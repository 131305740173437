const EstatusPedidos = [
  {
    nombre: 'Incompleto',
  },
  {
    nombre: 'Completo',
  },
  {
    nombre: 'En cola',
  },
  {
    nombre: 'Cancelado',
  },
];

export {
  EstatusPedidos,
};
