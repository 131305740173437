import Vue from 'vue';
import VueRouter from 'vue-router';
import Planificador from '../views/Planificador.vue';
import Clientes from '../views/Clientes.vue';
import Cliente from '../views/Cliente.vue';
import Login from '../views/Login.vue';
import Reportes from '../views/Reportes.vue';
import Pedidos from '../views/Pedidos.vue';
import Pedido from '../views/Pedido.vue';
import Actividades from '../views/Actividades.vue';
import Actividad from '../views/Actividad.vue';
import PedidosvsDespacho from '../components/reportes/PedidosVsDespacho.vue';
import KilosVendidos from '../components/reportes/KilosVendidos.vue';
import Despachos from '../views/Despachos.vue';
import Devoluciones from '../views/Devoluciones.vue';
import Devolucion from '../views/Devolucion.vue';
import Cobranzas from '../views/Cobranzas.vue';
import Cobranza from '../views/Cobranza.vue';
import RAD from '../views/RAD.vue';
import NotaDeEntrega from '../components/despachos/NotaDeEntrega.vue';

Vue.use(VueRouter);
const pushPrototype = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return pushPrototype.call(this, location).catch((e) => e);
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Planificador',
    component: Planificador,
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clientes,
    children: [
      {
        path: ':id',
        name: 'Cliente',
        component: Cliente,
        children: [
          {
            path: ':idActividad',
            name: 'ClienteActividad',
            component: Actividad,
          },
        ],
      },
    ],
  },
  {
    path: '/actividades',
    name: 'Actividades',
    component: Actividades,
    children: [
      {
        path: ':id',
        name: 'Actividad',
        component: Actividad,
      },
    ],
  },
  {
    path: '/pedidos/:sucursal?',
    name: 'Pedidos',
    component: Pedidos,
    children: [
      {
        path: '/pedido/:id',
        name: 'Pedido',
        component: Pedido,
      },
    ],
  },
  {
    path: '/despachos',
    name: 'Despachos',
    component: Despachos,
  },
  {
    path: '/reportes',
    name: 'Reportes',
    component: Reportes,
  },
  {
    path: '/reportes/PedidosvsDespacho',
    name: 'PedidosvsDespachos',
    component: PedidosvsDespacho,
  },
  {
    path: '/reportes/KilosVendidos',
    name: 'KilosVendidos',
    component: KilosVendidos,
  },
  {
    path: '/devoluciones',
    name: 'Devoluciones',
    component: Devoluciones,
    children: [
      {
        path: ':id',
        name: 'Devolucion',
        component: Devolucion,
      },
    ],
  },
  {
    path: '/NotaDeEntrega/:id',
    name: 'NotaDeEntrega',
    component: NotaDeEntrega,
  },
  {
    path: '/cobranzas',
    name: 'Cobranzas',
    component: Cobranzas,
    children: [
      {
        path: ':id',
        name: 'Cobranza',
        component: Cobranza,
      },
    ],
  },
  {
    path: '/RAD',
    name: 'RAD',
    component: RAD,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    const rootElement = document.getElementById('app');
    if (rootElement) {
      rootElement.scrollIntoView();
    }
  },
});

export default router;
