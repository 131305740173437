<template>
  <section class="mb-4">
    <article class="pt-4">
      <div class="d-sm-flex text-center">
        <header class="titulo h4-responsive flex-fill text-center text-md-left pl-md-5 pl-lg-2">
          Filtros de búsqueda
        </header>
        <div class="custom-checkbox">
          <input v-model="hideIcons" type="checkbox" id="ver-iconos">
          <label :class="['custom-checkbox-label eye-icon', {'checked': hideIcons}]" for="ver-iconos">
            <!-- <font-awesome-icon icon="eye" /> -->
            Ocultar iconos
          </label>
        </div>
      </div>
      <!-- Checks de filtros -->
      <template>
        <TransitionGroup
          name="custom-classes-transition"
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOutLeft"
        >
          <!-- Checks de los módulos  -->
          <div
            key="modulos"
            class="btn-checkgroup-contenedor border border-bottom-0 border-left-0 border-right-0 border-light"
          >
            <input
              v-model="reposAMostrar"
              :id="`rep-crm`"
              type="checkbox"
              name="checkbox-categoria"
              value="crm"
            >
            <label :for="`rep-crm`" class="btn-checkgroup my-1 crm-label icon-headset">
              CRM
            </label>
            <input
              v-model="reposAMostrar"
              :id="`rep-cob`"
              type="checkbox"
              name="checkbox-categoria"
              value="cobranza"
            >
            <label :for="`rep-cob`" class="btn-checkgroup my-1 cobranza-label icon-invoice-list">
              Facturas
            </label>
            <input
              v-model="reposAMostrar"
              :id="`rep-vendedor`"
              type="checkbox"
              name="checkbox-categoria"
              value="vendedor"
            >
            <label :for="`rep-vendedor`" class="btn-checkgroup my-1 vendedor-label icon-handshake">
              Vendedor
            </label>
            <input
              v-model="reposAMostrar"
              :id="`rep-ventas`"
              type="checkbox"
              name="checkbox-categoria"
              value="ventas"
            >
            <label :for="`rep-ventas`" class="btn-checkgroup my-1 ventas-label icon-truck-cargo-container">
              Despachos
            </label>
          </div>
          <!-- Checks de las actividades -->
          <div
            v-if="reposAMostrar.find((r) => r == 'vendedor')"
            key="vendedorCalendario"
            class="btn-checkgroup-contenedor border border-bottom-0 border-left-0 border-right-0 border-light"
          >
            <span v-for="ven in venCalen" :key="ven.id">
              <input
                v-model="venAMostrar"
                :id="`ven-${ven.nom_acc}`"
                type="checkbox"
                name="checkbox-categoria"
                :value="ven.id"
              >
              <label :for="`ven-${ven.nom_acc}`" :class="['btn-checkgroup my-1', ven.icon_acc, ven.color]">
                {{ven.nom_acc}}
              </label>
            </span>
          </div>
          <!-- Checks de acciones de los vendedores -->
          <div
            key="acciones"
            class="btn-checkgroup-contenedor interacciones-list d-flex flex-wrap align-items-center
              border border-bottom-0 border-left-0 border-right-0 border-light"
          >
            <span v-for="acc in acciones" :key="acc.id_acc">
              <input
                v-model="accAMostrar"
                :id="`acc-${acc.nom_acc}`"
                type="checkbox"
                name="checkbox-categoria"
                :value="acc.id_acc"
              >
              <label :for="`acc-${acc.nom_acc}`" :class="['btn-checkgroup my-1', acc.icon_acc]">
                {{acc.nom_acc}}
              </label>
            </span>
            <span class="cliente-potencial-input">
              <input
                v-model="clientesPotenciales"
                id="cliPo"
                type="checkbox"
                name="checkbox-cliPo"
                @click="clientesPotenciales = !clientesPotenciales; actualizar()"
              >
              <label for="cliPo" class="btn-checkgroup my-1 icon-target-account">
                Clientes Potenciales
              </label>
            </span>
          </div>
          <!-- Checks de motivos del CRM -->
          <div
            v-if="reposAMostrar.find((r) => r == 'crm')"
            key="motivos"
            class="btn-checkgroup-contenedor acciones-list
              border border-bottom-0 border-left-0 border-right-0 border-light"
          >
            <span v-for="me in motivos" :key="me.id_me">
              <input
                v-model="meAMostrar"
                :id="`me-${me.nombre_me}`"
                type="checkbox"
                name="checkbox-categoria"
                :value="me.id_me"
              >
              <label :for="`me-${me.nombre_me}`" :class="['btn-checkgroup my-1', unirIconos(me.id_me, motivos)[0].icon_class]">
                {{me.nombre_me}}
              </label>
            </span>
          </div>
        </TransitionGroup>
      </template>
      <div :class="['calendario', {'hideIcons': hideIcons}]">
        <calendar-view
          :items="dates"
          :show-date="showDate"
          :show-times="true"
          :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
          :startingDayOfWeek="1"
          :disable-past="false"
          :disable-future="false"
          @click-date="onClickDay"
          @click-item="onClickItem"
          class="theme-default holiday-us-traditional holiday-us-official"
        >
        <template slot="dayContent" slot-scope="{ day }">
            <div v-if="clientesDelDia(day.toISOString().split('T')[0])[1]" class="mx-auto d-none d-md-block">
              <mdb-badge
                pill
                color="bg-primario"
                class="z-depth-0 mr-1"
              >
                <span class="icon-account">
                  {{ clientesDelDia(day.toISOString().split('T')[0])[0] }} |
                </span>
                <span class="icon-handshake">
                  {{ clientesDelDia(day.toISOString().split('T')[0])[1] }}
                </span>
              </mdb-badge>
            </div>
          </template>
          <calendar-view-header
            slot="header"
            slot-scope="{ headerProps }"
            :header-props="headerProps"
            @input="setShowDate"
          />
        </calendar-view>
      </div>
      <template v-if="selectedDate.length">
        <p class="h5 text-center mt-1">
          <span class="h3">
            {{ selectedDate.length }}
          </span>
          <small class="small-block">
            Actividades del dia
          </small>
          <small class="small-block font-weight-bold">
              {{ clientesDelDia(selectedDayISOString.split('T')[0])[0] }} clientes
            </small>
          <small class="small-block">
            {{ selectedDay }}
          </small>
        </p>
        <div class="d-flex flex-wrap">
          <div
            v-for="(date, i) in selectedDate"
            :key="i"
            class="col-12 col-md-6 col-lg-4 px-0 px-md-2 py-2"
          >
            <mdb-card class="card-bordered">
              <mdb-card-body class="py-2 px-2">
                <mdb-card-title class="h6">
                  <RouterLink
                    v-if="date.originalItem.id_emp && date.originalItem.id_suc"
                    custom
                    v-slot="{navigate}"
                    :to="{
                      name: 'Cliente',
                      params: {id: date.originalItem.id_emp, idSuc: date.originalItem.id_suc},
                    }"
                    class="float-right"
                  >
                    <mdb-btn flat dark-waves class="m-0 px-2 py-1" @click="navigate">
                      Ver detalles
                      <font-awesome-icon icon="angle-right" />
                    </mdb-btn>
                  </RouterLink>
                  <p :class="['mb-1',date.originalItem.icon_id]">
                    {{ date.originalItem.title }}
                  </p>
                </mdb-card-title>
                <mdb-card-text>
                  <template v-if="date.originalItem.id_fac">
                    <div class="row w-100 mx-0">
                      <p class="col-auto pl-0">
                        <small class="small-block">Estatus</small>
                        <span :class="asignarColorEstado(date.originalItem.des)">
                          {{date.originalItem.des}}
                        </span>
                      </p>
                      <p v-if="date.originalItem.atraso" class="col-auto col-md-6 pl-0">
                        <small class="small-block">{{ Number(date.originalItem.atraso)>=0 ? 'Días de Atraso' : 'Días que faltan'}}</small>
                        {{ Number(date.originalItem.atraso)>=0 ? date.originalItem.atraso : Math.abs(Number(date.originalItem.atraso)) }}
                      </p>
                      <p v-if="date.originalItem.diferencia" class="col-auto col-md-6 pl-0">
                        <small class="small-block">{{Number(date.originalItem.diferencia)>=0 ? 'Dias que tardo en pagar' : 'Dias del pago anticipado'}}</small>
                        {{ Number(date.originalItem.diferencia)>=0 ? date.originalItem.diferencia : Math.abs(Number(date.originalItem.diferencia)) }}
                      </p>
                      <p class="col-auto col-md-6 pl-0" v-if="date.originalItem.fec_pag_fac">
                        <small class="small-block">Fecha del pago</small>
                        {{date.originalItem.fec_pag_fac}}
                      </p>
                      <p class="col-auto col-md-6 pl-0" v-if="date.originalItem.fec_ven_fac">
                        <small class="small-block">Fecha de vencimiento</small>
                        {{date.originalItem.fec_ven_fac}}
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="float-right d-flex">
                      <mdb-badge
                        v-if="date.originalItem.eli_suc === '3'"
                        pill
                        color="bg-primario"
                        class="d-flex align-items-center z-depth-0 icon-target-account icon-target-account-dark"
                      >
                        Cliente potencial
                      </mdb-badge>
                      <mdb-badge
                        v-if="date.originalItem.faltantes === '0'"
                        pill
                        color="bg-success"
                        class="d-flex align-items-center icon-check z-depth-0"
                      >
                        Cumplida
                      </mdb-badge>
                    </div>
                    <div class="d-inline">
                      <template v-if="date.originalItem.motivos && date.originalItem.motivos.length">
                        <small class="small-block">
                          Planificado
                        </small>
                        <mdb-badge
                          v-for="(mot, m) in date.originalItem.motivos"
                          :key="m"
                          pill
                          :color="mot.realizado == 1 ? 'bg-success' : 'bg-warning'"
                          class="z-depth-0 mr-1"
                        >
                          {{mot.motivo}}
                        </mdb-badge>
                        <p v-if="date.originalItem.can_rep >= 1" class="border-bottom border-dark mb-1">
                          <small class="small-block bold">
                            Esta actividad fue replanificada {{ date.originalItem.can_rep }} {{ date.originalItem.can_rep > 1 ? 'veces' : 'vez'}}
                          </small>
                          <small class="small-block">
                            del
                            <span class="bold">
                              {{ formatearFechaSinHora(date.originalItem.fec_ori_pla) }}
                            </span>
                            al
                            <span class="bold">
                              {{ formatearFechaSinHora(date.originalItem.fec_pla) }}
                            </span>
                          </small>
                        </p>
                      </template>
                      <template v-if="date.originalItem.des && date.originalItem.des.length">
                        <small class="small-block">Actividad realizada</small>
                        <p class="d-flex flex-wrap">
                          <span
                            v-for="(des, d) in date.originalItem.des"
                            :key="d"
                            :class="[des.icon_class, 'col-12 col-md-auto small-block px-0 pr-2']"
                          >
                            {{des.icon_des}}
                          </span>
                        </p>
                      </template>
                    </div>
                  </template>
                  <p
                    v-if="date.originalItem.observaciones !== null && date.originalItem.observaciones !== 'null' && date.originalItem.observaciones.length"
                    class="mt-2"
                  >
                    <small class="small-block">Observación</small>
                    {{ date.originalItem.observaciones }}
                  </p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </template>
      <template v-if="productos && Object.keys(productos).length">
        <section class="container mt-4">
          <div class="encabezado-detalles">
            <header class="h4-responsive text-center pt-3">Resumen del mes</header>
            <div class="row mx-0 mt-2 text-center">
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Total clientes
                </small>
                {{  resumen.total_clientes }}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Visitas por dia
                </small>
                {{ resumen.visitas_por_dia}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes Visitados
                </small>
                {{ resumen.clientes_visitados}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes llamados
                </small>
                {{ resumen.clientes_llamados}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes con pedido
                </small>
                {{ resumen.clientes_con_pedido}}
                <span class="text-muted"> - {{ resumen.porcentaje_con_pedidos }}%</span>
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes nuevos
                </small>
                {{ resumen.clientes_nuevos}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes sin pedido
                </small>
                {{ resumen.clientes_sin_pedido}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Cantidad de visitas
                </small>
                {{ resumen.cantidad_visitas}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Días atendiendo
                </small>
                {{ Number(resumen.dias_atendiendo) }}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes contactados
                </small>
                {{ resumen.clientes_contactados}}
                <span class="text-muted"> - {{ Number((100 - resumen.porcentaje_no_contactado).toFixed(2)) }}%</span>
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes no contactados
                </small>
                {{ resumen.total_clientes - resumen.clientes_contactados}}
                <span class="text-muted"> - {{ resumen.porcentaje_no_contactado }}%</span>
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Cantidad de llamadas
                </small>
                {{ resumen.cantidad_llamadas}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Clientes con despacho
                </small>
                {{ resumen.clientes_con_despacho}}
                <span class="text-muted"> - {{ resumen.porcentaje_con_despacho }}%</span>
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Kilos despachados
                </small>
                {{ Number(resumen.kilos_despachados)}} kg
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Cobranzas realizadas
                </small>
                {{ resumen.cantidad_cobranza}}
              </p>
              <p class="col-6 col-sm-3 border-bottom mt-1 mb-0">
                <small class="small-block">
                  Cobranza efectiva
                </small>
                {{ resumen.porcentaje_efectividad_cobranza}}%
              </p>
            </div>
          </div>
          <ul class="table t-detalles mt-3">
            <li class="encabezado sticky">
              <header class="h4-responsive text-capitalize">
                Productos del mes
              </header>
              <div class="thead claro">
                <div class="col-3">
                  Producto
                  <span class="small-block">Descripción - Categoría</span>
                </div>
                <div class="col-3 text-right">
                  N° de Clientes
                  <span class="small-block">Porcentaje</span>
                </div>
                <div class="col-3">
                  Producto
                  <span class="small-block">Descripción - Categoría</span>
                </div>
                <div class="col-3 text-right">
                  N° de Clientes
                  <span class="small-block">Porcentaje</span>
                </div>
              </div>
            </li>
            <div class="d-md-flex flex-wrap">
              <li
                v-for="(p, i) in productos"
                :key="i"
                :class="[{'fila-error' : !p.num_sucs}, 'contenido hover-claro col-md-6 px-0']"
              >
                <div class="col-12 col-md pr-1" data-columna="Producto">
                  <p>
                    {{ p.nom_pro }}
                    <span class="d-none d-sm-block small-block text-uppercase">
                      {{ p.des_pre }} - {{ p.nom_cat }}
                    </span>
                  </p>
                </div>
                <div data-columna="Descripción" class="col-12 d-sm-none">{{ p.des_pre }}</div>
                <div data-columna="Categoría" class="col-12 d-sm-none">{{ p.nom_cat }}</div>
                <div class="col-12 col-sm-4" data-columna="N° de Clientes Porcentaje">
                  <p :class="[{'texto-error': !p.num_sucs}, 'text-sm-right']">
                    {{ p.num_sucs ? p.num_sucs : 'Sin clientes'}}
                    <span v-if="p.num_sucs" class="small-block">{{ Number(p.prom).toFixed(2) }}%</span>
                  </p>
                </div>
              </li>
            </div>
          </ul>
        </section>
      </template>
    </article>
  </section>
</template>

<script>
import {CalendarView, CalendarViewHeader} from 'vue-simple-calendar';
import {mdbCard, mdbCardTitle, mdbCardBody, mdbCardText, mdbBtn, mdbBadge} from 'mdbvue';
import {formatearFechaSinHora, asignarColorEstado} from '@/funciones/funciones.js';
import {apiPost} from '@/funciones/api.js';

import 'vue-simple-calendar/static/css/default.css';
import 'vue-simple-calendar/static/css/holidays-us.css';

export default {
  name: 'RAD',
  components: {
    mdbCard,
    mdbCardTitle,
    mdbCardBody,
    mdbCardText,
    mdbBtn,
    mdbBadge,
    CalendarView,
    CalendarViewHeader,
  },
  data: function() {
    return {
      formatearFechaSinHora,
      asignarColorEstado,
      reposAMostrar: ['vendedor'],
      venAMostrar: ['1', '2'],
      venCalen: [
        {
          id: '1',
          nom_acc: 'Actividades',
          icon_acc: 'icon-handshake',
          color: 'vendedor-label',
        },
        {
          id: '2',
          nom_acc: 'Planificado',
          icon_acc: 'icon-calendar',
          color: 'planificador-label',
        },
        {
          id: '3',
          nom_acc: 'Replanificado',
          icon_acc: 'icon-calendar-minus',
          color: 're-planificador-label',
        },
      ],
      clientesPotenciales: false,
      accAMostrar: [],
      meAMostrar: [],
      hideIcons: false,
      showDate: this.thisMonth(1),
      selectedDate: [],
      selectedDay: '',
      dates: [],
      fecha: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      acciones: [],
      motivos: [],
      productos: {},
      resumen: {},
    };
  },
  methods: {
    // Funcionalidades para la data
    cargaInicial() {
      apiPost({s: 'motivosMv'}, '')
          .then((res) => {
            if (res.data) {
              this.motivos = res.data.mot;
            }
          });
      apiPost({s: 'accionesMv'}, '')
          .then((res) => {
            if (res.data) {
              this.acciones = res.data.acc;
            }
          });
      this.actualizar();
    },
    actualizar() {
      const fecha = new Date(this.showDate).toISOString();
      let data = this.showDate ? 'ano=' + fecha.substring(0, 4) + '&mes=' + fecha.substring(5, 7) : '';
      data += this.reposAMostrar ? '&repos=' + this.reposAMostrar.join(',') : '';
      data += this.accAMostrar ? '&acciones=' + this.accAMostrar.join(',') : '';
      data += this.meAMostrar ? '&motivos=' + this.meAMostrar.join(',') : '';
      data += this.venAMostrar ? '&venAcc=' + this.venAMostrar.join(',') : '';
      data += '&clip=' + this.clientesPotenciales;
      apiPost({s: 'radMv'}, data)
          .then((res) => {
            if (res.data) {
              this.datosRad(res.data);
              this.selectedDate = [];
            }
          });
      data = this.showDate ? 'ver_ano=' + fecha.substring(0, 4) + '&ver_mes=' + fecha.substring(5, 7) : '';
      data += this.vendedoresAFiltrar ? '&ven=' + this.vendedoresAFiltrar.join(',') : '';
      data += '&clip=' + this.clientesPotenciales;
      apiPost({s: 'actividadesMv'}, data)
          .then((res) => {
            if (res.data) {
              this.resumen = res.data;
            }
          });
      apiPost({s: 'productosPrctjMv'}, data)
          .then((res) => {
            if (res.data) {
              this.datosProductos(res.data.pro);
            }
          });
    },
    datosRad(data) {
      const dates = [];
      data.pla.forEach((pla) => {
        dates.push({
          startDate: pla.fec_pla,
          title: `${pla.nom_emp} - ${pla.nom_suc}`,
          classes: pla.eli_suc === '3' ? 'cliente-potencial icon-target-account':
            pla.can_rep_pla === '0' ? 'planificador icon-calendar' : 're-planificador icon-calendar-minus',
          eli_suc: pla.eli_suc,
          motivos: this.motivosRealizados(pla.motivos),
          icon_id: 'icon-calendar',
          faltantes: pla.faltantes,
          id_emp: pla.id_emp,
          id_pla: pla.id_pla,
          id_suc: pla.id_suc,
          observaciones: pla.obs_pla,
        });
      });
      data.fac.forEach((f) => {
        dates.push({
          startDate: f.fec_ven_fac,
          title: f.nom_emp + ' ' + f.nom_suc,
          classes: 'cobranza icon-invoice-list',
          des: f.est_fac,
          icon_id: 'icon-invoice-list',
          id_fac: f.id_fac,
          id_emp: f.id_emp,
          id_suc: f.id_suc,
          fec_pag_fac: f.fec_pag_fac,
          fec_ven_fac: f.fec_ven_fac,
          diferencia: f.diferencia,
          atraso: f.atraso,
        });
      });
      data.des.forEach((d) => {
        dates.push({
          startDate: d.fec_des,
          title: d.zonas,
          classes: 'ventas icon-truck-cargo-container',
          des: [{
            icon_class: 'icon-truck-cargo-container',
            icon_des: 'Despacho',
          }],
          icon_id: 'icon-truck-cargo-container',
          id_des: d.id_des,
          id_emp: d.id_emp_suc,
          id_suc: d.id_suc,
          observaciones: d.obs_des,
        });
      });
      data.interacciones.forEach((i) => {
        dates.push({
          startDate: i.fecha_int,
          title: i.nom_emp + ' ' + i.nom_suc,
          classes: i.eli_suc === '3'? 'cliente-potencial icon-target-account': 'vendedor icon-handshake',
          eli_suc: i.eli_suc,
          des: this.unirIconos(i.acciones, this.acciones),
          icon_id: 'icon-handshake',
          id_int: i.id_int,
          id_emp: i.id_emp,
          id_suc: i.id_suc,
          observaciones: i.obs_int,
          fec_pla: i.fec_pla,
          fec_ori_pla: i.fec_ori_pla,
          can_rep: i.can_rep_pla,
          obs_pla: i.obs_pla,
          id_pla: i.id_pla,
          hor_pla: i.hor_pla,
          motivos: this.motivosRealizados(i.motivos),
        });
      });
      data.even.forEach((e) => {
        dates.push({
          startDate: e.fecha_evento,
          title: e.nom_emp + ' ' + e.nom_suc,
          classes: 'crm icon-headset',
          des: this.unirIconos(e.motivos, this.motivos),
          icon_id: 'icon-headset',
          id_evento: e.id_evento,
          id_emp: e.id_emp,
          id_suc: e.id_suc,
          observaciones: e.observacion_evento,
        });
      });
      this.dates = dates;
    },
    datosProductos(pro) {
      const dataPro = [];
      pro.forEach((d) => {
        dataPro.push({
          id_pre: d.id_pre,
          cod_pre: d.cod_pre,
          des_pre: d.des_pre,
          nom_cat: d.nom_cat,
          nom_pro: d.nom_pro,
          num_sucs: d.num_sucs,
          prom: d.num_sucs ? (Number(d.num_sucs) * 100) / Number(this.resumen.total_clientes) : 0,
        });
      });
      this.productos = dataPro;
    },
    unirIconos(iconosId, listaIconos) {
      const des = [];
      iconosId.split(', ').forEach((id) => {
        listaIconos.map((i) => {
          if (i.id_me && i.id_me === id) {
            des.push({
              icon_class: i.nombre_me.toLowerCase().replace(/\s+/g, '-'),
              icon_des: i.nombre_me,
            });
          } else if (i.id_acc && i.id_acc === id) {
            des.push({
              icon_class: i.icon_acc,
              icon_des: i.nom_acc,
            });
          }
        });
      });
      return des;
    },
    motivosRealizados(motivos) {
      const des = [];
      if (motivos) {
        const motSep = motivos.split(',');
        motSep.forEach((mot) => {
          const res = mot.split(' - ');
          des.push({
            motivo: res[0],
            realizado: res[1],
          });
        });
      }
      return des;
    },
    // Funcionalidades del calendario
    onClickDay(d, c) {
      this.selectedDay = d.toLocaleDateString();
      this.selectedDayISOString = d.toISOString();
      this.selectedDate = c;
    },
    onClickItem(i) {
      const itemsDate = [];
      this.dates.forEach((date) => {
        if (date.startDate === i.originalItem.startDate) {
          itemsDate.push({originalItem: date});
        }
      });
      this.selectedDay = i.originalItem.startDate;
      this.selectedDayISOString = new Date(i.originalItem.startDate).toISOString();
      this.selectedDate = itemsDate;
    },
    thisMonth(d, h, m) {
      const t = new Date();
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
    },
    setShowDate(d) {
      this.showDate = d;
      this.actualizar();
    },
    clientesDelDia(dia) {
      const totalCli = {};
      let totalInte = 0;
      if (this.dates.length) {
        this.dates.forEach((date) => {
          if (date.startDate === dia) {
            totalInte++;
            if (!totalCli[date.id_suc]) {
              totalCli[date.id_suc] = date.id_suc;
            }
          }
        });
      }
      return [Object.keys(totalCli).length, totalInte];
    },
  },
  watch: {
    venAMostrar: function() {
      this.actualizar();
    },
    reposAMostrar: function() {
      this.actualizar();
    },
    accAMostrar: function() {
      this.actualizar();
    },
    meAMostrar: function() {
      this.actualizar();
    },
  },
  mounted() {
    this.cargaInicial();
  },
};
</script>
<style lang="scss">
.table.t-detalles {
  .contenido > [class*=col] {line-height: 1.3}
  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "768px";
  @include ul-table-responsive-at ($breakpointToChange);
  .fila-error {
    background-color: lighten($secundario, 46%);

    .texto-error {
      font-weight: bold;
    }
  }
}
.calendario {
  &.hideIcons {
    [class*='icon']::before {
      content: '';
    }
  }
  .cv-item {
    font-size: 11px;
    text-overflow: clip;
    margin-top: 6px;
    @media (min-width: 768px) {
      font-size: 12px;
      margin-top: 4px;
    }
    @media (min-width: 1200px) {
      font-size: 14px;
      margin-top: 3px;
    }
  }
  .cv-header {
    flex-direction: column;
    @media (min-width: 576px) {
      flex-direction: row;
    }
  }
  .cv-weeks {
    display: block;
  }
  .cv-week {
    min-height: 8rem;
    @media (min-width: 576px) {
      min-height: 9rem;
    }
  }
}
$ventas-label: lighten(#be623d, 30%);
$vendedor-label: lighten($secundario, 35%);
$planificador-label: $secundario-oscuro;
$re-planificador-label: lighten($primario, 25%);
$cliente-potencial-label: lighten($error-oscuro, 25%);
$crm-label: lighten(#4FDB8C, 15%);
$cobranza-label: lighten($error, 20%);

.btn-checkgroup-contenedor {
  input[type=checkbox]:checked + .btn-checkgroup {
    &.crm-label {
      background-color: $crm-label !important;
    }
    &.vendedor-label {
      background-color: $vendedor-label !important;
    }
    &.planificador-label {
      background-color: $planificador-label !important;
    }
    &.re-planificador-label {
      background-color: $re-planificador-label !important;
    }
    &.ventas-label {
      background-color: $ventas-label !important;
    }
    &.cobranza-label {
      background-color: $cobranza-label !important;
    }
  }
  &.acciones-list {
    input[type=checkbox]:checked + .btn-checkgroup {
      background-color: $crm-label !important;
    }
  }
  &.interacciones-list {
    input[type=checkbox]:checked + .btn-checkgroup {
      background-color: $vendedor-label !important;
    }
    .cliente-potencial-input {
      input[type=checkbox]:checked + .btn-checkgroup {
        background-color: $cliente-potencial-label !important;
      }
    }
  }
}
.btn-checkgroup {
  display: inline-flex;
  align-items: center;
}
.ventas {
  background-color: $ventas-label !important;
}
.vendedor {
  background-color: $vendedor-label !important;
}
.planificador {
  background-color: $planificador-label !important;
}
.re-planificador {
  background-color: $re-planificador-label !important;
}
.cliente-potencial {
  background-color: $cliente-potencial-label !important;
}
.crm {
  background-color: $crm-label !important;
}
.cobranza {
  background-color: $cobranza-label !important;
}
.custom-checkbox {
  input {
    display: none;
  }
  .custom-checkbox-label {
    border-radius: .3rem;
    padding: .3rem;
    transition: all  .5s ease;
  }
  .custom-checkbox-label.checked {
    background: lighten($secundario, 30%);
  }
  .custom-checkbox-label.eye-icon::before {
    @include agregar-icono('\f06e', 400);
  }
}
.border-md-none {
  @media (min-width:768px) {
    border-bottom: none !important;
  }
}
.icon-check::before {
  @include agregar-icono('\f00c');
  margin-right: 3px
}
</style>
