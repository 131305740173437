<template>
  <section class="container">
    <div class="row justify-content-center justify-content-md-between">
      <div class="col-auto px-0">
        <mdb-btn
          flat
          dark-waves
          icon="arrow-left"
          type="button"
          title="Regresar"
          @click="$router.go(-1)"
        >
          Regresar
        </mdb-btn>
      </div>
    </div>
    <div class="encabezado-detalles">
      <header class="h4-responsive text-center pt-3">Reporte</header>
      <div class="row mx-0 mt-2">
        <div class="col-12 col-md-4">
          <span class="small-block font-titulo">Nombre del reporte</span>
          <p>
            Comparativa de ventas por vendedor
          </p>
        </div>
        <div class="col-12 col-sm">
          <span class="small-block font-titulo">Mes</span>
          <p>
            {{ fechaMes }}
          </p>
        </div>
        <div class="col-12">
          <span class="small-block font-titulo">Descripción</span>
          <p class="mb-1">
            Reporte de las ventas en pesos realizadas por mes
            <span v-if="!vendedoresSeleccionados.length">de todos los vendedores.</span>
            <span v-else-if="vendedoresSeleccionados.length >= 2" class="d-inline">
              de los vendedores:
              <span v-for="(vende, index) in vendedoresSeleccionados" :key="index" class="text-capitalize">
                {{vende.nom_per + ' ' + vende.ape_per + (index === (vendedoresSeleccionados.length -1) ? '. ' : ', ')}}
              </span>
            </span>
            <span v-else>
              del vendedor {{ vendedoresSeleccionados[0].nom_per + ' ' + vendedoresSeleccionados[0].ape_per }}.
            </span>
          </p>
          <p class="mb-1">Muestra la comparativa del peso total de productos vendidos en la fecha seleccionada por el o los vendedores.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-xl-3">
        <mdb-input
          v-model="fechaMes"
          type="month"
          label="Mes"
          class="my-2"
          outline
        />
      </div>
      <!-- Vendedores -->
      <div class="col-12 col-md-4 px-0">
        <v-select
          v-model="vendedoresFiltrados"
          :searchable="false"
          :options="Object.values(vendedores)"
          :getOptionLabel="vendedores => vendedores.nom_per"
          multiple
          class="outline-datalist my-2"
          @input="vendedoresEnvio"
        >
          <template #header>
            <label :class="['datalist-label', {'activo': vendedoresFiltrados.includes(vendedores.id_ven)}]">
              Vendedores
            </label>
          </template>
          <template #option="vendedores">
            {{vendedores.nom_per}} {{vendedores.ape_per}}
          </template>
        </v-select>
      </div>
      <div>
        <mdb-btn
          flat
          dark-waves
          icon="broom"
          class="my-1 px-3"
          @click="restablecerFiltros()"
        >
          Restablecer filtros
        </mdb-btn>
      </div>
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <!-- Mensaje cuando el vendedor seleccionado no tiene pedido -->
    <div
      v-else-if="!productos.length"
      class="mensaje-no-items pb-3"
    >
      <font-awesome-icon
        icon="clipboard-list"
        size="5x"
        class="icono"
      />
      <p class="texto">No hay productos vendidos según los parámetros seleccionados</p>
    </div>
    <div v-else>
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :css-classes="'mx-auto'"
      />
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>
<script>
import {mdbInput, mdbBtn} from 'mdbvue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import LoaderKel from '@/components/LoaderKel.vue';
import {apiPost} from '@/funciones/api.js';
import {convertirNumeroAFixed} from '@/funciones/funciones.js';
import {Bar} from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'KilosVendidos',
  components: {
    mdbInput,
    mdbBtn,
    AlertaMensaje,
    LoaderKel,
    Bar,
  },
  data() {
    return {
      cargando: false,
      productos: [],
      responsive: {btnRestablecer: 'col-12 col-md-auto'},
      vendedores: [],
      vendedoresFiltrados: [],
      vendedoresAFiltrar: [],
      vendedoresSeleccionados: [],
      fechaMes: (new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)).toString(),
      alertaMensaje: {contenido: ''},
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {stacked: true},
          y: {stacked: true},
        },
      },
    };
  },
  mounted() {
    this.obtenerVendedoresFiltros();
    this.actualizar();
  },
  watch: {
    vendedoresAFiltrar() {
      this.vendedoresSeleccionados = [];
      this.agruparVendedoresSeleccionados();
    },
    fechaMes() {
      if (this.vendedoresAFiltrar) {
        this.actualizar();
      }
    },
  },
  methods: {
    agruparVendedoresSeleccionados() {
      this.vendedoresAFiltrar.forEach((IdVende) => {
        this.vendedores.forEach((vendefiltrado) => {
          return vendefiltrado.id_per === IdVende ? this.vendedoresSeleccionados.push(vendefiltrado) : null;
        });
      });
    },
    obtenerVendedoresFiltros() {
      apiPost({s: 'vendedores'}, '')
          .then((res) => {
            this.vendedores = res.data.ven;
          }).catch(() => {
            this.alertaMensaje = {
              contenido: 'Ocurrió un error obteniendo los vendedores',
              tipo: 'error',
            };
          });
    },
    restablecerFiltros() {
      this.vendedoresFiltrados = [];
      this.vendedoresAFiltrar = [];
      this.actualizar();
    },
    datosDelChart(productos, vendedores) {
      const labels = [];
      const dataProductos = [];
      const vendedoresOrdenadosPorKilosVendidos = [];

      productos.forEach((producto) => {
        vendedores.forEach((vendedor) => {
          if (!vendedoresOrdenadosPorKilosVendidos[vendedor.id_per] || !vendedoresOrdenadosPorKilosVendidos[vendedor.id_per].totalKilos) {
            vendedoresOrdenadosPorKilosVendidos[vendedor.id_per] = {
              nom_per: vendedor.nom_per,
              ape_per: vendedor.ape_per,
              id_per: vendedor.id_per,
              totalKilos: 0,
            };
          }
          if (vendedor.id_per === producto.id_per_v_suc) {
            vendedoresOrdenadosPorKilosVendidos[vendedor.id_per].totalKilos += Number(producto.kilos);
          }
        });
      });

      vendedoresOrdenadosPorKilosVendidos.sort((a, b) => ~~b.totalKilos - ~~a.totalKilos);

      productos.forEach((producto) => {
        const pesosPorVendedor = [];
        vendedoresOrdenadosPorKilosVendidos.forEach((vendedor) => {
          if (vendedor.id_per === producto.id_per_v_suc) {
            pesosPorVendedor.push(producto.kilos);
          } else {
            pesosPorVendedor.push('');
          }
        });
        dataProductos.push({
          label: `${producto.nom_pro}-${producto.des_pre}`,
          backgroundColor: this.generarColorHexadecimal(),
          data: pesosPorVendedor,
        });
      });
      vendedoresOrdenadosPorKilosVendidos.forEach((ven) => {
        labels.push(`${ven.nom_per}-${ven.ape_per}. Total: ${this.convertirValorAFixed(ven.totalKilos)} kg`);
      });
      this.chartData.labels = labels;
      this.chartData.datasets = dataProductos;
    },
    actualizar() {
      this.cargando = true;
      let data = `y=${this.fechaMes.split('-')[0]}&m=${this.fechaMes.split('-')[1]}`;
      data += this.vendedoresAFiltrar ? '&vendedores=' + this.vendedoresAFiltrar.join(',') : '';
      apiPost({s: 'provsvenMv'}, data)
          .then((res) => {
            this.productos = res.data.pvv;
            this.vendedoresAFiltrar.length ? this.datosDelChart(this.productos, this.vendedoresSeleccionados) : this.datosDelChart(this.productos, this.vendedores);
            this.cargando = false;
          }).catch(() => {
            this.alertaMensaje = {
              contenido: 'Ocurrió un error obteniendo los datos',
              tipo: 'error',
            };
          });
    },
    vendedoresEnvio() {
      this.vendedoresAFiltrar = [];
      this.vendedoresFiltrados.forEach((v) => {
        this.vendedoresAFiltrar.push(v.id_per);
      });
      this.actualizar();
    },
    generarColorHexadecimal() {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      return color;
    },
    convertirValorAFixed(numero) {
      return convertirNumeroAFixed(numero);
    },
  },
};
</script>
<style lang="scss">
.table.reporte-kilos {
  @include ul-table-responsive-at ("576px");
}
</style>
