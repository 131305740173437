<template>
  <section class="container">
    <article class="pt-3">
      <header class="titulo h4-responsive text-center text-md-left m-2 ml-md-5 pl-md-4 pl-lg-0 m-lg-3">
        Filtros de búsqueda
      </header>
      <div class="d-flex flex-wrap ml-md-4 pl-md-4 pl-lg-0 ml-lg-0">
        <div class="col-12 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaDespacho"
            type="month"
            label="Mes"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaDel"
            type="date"
            label="Desde"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            outline
          />
        </div>
        <div :class="['col text-center px-0']">
          <mdb-btn
            flat
            dark-waves
            icon="broom"
            class="my-1 px-3"
            @click="restablecerFiltros"
            >
            Restablecer filtros
          </mdb-btn>
        </div>
      </div>
      <article class="mx-2 mx-md-3">
        <div
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </div>
        <div
          v-else-if="!Object.keys(despachos).length"
          class="mensaje-no-items pb-3"
        >
          <font-awesome-icon
            icon="calendar-times"
            size="5x"
            class="icono"
          />
          <p class="texto">No hay despachos en esta fecha</p>
        </div>
        <ul v-else class="table despachos">
          <li class="encabezado sticky">
            <div class="thead claro">
              <div class="col-12 col-md-2">
                <span class="text-muted">
                  ID -
                </span>
                Transportista
              </div>
              <div class="col-12 col-md-2">
                Fecha del despacho
                <span class="small-block">
                  Fecha del retorno
                </span>
              </div>
              <div class="col-12 col-md-3">
                Zonas
              </div>
              <div class="col-12 col-md-2 text-right">
                Estatus
                <span class="small-block">
                  Peso
                </span>
              </div>
            </div>
          </li>
          <li
            class="contenido cursor-pointer"
            v-for="d in despachos"
            :key="d.id_des"
            @click="imprimirNotaDeEntrega(d)"
          >
            <div
              class="col-12 col-md-2"
              data-columna="ID - Transportista"
            >
              <div>
                <span
                  title="Número de despacho"
                  class="text-muted"
                >
                  {{d.id_des}} -
                </span>
                {{d.nom_per}} {{d.ape_per}}
              </div>
            </div>
            <div
              class="col-12 col-md-2"
              data-columna="Fecha despacho Fecha retorno"
            >
              <div>
                {{formatearFechaYHora(d.fec_cre_des)}}
                <span
                  class="small-block"
                  v-if="d.fec_ret_des"
                >
                  {{formatearFecha(d.fec_ret_des).YYYYMMDD}}
                </span>
              </div>
            </div>
            <div
              class="col-12 col-md-3 col-lg-3"
              data-columna="Zonas"
            >
              <div>
                <span class="small-block">
                  <span class="auto-coma text-capitalize">
                    {{ d.zonas.toLowerCase() }}
                  </span>
                </span>
              </div>
            </div>
            <div
              class="col-12 col-md-2 text-md-right"
              data-columna="Peso"
            >
              <div>
                <span :class="['bold text-capitalize d-none d-md-block', asignarColorEstado(d.est_des)]">
                  {{ d.est_des }}
                </span>
                <span class="small-block">
                  {{convertirValorAFixed(d.pes_des)}} KG
                </span>
              </div>
            </div>
            <div
              class="col-12 d-md-none"
              data-columna="Estatus"
            >
              <div>
                <span :class="['bold text-capitalize', asignarColorEstado(d.est_des)]">
                  {{ d.est_des }}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </article>
    </article>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {apiPost} from '@/funciones/api.js';
import {
  mdbBtn,
  mdbInput,
} from 'mdbvue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import {convertirNumeroAFixed, formatearFecha, formatearFechaYHora, asignarColorEstado} from '@/funciones/funciones.js';
import LoaderKel from '@/components/LoaderKel.vue';

export default {
  name: 'Despachos',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje,
    LoaderKel,
  },
  data() {
    return {
      asignarColorEstado,
      formatearFecha,
      formatearFechaYHora,
      cargando: false,
      alertaMensaje: {
        contenido: '',
      },
      despachos: {},
      fechaDespacho: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      fechaDel: '',
      fechaHasta: '',
      timeout: null,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  watch: {
    fechaDespacho() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.cargaInicial();
    },
    fechaHasta() {
      if (!this.fechaHasta) return;
      this.cargaInicial();
    },
  },
  methods: {
    convertirValorAFixed(numero) {
      return convertirNumeroAFixed(numero);
    },
    cargaInicial() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.cargando = true;
        this.resultados = {};
        let data = this.fechaDespacho ? 'y=' + this.fechaDespacho.split('-')[0] + '&m=' + this.fechaDespacho.split('-')[1] + '&' : '';
        data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta + '&' : '';
        apiPost({s: 'despachosMv'}, data)
            .then((res) => {
              this.despachos = res.data.des;
              this.cargando = false;
            });
      }, 500); // delay
    },
    imprimirNotaDeEntrega(des) {
      if (
        !des.v_id_per ||
        !des.fec_cre_des ||
        !des.fec_ret_des ||
        !des.m_id_per ||
        !des.a_id_per
      ) {
        this.alertaMensaje = {
          contenido: 'Faltan datos para la impresión, por favor, espera a que se proporcione información',
          tipo: 'error',
        };
        return;
      }
      const routeData = this.$router.resolve({name: 'NotaDeEntrega', params: {id: des.id_des}});
      window.open(routeData.href, '_blank');
    },
    restablecerFiltros() {
      this.fechaDespacho = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
      this.fechaDel = '';
      this.fechaHasta = '';
      this.cargaInicial();
    },
  },
};
</script>

<style lang="scss" scoped>
.table.despachos {
  margin: 0 auto 3rem;
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("768px");
}
</style>
