<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left">
          Filtros de búsqueda
        </header>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6">
          <v-select
            v-model="sucAFiltrar"
            :options="clientes"
            :getOptionLabel="clientes => clientes.nom_emp"
            class="outline-datalist my-2"
            @search="filtroNombre"
          >
            <template #header>
              <label :class="['datalist-label', {'activo': sucAFiltrar && Object.values(sucAFiltrar).length}]">
                Nombre del cliente o sucursal
              </label>
            </template>
            <template #option="clientes">
              {{ clientes.nom_emp }} - {{ clientes.nom_suc }}
            </template>
            <template #no-options>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6">
          <v-select
            v-model="estatusFiltrar"
            :options="EstatusPedidos"
            :reduce="estatus => estatus.nombre"
            :getOptionLabel="option => option.nombre"
            placeholder="Selecciona estatus"
            multiple
            :searchable="false"
            class="outline-datalist my-2"
          >
            <template #header>
              <label class="datalist-label">
                Estatus del pedido
              </label>
            </template>
            <template #option="{ nombre }">
              {{ nombre }}
            </template>
            <template #no-options v-if="estatusFiltrar && estatusFiltrar.length < 3">
              Selecciona un estatus
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-xl">
          <mdb-input
            class="my-2"
            v-model="fechaPedido"
            type="month"
            label="Mes"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-xl">
          <mdb-input
            class="my-2"
            v-model="fechaDel"
            type="date"
            label="Desde"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-xl">
          <mdb-input
            class="my-2"
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            outline
          />
        </div>
        <div
          v-if="$route.params.sucursal || fechaDel.length && fechaHasta.length"
          class="col d-flex justify-content-center"
        >
          <mdb-btn
            flat
            dark-waves
            icon="broom"
            class="mx-0"
            @click="RestablecerFiltros()"
          >
            Restablecer Filtros
          </mdb-btn>
        </div>
      </div>
      <ul class="table pedidos">
        <li class="encabezado sticky">
          <header class="h4-responsive">Reporte de pedidos</header>
          <p class="descripcion">
            Pedidos listados: {{pedidos && Object.keys(pedidos).length}}
          </p>
          <div class="thead claro">
            <div class="col-12 col-sm-2">
              Día
              <span class="small-block">
                Mes
              </span>
            </div>
            <div class="col-12 col-sm-5 col-md-6">
              Cliente - Sucursal
              <span class="small-block">
                Núm Pedido - Zona
              </span>
            </div>
            <div class="col-12 col-sm text-sm-right">
              Estado
            </div>
          </div>
        </li>
        <li
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </li>
        <!-- Mensaje para fecha sin pedidos -->
        <div
          v-if="pedidos && !Object.keys(pedidos).length && !cargando"
          class="mensaje-no-items pb-3"
        >
          <font-awesome-icon
            icon="calendar-times"
            size="5x"
            class="icono"
          />
          <p class="texto">
            No hay pedidos con el filtro seleccionado
          </p>
        </div>
        <li
          class="contenido cursor-pointer"
          v-for="p in pedidos"
          :key="p.id_ped"
          @click="$router.push(`/pedido/${p.id_ped}`)"
        >
          <div
            class="col-12 col-sm-2"
            data-columna="Día / Mes"
          >
            <p>
              {{p.fec_ped.split('-')[2]}}
              <span class="small-block">
                {{mesEnLetra(p.fec_ped)}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm-5 col-md-6"
            data-columna="Cliente - Sucursal / Núm Pedido - Zona"
          >
            <p>
              {{p.nom_emp}} - {{p.nom_suc}}
              <span class="small-block">
                {{p.id_ped}} - {{p.ciu_zon}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm text-sm-right"
            data-columna="Estado"
          >
            <div class="row justify-content-sm-end align-items-center">
              <div
                class="col-12 col-sm col-lg-6 order-1 order-sm-0"
                v-if="p.est_ped === 'en cola'"
              >
                <mdb-btn
                  flat
                  dark-waves
                  icon="ban"
                  title="Anular pedido"
                  size="sm"
                  class="w-100 my-n4 mx-0 px-0 text-left text-sm-center"
                  @click.native.stop="modalConfirmacionEliminar = true;pedidoId=p.id_ped"
                >
                  Anular
                </mdb-btn>
              </div>
              <div class="col-auto">
                <p>
                  <span :class="[asignarColorEstado(p.est_ped), 'font-weight-bold']">
                    {{ p.est_ped.slice(0,1).toUpperCase() + p.est_ped.slice(1) }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      titulo="¡Esta acción es irreversible!"
      mensaje="Se anulará el pedido seleccionado"
      textoBotonConfirmar="Anular"
      @cerrar="modalConfirmacionEliminar = false; pedidoId=''"
      @confirmar="cancelarPedido()"
    />
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {mdbInput, mdbBtn} from 'mdbvue';
import {EstatusPedidos} from '@/constantes/pedidos.js';
import {apiPost} from '@/funciones/api.js';
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import {asignarColorEstado, comprobarRutaHija} from '@/funciones/funciones.js';
import LoaderKel from '@/components/LoaderKel.vue';
export default {
  name: 'Pedidos',
  components: {
    mdbInput,
    mdbBtn,
    ConfirmacionEliminar,
    AlertaMensaje,
    LoaderKel,
  },
  data() {
    return {
      EstatusPedidos,
      asignarColorEstado,
      fechaPedido: '',
      pedidos: [],
      fechaDel: '',
      fechaHasta: '',
      sucAFiltrar: {},
      mes: {},
      clientes: [],
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      estatusFiltrar: '',
      pedidoId: '',
      alertaMensaje: {
        contenido: '',
      },
      cargando: false,
    };
  },
  mounted() {
    this.fechaPedido = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
  },
  watch: {
    fechaPedido() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.actualizarPedidos();
    },
    fechaHasta() {
      if (!this.fechaHasta) return;
      this.actualizarPedidos();
    },
    fechaDel() {
      if (!this.fechaHasta) return;
      this.actualizarPedidos();
    },
    nombreAFiltrar() {
      this.filtroNombre();
    },
    sucAFiltrar(value) {
      const suc = value ? '/' + this.sucAFiltrar.id_suc : '';
      this.$router.push('/pedidos' + suc);
      this.actualizarPedidos();
    },
    estatusFiltrar() {
      this.actualizarPedidos();
    },
  },
  computed: {
    siMuestraRutaHija() {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'));
    },
  },
  methods: {
    actualizarPedidos(search) {
      this.pedidos = {};
      this.cargando = true;
      let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal : 'nom=' + search;
      data += this.fechaHasta ? '&del=' + this.fechaDel + '&al=' + this.fechaHasta :
        '&y=' + this.fechaPedido.split('-')[0] + '&m=' + this.fechaPedido.split('-')[1];
      data += this.estatusFiltrar ? '&estatus=' + this.estatusFiltrar : '';
      apiPost({s: 'pedidosMv'}, data)
          .then((r) => {
            this.pedidos = r.data.ped;
            if (r.data.nfil) {
              this.clientes = r.data.nfil;
            } else {
              this.clientes = [];
            }
            this.cargando = false;
          });
    },
    mesEnLetra(fechaPlana) {
      const mess = new Date(fechaPlana.substring(5, 7));
      const opt = {month: 'short'};
      const mesLetras = mess.toLocaleDateString('es-VE', opt);
      return mesLetras;
    },
    RestablecerFiltros() {
      this.$router.push('/pedidos');
      this.sucAFiltrar = {};
      this.fechaDel = '';
      this.fechaHasta = '';
      this.estatusFiltrar = '';
      this.fechaPedido = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
      this.actualizarPedidos();
    },
    filtroNombre(search) {
      if (!search) {
        this.clientes = [];
      } else {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.actualizarPedidos(search);
        }, 500); // delay
      }
    },
    cancelarPedido() {
      if (!this.pedidoId) return;
      this.botonDeshabilitado = true;
      apiPost({s: 'cancP'}, 'ped=' + this.pedidoId)
          .then((response) => {
            if (response.data.r) {
              this.alertaMensaje = {
                contenido: 'Pedido cancelado correctamente',
                tipo: 'correcto',
              };
              this.modalConfirmacionEliminar = false;
              this.pedidos.filter((p) => p.id_ped === this.pedidoId)[0].est_ped = 'Cancelado';
            } else {
              this.alertaMensaje = {
                contenido: 'No se pudo cancelar el pedido',
                tipo: 'advertencia',
              };
            }
            this.pedidoId = '';
            this.botonDeshabilitado = false;
          }).catch(()=>{
            this.pedidoId = '';
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: `Ocurrió un error cancelando el pedido.`,
              tipo: 'error',
            };
          });
    },
  },
};

</script>

<style lang="scss" scoped>
.table.pedidos {
  margin: 0 auto 3rem;
  max-width: 900px;
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
</style>
