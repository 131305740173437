<template>
  <mdb-modal
    centered
    elegant
    scrollable
    :show="mostrarModal"
  >
    <mdb-modal-header
      class="align-items-center flex-wrap"
      :close="false"
    >
        <mdb-btn
          flat
          dark-waves
          icon="chevron-left"
          icon-class="fa-lg"
          class="m-0 py-2 px-3"
          @click="esEditar ? $emit('cerrar', false) : emitirCantidad()"
        >
          Atrás
        </mdb-btn>
        <mdb-btn
          v-if="existenItemsRellenados"
          flat
          dark-waves
          class="m-0 py-2 px-3"
          @click="expandirItemsRellenados()"
        >
          {{ mostrarProductosExpandidos && !esEditar ? 'Ver productos' : !esEditar ? 'Ocultar productos' : esEditar && mostrarProductosExpandidos ? 'Ocultar productos' : 'Expandir productos'  }}
        </mdb-btn>
        <mdb-btn
          v-else-if="!existenItemsRellenados && !esEditar && esDevolucion"
          flat
          dark-waves
          class="m-0 py-2 px-3"
          @click="$emit('cancelarDevolucion'); $emit('cerrar', false)"
        >
          Cancelar
        </mdb-btn>
      <mdb-modal-title class="col-12 order-sm-1 text-center">
        {{tituloTomarCantidad}}
      </mdb-modal-title>
      <header class="col-12 order-3 h6-responsive text-center">
        {{capitalizar(sucursal.nombre)}}
      </header>
    </mdb-modal-header>
    <mdb-modal-body class="pt-0">
      <div
        v-for="(pre, i) in presentaciones"
        :key="'pre' + i"
      >
        <!-- Nombre de la categoria -->
        <header class="h5-responsive">
          {{pre.nom_cat}}
        </header>
        <!-- Productos -->
        <div
          v-for="(producto, I) in pre.productos"
          :key="'producto'+I"
          :class="['producto', {'activo': indexCartasExpandidas.includes(I+'carta')}]"
        >
          <div
            class="encabezado"
            @click.stop.prevent="abrirCarta(I)"
          >
            <header>
              {{producto.nom_pro}}
            </header>
            <font-awesome-icon
              :icon="indexCartasExpandidas.includes(I+'carta') ? 'minus' : 'plus'"
              :class="['icono', {'fa-rotate-180': indexCartasExpandidas.includes(I+'carta')}]"
            />
          </div>
          <!-- Presentaciones -->
          <div v-if="indexCartasExpandidas.includes(I+'carta')">
            <div
              v-for="(presentacion, Ip) in producto.presentaciones"
              :key="'presentacion'+Ip"
              class="detalles-presentacion"

            >
              <template v-if="contieneHabPre(presentacion)">
                <div class="descripcion col-12 col-sm px-0 pr-sm-2">
                  <div
                    v-if="esInventario"
                    class="custom-control custom-switch secundario"
                  >
                    <input
                      v-model="presentacion.check"
                      :id="`customSwitches-presentacion-${Ip}`"
                      type="checkbox"
                      class="custom-control-input secundario"
                      @click="asignarPropiedad(presentacion, !presentacion.check)"
                    >
                    <label
                      class="custom-control-label"
                      :for="`customSwitches-presentacion-${Ip}`"
                    >
                      <span class="texto">
                        {{presentacion.check ? 'PRESENTE' : ''}}
                      </span>
                    </label>
                  </div>
                  <p class="text-uppercase">
                    {{presentacion.des_pre}}
                    <small class="d-block">
                      <span class="texto-primario mr-2">Bs. {{presentacion.pvmp_pre}}</span>
                      {{presentacion.fec_mod_pre_pre.substring(0,10)}}
                    </small>
                  </p>
                </div>
                <div class="col-4 col-sm-2 pl-0 pl-sm-2 pr-2 text-right">
                  {{presentacion.uni_paq_pre}}
                  <small class="d-block text-uppercase text-muted">
                    {{presentacion.paq_pre}}
                  </small>
                </div>
                <div class="col-6 col-sm-3 ml-sm-1 pl-2 pr-0">
                  <mdb-input
                    v-model.number="presentacion.cantidad"
                    type="number"
                    label="Cantidad"
                    :min="0"
                    @input="presentacion.cantidad = Math.abs(Number($event)); asignarPropiedad(presentacion, presentacion.check)"
                    size="sm"
                    placeholder="Cantidad"
                    class="my-2 text-right"
                    outline
                  />
                  <small
                    v-if="esPedido"
                    class="form-text text-muted text-right mt-n2"
                  >
                    <!-- En caso es pedido se aprecia un monto total del pedido (presentación) -->
                    Bs. {{presentacion.cantidad ? (presentacion.pvmp_pre * presentacion.cantidad) : '0'}}
                  </small>
                </div>
              <div
                v-if="esDevolucion"
                class="col-12 px-0"
              >
                <!-- En caso es devolución se pueden seleccionar presentaciones -->
                <div class="col-lg">
                  <mdb-input
                    v-model.trim="presentacion.observacion"
                    type="textarea"
                    placeholder="Ej: Se encuentra defectuosa la presentación"
                    class="my-0 text-right"
                    outline
                    @input="asignarPropiedad(presentacion)"
                    @click.stop.prevent
                  />
                </div>
              </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <template v-if="esPedido">
        <header class="h5-responsive my-">Selección tipo de pago</header>
        <form
          class="row"
          @submit.prevent
        >
          <div class="col-12 col-sm-6">
            <div class="md-form md-outline outline-select my-2">
              <select
                v-model="pago.pag"
                id="condicion-pago-select-form"
                class="custom-select"
              >
                <option class="d-none" value="" disabled>
                  Seleccione condición
                </option>
                <option
                  v-for="condiciones in condicionesPagoSelect"
                  :key="`condicion-pago-${condiciones.id_cp}`"
                  :value="condiciones.id_cp"
                >
                  {{condiciones.nom_cp}}
                </option>
              </select>
              <label
                for="condicion-pago-select-form"
                :class="pago.pag ? 'label-active' : 'label-inactive'"
              >
                Condiciones de pago
              </label>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="md-form md-outline outline-select my-2">
              <select
                v-model="pago.tpre"
                id="tipo-precio-select-form"
                class="custom-select"
              >
                <option class="d-none" value="" disabled>
                  Selecionar tipo
                </option>
                <option
                  v-for="precios in tipoPrecioSelect"
                  :key="`precios-${precios.id_tpre}`"
                  :value="precios.id_tpre"
                >
                  {{precios.nom_tpre}}
                </option>
              </select>
              <label
                for="tipo-precio-select-form"
                :class="pago.tpre ? 'label-active' : 'label-inactive'"
              >
                Tipo de precio
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="md-form md-outline outline-select my-2">
              <select
              :disabled="tipoDocumentoSelect.length === 1"
                v-model="pago.doc"
                id="tipo-documento-select-form"
                class="custom-select"
              >
                <option class="d-none" value="" disabled>
                  Seleccione tipo de documento
                </option>
                <option
                  v-for="documento in tipoDocumentoSelect"
                  :value="documento.id_tdoc"
                  :key="documento.id_tdoc"
                >
                  {{documento.nom_tdoc}}
                </option>
              </select>
              <label
                for="tipo-documento-select-form"
                :class="pago.doc ? 'label-active' : 'label-inactive'"
              >
                Tipo documento
              </label>
            </div>
          </div>
          <div class="col-12">
            <mdb-input
              v-model.number="pago.dsc"
              label="Descuento"
              type="number"
              class="my-2"
              outline
            />
          </div>
          <div class="col-12">
            <mdb-input
              v-model.trim="pago.obs"
              type="textarea"
              label="Observaciones (opcional)"
              :rows="5"
              class="my-2"
              outline
            />
          </div>
        </form>
        <mdb-btn
          flat
          dark-waves
          icon="print"
          class="d-block mx-auto m-0 py-2 px-3"
          @click="imprimirPresupuesto()"
        >
          Generar presupuesto
        </mdb-btn>
      </template>
    </mdb-modal-body>
    <mdb-modal-footer
      v-if="esEditar"
      class="justify-content-around"
    >
      <mdb-btn
        :title="`Actualizar ${esPedido ? 'pedido' : esInventario ? 'inventario' : 'devolución'}`"
        color="secundario"
        icon="pen"
        type="button"
        @click="emitirCantidad"
      >
        Actualizar {{`${esPedido ? 'pedido' : esInventario ? 'inventario' : 'devolución'}`}}
      </mdb-btn>
    </mdb-modal-footer>
    <OpcionesImpresion
      :mostrar-modal="opcionesDeImpresion.mostrarModal"
      :codigoDeImpresion="opcionesDeImpresion.codigoDeImpresion"
      :nombre-documento="opcionesDeImpresion.nombreDocumento"
      @cerrar="opcionesDeImpresion.mostrarModal = $event"
    />
  </mdb-modal>
</template>

<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
} from 'mdbvue';
import {apiPost} from '@/funciones/api.js';
import OpcionesImpresion from '@/components/OpcionesImpresion.vue';
import {cotizaPedido} from '@/funciones/formatosImpresion.js';
import {capitalizar} from '@/funciones/funciones.js';

export default {
  name: 'TomarCantidad',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    OpcionesImpresion,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esPedido: {
      type: Boolean,
      required: false,
      default: false,
    },
    esInventario: {
      type: Boolean,
      required: false,
      default: false,
    },
    esDevolucion: {
      type: Boolean,
      required: false,
      default: false,
    },
    esEditar: {
      type: Boolean,
      required: false,
      default: false,
    },
    sucursal: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: '',
          nombre: '',
        };
      },
    },
    notasDeEntrega: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    inventarioGuardado: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    pedidoGuardado: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    devolucionGuardada: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    inventarioAEditar: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    devolucionAEditar: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    adicionalesDelPedido: {
      type: Object,
      required: false,
      default: () => {
        return {
          pag: '',
          doc: '1',
          tpre: '',
          dsc: '',
          obs: '',
          estado: '',
        };
      },
    },
  },
  data() {
    return {
      opcionesDeImpresion: {
        codigoDeImpresion: [],
        mostrarModal: false,
        nombreDocumento: '',
      },
      existenItemsRellenados: false,
      mostrarProductosExpandidos: false,
      presentaciones: {},
      indexCartasExpandidas: [],
      condicionesPagoSelect: [],
      tipoPrecioSelect: [],
      tipoDocumentoSelect: [],
      capitalizar,
      inventario: {},
      pedido: {},
      devolucion: {},
      fechaImpresion: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      pago: {
        pag: '',
        doc: '1',
        tpre: '',
        dsc: '',
        obs: '',
      },
    };
  },
  watch: {
    mostrarModal: function(valor) {
      if (valor && this.esPedido) {
        this.obtenerDatosSelectPago();
      }
      if (!valor) {
        this.limpiarCampos();
      }
      this.cargaInicial();
    },
    adicionalesDelPedido: function(valor) {
      if (valor) {
        Object.assign(this.pago, {
          obs: valor.obs,
          dsc: Number(valor.dsc),
        });
      }
    },
  },
  computed: {
    tituloTomarCantidad() {
      let titulo = '';
      if (this.esEditar && this.esPedido) {
        return titulo = 'Editar Pedido';
      }
      if (this.esEditar && this.esInventario) {
        return titulo = 'Editar Inventario';
      }
      if (this.esEditar && this.esDevolucion) {
        return titulo = 'Editar Devolución';
      }

      if (!this.esEditar && this.esPedido) {
        titulo = 'Tomar Pedido';
      }
      if (!this.esEditar && this.esInventario) {
        titulo = 'Tomar Inventario';
      }
      if (!this.esEditar && this.esDevolucion) {
        titulo = 'Tomar Devolución';
      }
      return titulo;
    },
    categoriaDeProductosSeleccionados() {
      return Object.values(this.presentaciones).filter((cat) => {
        return Object.values(cat.productos).find((pro) => {
          return Object.values(pro.presentaciones).find((pre) => {
            return pre.cantidad;
          });
        });
      });
    },
  },
  methods: {
    expandirItemsRellenados(validarExistenExpandidos) {
      if (!validarExistenExpandidos) {
        this.mostrarProductosExpandidos = !this.mostrarProductosExpandidos;
      }
      let existenItemsRellenados = false;
      for (const categoria in this.presentaciones) {
        if (categoria) {
          const categoriaConFormato = this.presentaciones[categoria];
          for (const producto in categoriaConFormato.productos) {
            if (producto) {
              const productoActual = categoriaConFormato.productos[producto];
              if (productoActual.presentaciones) {
                for (const presentacionesProducto in productoActual.presentaciones) {
                  if (presentacionesProducto) {
                    const presentacionActual = productoActual.presentaciones[presentacionesProducto];
                    if (this.existePresentacionRellenada(presentacionActual)) {
                      if (!validarExistenExpandidos) {
                        this.abrirCarta(producto);
                        break;
                      } else {
                        return existenItemsRellenados = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } return existenItemsRellenados;
    },
    limpiarCampos() {
      this.presentaciones = {};
      this.indexCartasExpandidas = [];
      this.inventario = {};
      this.pedido = {};
      this.devolucion = {};
      this.mostrarProductosExpandidos = false;
      this.existenItemsRellenados = false;
      this.pago = {
        pag: '',
        doc: '1',
        tpre: '',
        dsc: '',
        obs: '',
      };
    },
    contieneHabPre(producto) {
      if (this.esPedido && Number(producto.hab_pre) !== 1) {
        return false;
      }
      return true;
    },
    existePresentacionRellenada(presentacion) {
      return (presentacion.cantidad || presentacion.observacion || presentacion.check);
    },
    emitirCantidad() {
      if (this.esInventario) {
        if (Object.entries(this.inventario).length) {
          return this.enviarTomaInventario(this.inventario);
        }
      }
      if (this.esPedido) {
        if (Object.entries(this.pedido).length) {
          if (!this.pago.pag || !this.pago.tpre || !this.pago.doc) {
            return this.alertaMensaje('No se puede realizar la toma de pedido sin especificar el pago', 'advertencia');
          }
          if (typeof this.pago.dsc == 'string') {
            return this.alertaMensaje('Especifica el descuento, asi sea 0', 'advertencia');
          }
          const pedido = JSON.parse(JSON.stringify(this.pedido));
          const pago = JSON.parse(JSON.stringify(this.pago));
          return this.enviarTomaPedido(pedido, pago);
        }
      }
      if (this.esDevolucion) {
        if (Object.entries(this.devolucion).length) {
          return this.enviarTomaDevolucion(this.devolucion);
        }
      }
      this.$emit('cerrar', false);
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    cargaInicial() {
      this.resultados = {};
      apiPost({s: 'presentacionesMv'}, '')
          .then((res) => {
            if (res.data.pre) {
              this.enlistarPresentaciones(res.data.pre);
            }
          });
    },
    async obtenerDatosSelectPago() {
      try {
        const [condicionesPago, tipoPrecio, tipoDocumento] = await Promise.all([
          apiPost({s: 'cond_pagoMv'}, ''),
          apiPost({s: 'tipo_precioMv'}, ''),
          apiPost({s: 'tipo_docMv'}, ''),
        ]);
        this.condicionesPagoSelect = condicionesPago.data.cp;
        this.tipoPrecioSelect = tipoPrecio.data.tpre;
        this.tipoDocumentoSelect = tipoDocumento.data.tdoc;
        // En caso existan datos de un pedido anterior se rellenan los select del pedido
        if (this.adicionalesDelPedido) {
          if (this.adicionalesDelPedido.doc) {
            this.tipoDocumentoSelect.forEach((td) => {
              if (td.nom_tdoc == this.adicionalesDelPedido.doc) {
                this.pago.doc = td.id_tdoc;
              }
            });
          }
          if (this.adicionalesDelPedido.tpre) {
            this.tipoPrecioSelect.forEach((tp) => {
              if (tp.nom_tpre == this.adicionalesDelPedido.tpre) {
                this.pago.tpre = tp.id_tpre;
              }
            });
          }
          if (this.adicionalesDelPedido.pag) {
            this.condicionesPagoSelect.forEach((cp) => {
              if (cp.nom_cp == this.adicionalesDelPedido.pag) {
                this.pago.pag = cp.id_cp;
              }
            });
          }
        }
      } catch (error) {
        return this.alertaMensaje('Ocurrió un error obteniendo los datos para los campos de pago', 'error');
      }
    },
    abrirCarta(index) {
      if (this.indexCartasExpandidas.includes(index + 'carta')) {
        this.indexCartasExpandidas = this.indexCartasExpandidas.filter((i) => i !== (index + 'carta'));
      } else {
        this.indexCartasExpandidas.push(index + 'carta');
      }
    },
    asignarPropiedad(presentacion, valorCheck) {
      if (this.esInventario) {
        this.inventario[presentacion.id_pre] = {
          cantidad: presentacion.cantidad,
          presencia: valorCheck,
        };
        this.existenItemsRellenados = true;
        if (!presentacion.cantidad) {
          if (this.esEditar) {
            // En caso se edite un inventario y se desee eliminar la presentación
            return delete this.inventario[presentacion.id_pre].cantidad;
          }
        }
      }
      if (this.esPedido) {
        if (!presentacion.cantidad) {
          if (!this.esEditar) {
            return this.alertaMensaje('La cantidad del pedido no puede ser 0', 'advertencia');
          } else {
            // En caso se edite un pedido y se desee eliminar la presentación
            return delete this.pedido[presentacion.id_pre];
          }
        }
        this.pedido[presentacion.id_pre] = {
          cantidad: presentacion.cantidad,
        };
        this.existenItemsRellenados = true;
      }
      if (this.esDevolucion) {
        if (!this.esEditar) {
          if (!presentacion.observacion && !this.inventario[presentacion.id_pre]) {
            return this.alertaMensaje('Es requerido especificar un motivo por el cual devuelve la presentación', 'advertencia');
          }
          if (!presentacion.cantidad && !this.inventario[presentacion.id_pre]) {
            return this.alertaMensaje('Es necesario especificar la cantidad de la presentación para continuar', 'advertencia');
          }
          if (presentacion.observacion.trim() === '') {
            return this.alertaMensaje('La observación no puede estar vacía', 'advertencia');
          }
          if (!presentacion.cantidad) {
            return this.alertaMensaje('La cantidad de devolución no puede ser 0', 'advertencia');
          }
        } else {
          // En caso se edite una devolucion y se desee eliminar la presentación (se borra también la obs)
          if (!presentacion.cantidad) {
            return delete this.devolucion[presentacion.id_pre];
          }
        }
        this.devolucion[presentacion.id_pre] = {
          cantidad: presentacion.cantidad,
          motivo: presentacion.observacion,
        };
        this.existenItemsRellenados = true;
      }
    },
    enviarTomaPedido(pedido, pago) {
      this.$emit('tomarPedido', {pedido, pago});
      if (!this.notasDeEntrega.length) {
        this.$emit('cerrar', false);
        this.limpiarCampos();
      }
    },
    enviarTomaInventario(inventario) {
      this.$emit('tomarInventario', inventario);
      this.$emit('cerrar', false);
      this.limpiarCampos();
    },
    enviarTomaDevolucion(devolucion) {
      this.$emit('tomarDevolucion', devolucion);
      this.$emit('cerrar', false);
      this.limpiarCampos();
    },
    enlistarPresentaciones(Data) {
      let data = Data;
      // En caso hay notas de pedido se agregan cantidades anteriores al pedido
      if (this.notasDeEntrega.length) {
        this.notasDeEntrega.forEach((n) => {
          data = data.map((p) => {
            const pro = p;
            if (p && n && p.id_pre === n.id_pre) {
              pro.cantidad = parseInt(n.can_ped_np);
              // Agregar al pedido los datos anteriores de las cantidades de la presentacion
              this.pedido[p.id_pre] = {
                cantidad: parseInt(p.cantidad),
              };
            }
            return pro;
          });
        });
      }
      if (!this.esEditar) {
        if (this.esInventario && !this.esPedido && !this.esDevolucion && this.inventarioGuardado && Object.keys(this.inventarioGuardado.presentaciones).length) {
          for (const key in this.inventarioGuardado.presentaciones) {
            if (Object.hasOwnProperty.call(this.inventarioGuardado.presentaciones, key)) {
              data = data.map((presentacion) => {
                if (presentacion && presentacion.id_pre === key) {
                  presentacion.cantidad = Number(this.inventarioGuardado.presentaciones[key].cantidad);
                  presentacion.check = this.inventarioGuardado.presentaciones[key].presencia;
                  this.inventario[key] = {
                    cantidad: this.inventarioGuardado.presentaciones[key].cantidad,
                    presencia: this.inventarioGuardado.presentaciones[key].presencia,
                  };
                }
                return presentacion;
              });
            }
          }
        }
        if (this.esPedido && !this.esInventario && !this.esDevolucion && this.pedidoGuardado && Object.keys(this.pedidoGuardado.presentaciones).length) {
          for (const key in this.pedidoGuardado.presentaciones) {
            if (Object.hasOwnProperty.call(this.pedidoGuardado.presentaciones, key)) {
              data = data.map((presentacion) => {
                if (presentacion && presentacion.id_pre === key) {
                  presentacion.cantidad = Number(this.pedidoGuardado.presentaciones[key].cantidad);
                  this.pedido[key] = {
                    cantidad: Number(this.pedidoGuardado.presentaciones[key].cantidad),
                  };
                }
                return presentacion;
              });
            }
          }
          this.pago.doc = this.pedidoGuardado.doc;
          this.pago.tpre = this.pedidoGuardado.tpre;
          this.pago.pag = this.pedidoGuardado.pag;
          this.pago.obs = this.pedidoGuardado.obs;
          this.pago.dsc = this.pedidoGuardado.dsc;
        }
        if (this.esDevolucion && !this.esInventario && !this.esPedido && this.devolucionGuardada && Object.keys(this.devolucionGuardada.presentaciones).length) {
          for (const key in this.devolucionGuardada.presentaciones) {
            if (Object.hasOwnProperty.call(this.devolucionGuardada.presentaciones, key)) {
              data = data.map((presentacion) => {
                if (presentacion && presentacion.id_pre === key) {
                  presentacion.cantidad = Number(this.devolucionGuardada.presentaciones[key].cantidad);
                  presentacion.observacion = this.devolucionGuardada.presentaciones[key].motivo;
                  this.devolucion[key] = {
                    cantidad: Number(this.devolucionGuardada.presentaciones[key].cantidad),
                    motivo: this.devolucionGuardada.presentaciones[key].motivo,
                  };
                }
                return presentacion;
              });
            }
          }
        }
      }

      if (this.inventarioAEditar.length) {
        this.inventarioAEditar.forEach((i) => {
          data = data.map((p) => {
            const pro = p;
            if (p && i && p.id_pre === i.id_pre) {
              pro.cantidad = parseInt(i.can_ni);
              pro.check = i.pre_ni === '1',
              // Agregar al inventario los datos anteriores de las cantidades y presencia de la presentacion
              this.inventario[i.id_pre] = {
                cantidad: parseInt(i.can_ni),
                presencia: i.pre_ni === '1',
                id_isu: i.id_isu,
              };
            }
            return pro;
          });
        });
      }
      if (this.devolucionAEditar.length) {
        this.devolucionAEditar.forEach((d) => {
          data = data.map((p) => {
            const pro = p;
            if (p && d && p.id_pre === d.id_pre) {
              pro.cantidad = parseInt(d.can_nd);
              pro.observacion = d.obs_nd;
              // Agregar a la devolucion los datos anteriores de las cantidades y observacion de la presentacion
              this.devolucion[d.id_pre] = {
                cantidad: parseInt(d.can_nd),
                motivo: d.obs_nd,
                id_dev: d.id_dev,
              };
            }
            return pro;
          });
        });
      }
      const presentaciones = {};
      data.forEach((e) => {
        if (!presentaciones[e.nom_cat]) {
          presentaciones[e.nom_cat] = {
            nom_cat: e.nom_cat,
            productos: {},
          };
        }
        if (!presentaciones[e.nom_cat].productos[e.nom_pro]) {
          presentaciones[e.nom_cat].productos[e.nom_pro] = {
            nom_pro: e.nom_pro,
            presentaciones: {},
          };
        }
        if (!presentaciones[e.nom_cat].productos[e.nom_pro].presentaciones[e.des_pre]) {
          presentaciones[e.nom_cat].productos[e.nom_pro].presentaciones[e.des_pre] = {
            id_pre: e.id_pre,
            des_pre: e.des_pre,
            pvmp_pre: e.pvmp_pre,
            fec_mod_pre_pre: e.fec_mod_pre_pre,
            uni_paq_pre: e.uni_paq_pre,
            paq_pre: e.paq_pre,
            hab_pre: e.hab_pre,
            // Otros campos importantes de la presentacion
            cantidad: e.cantidad,
            check: e.check,
            observacion: e.observacion,
          };
        }
        // presentaciones[e.id_emp].tipos[e.id_tcl_suc] = e.id_tcl_suc
        // presentaciones[e.id_emp].zonas[e.id_zon_suc] = e.id_zon_suc
      });
      // Formatear
      this.presentaciones = presentaciones;
      if (this.expandirItemsRellenados(true)) {
        this.existenItemsRellenados = true;
      }
    },
    imprimirPresupuesto() {
      if (!this.categoriaDeProductosSeleccionados.length) {
        return this.alertaMensaje('Debes seleccionar al menos un producto para el presupuesto.', 'advertencia');
      }
      const PRESUPUESTO = [
        // Fila de encabezados
        [
          {
            stack: [
              'PRODUCTO',
              {
                text: 'PRESENTACIÓN',
                fontSize: 8,
              },
            ],
          },
          {
            alignment: 'right',
            text: 'PEDIDO',
          },
          {
            alignment: 'right',
            text: 'PRECIO',
          },
        ],
      ];
      let montoTotal = 0;
      this.categoriaDeProductosSeleccionados.forEach((cat) => {
        PRESUPUESTO.push([
          {
            text: `${cat.nom_cat.toUpperCase()}`,
            colSpan: 3,
            alignment: 'center',
          }, {}, {},
        ]);
        Object.values(cat.productos).forEach((pro) => {
          Object.values(pro.presentaciones).forEach((pre) => {
            if (pre.cantidad) {
              montoTotal += pre.cantidad * pre.pvmp_pre;
              PRESUPUESTO.push([
                {
                  stack: [
                    pro.nom_pro,
                    {
                      text: pre.des_pre,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  text: pre.cantidad,
                  alignment: 'right',
                },
                {
                  text: pre.pvmp_pre,
                  alignment: 'right',
                },
              ]);
            }
          });
        });
      });
      PRESUPUESTO.push([
        {
          text: 'TOTAL',
          colSpan: 2,
        },
        {},
        {
          text: `$ ${montoTotal.toFixed(2)}`,
          alignment: 'right',
        },
      ]);
      if (this.pago.dsc) {
        const desc = montoTotal - (montoTotal * (this.pago.dsc / 100));
        PRESUPUESTO.push([
          {
            stack: [
              {
                text: `DESCUENTO`,
              },
              {
                text: `-${this.pago.dsc}%`,
                fontSize: 8,
              },
            ],
            colSpan: 2,
          },
          {},
          {
            text: `$ ${desc}`,
            alignment: 'right',
          },
        ]);
      }
      const {nombre, formato}= cotizaPedido(this.fechaImpresion, this.pago.obs, PRESUPUESTO);
      this.opcionesDeImpresion = {
        codigoDeImpresion: formato,
        nombreDocumento: nombre,
        mostrarModal: true,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
  .custom-switch {
    margin: -.75rem 1rem 0 0;
    .texto {
      bottom: -39px;
      color: $gris-muted;
      font-size: 10px;
      left: -44px;
      position: absolute;
    }
  }
  .producto {
    margin: .5rem 0;

    &:last-child {
      margin-bottom: 1rem;
    }

    .encabezado {
      align-items: center;
      background-color: lighten($secundario, 48%);
      border: 1px solid lighten($secundario,40%);
      border-style: none none solid;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 0 -1rem;
      padding: .5rem 1rem;
      transition: background-color .25s ease;

      header {
        @include agregar-prefijos(user-select, none)
      }

      .icono {
        margin-right: .5rem;
        transition: transform .25s ease;
      }
    }

    // Cuando se despliega los detalles del producto
    &.activo .encabezado {
      background-color: lighten($secundario, 32%);
    }
  }
  .detalles-presentacion {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .descripcion {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;

      div {
        width: auto;
      }
      p {
        margin-bottom: 0;
        flex-grow: 2;
      }
    }
  }
</style>
