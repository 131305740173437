<template>
  <section>
    <div id="app" v-if="!cargandoApp">
    <Menu v-if="!ocultarMenu" @alerta-mensaje="alertaMensaje = $event"/>
    <div id="contenido" :class="{'ocultar-menu': ocultarMenu}">
      <router-view/>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      :tiempoAutoOcultar="1500"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
     <AlertaSnackBar
      :alerta-snack-bar="{
        color: 'informativo',
        contenido: 'Nueva versión disponible',
      }"
      :btn-accion="{ icono: 'sync', texto: 'Clic para actualizar'}"
      :mostrar-snack-bar="notificacionNuevaVersion"
      @btn-accion="actualizarApp"
    />
  </section>
</template>
<script>
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import Menu from '@/components/Menu.vue';
import AlertaSnackBar from '@/components/AlertaSnackBar.vue';
export default {
  name: 'App',
  components: {
    AlertaMensaje,
    Menu,
    AlertaSnackBar,
  },
  data() {
    return {
      alertaMensaje: {contenido: ''},
      cargandoApp: false,
      detalles: null,
      estaActualizando: false,
      notificacionNuevaVersion: false,
    };
  },
  methods: {
    mostrarActualizacion(e) {
      this.detalles = e.detail;
      this.notificacionNuevaVersion = true;
    },
    actualizarApp() {
      this.notificacionNuevaVersion = false;
      if (!this.detalles || !this.detalles.waiting) return;
      this.detalles.waiting.postMessage({type: 'SKIP_WAITING'});
    },
  },
  computed: {
    ocultarMenu() {
      const ruta = this.$route.path.slice(1).split('/')[0];
      return ruta === 'login';
    },
  },
  created() {
    this.cargandoApp = true;
    setTimeout(()=> this.cargandoApp = false, 250);
    document.addEventListener('updateSW', this.mostrarActualizacion, {once: true});
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.estaActualizando) return;
        this.estaActualizando = true;
        window.location.reload();
      });
    }
  },
};
</script>

<style lang="scss">
$image-path: '~@/../node_modules/mdbvue/lib/img';
@import '~@/../node_modules/mdbvue/lib/scss/mdb-free.scss';

// Estilos para mostrar / ocultar Menu en mobile
#contenido:not(.ocultar-menu) {
  transition-property: margin-left, width, z-index;
  transition-delay: 0s, 0s, .6s;
  transition-duration: .5s;
  transition-timing-function: ease;

  @media screen and (min-width: 992px) {
    position: relative;
    margin-left: 205px;
    width: calc(100% - 205px); /* - ancho del navbar */
    z-index: 101;
  }
}

// Estilos para comprimir el Menu en desktop
.comprimido + #contenido:not(.ocultar-menu) {
  @media screen  and (min-width: 992px) {
    margin-left: 64px;
    width: calc(100% - 64px); /* - ancho del navbar */
    z-index: 99;
  }
}
</style>
