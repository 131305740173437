<template>
  <section id="contenedorHijoSection">
    <div id="contenedorHijoDiv" class="contenedor-hijo">
      <div class="container">
        <div class="row justify-content-center justify-content-md-between mb-3 ml-2 ml-sm-0">
          <div class="col-auto px-0">
            <mdb-btn
              flat
              dark-waves
              icon="arrow-left"
              type="button"
              title="Regresar"
              @click="$router.go(-1)"
            >
              Regresar
            </mdb-btn>
          </div>
          <div
            :class="['col-auto px-0',
              {'cursor-not-allowed': !(pedidoActividad.length
                || inventarioActividad.length || devolucionActividad.length)}]"
            :title="!(pedidoActividad.length || inventarioActividad.length || devolucionActividad.length)
              ? 'No hay información para actualizar' : 'Editar actividad'"
          >
            <mdb-btn
              v-if="actividad && actividad.length"
              flat
              dark-waves
              icon="pen"
              type="button"
              title="Editar actividad"
              :class="[{'disabled': !(pedidoActividad.length
                || inventarioActividad.length || devolucionActividad.length)}]"
              @click="mostrarModalTomarCantidad= !mostrarModalTomarCantidad"
            >
              Editar actividad
            </mdb-btn>
          </div>
        </div>
        <div
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </div>
        <div v-else>
          <div
            v-if="actividad && actividad.length"
            class="encabezado-detalles"
          >
            <header class="h4-responsive text-center pt-3">Detalles de la actividad</header>
            <div class="row mx-0 mt-2">
              <div class="col-12 col-md-4">
                <span class="small-block font-titulo">Empresa, Sucursal</span>
                <p class="text-capitalize">{{ actividad[0] && actividad[0].nom_emp }}, {{ actividad[0] && actividad[0].nom_suc }}</p>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <span class="small-block font-titulo">Encargado</span>
                <p class="text-capitalize">{{ actividad[0] && actividad[0].nom_per }} {{ actividad[0] && actividad[0].ape_per }}</p>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <span class="small-block font-titulo">Fecha</span>
                <p class="text-capitalize">{{ fecha ? formatearFechaYHora(fecha) : 'Sin especificar' }}</p>
              </div>
              <div class="col-12 col-sm-6">
                <span class="small-block font-titulo">Acciones</span>
                <p>
                  <span
                    v-for="(accion, i) in acciones"
                    :key="i"
                    :class="['text-capitalize', asignarIcono(accion.nom_acc)]"
                  >
                    {{ accion.nom_acc ? accion.nom_acc : 'Sin acción'}}{{i == (acciones.length - 1) ? '.' : ', '}}
                  </span>
                </p>
              </div>
              <div class="col-12 col-sm-6">
                <span class="small-block font-titulo">Zona</span>
                <p class="text-capitalize">{{ actividad[0] && actividad[0].est_zon + ', '}}{{ actividad[0] && actividad[0].sec_zon + ', ' }}{{ actividad[0] && actividad[0].dir_suc }}</p>
              </div>
              <div class="col-12 col-sm" v-if="actividad[0].obs_int">
                <span class="small-block font-titulo">Observaciones</span>
                <p>{{ actividad[0] && actividad[0].obs_int }}</p>
              </div>
            </div>
          </div>
          <div class="px-3 mt-4 mb-3" v-if="pago && pago.length" >
            <div class="row justify-content-between">
              <div class="row mx-0 mt-2">
                <h4
                  class="col-12 mb-3 cursor-pointer"
                  @click="$router.push(`/cobranzas/${pago[0].id_aco}`)"
                >
                  Detalles del pago
                  <font-awesome-icon icon="external-link-alt" class="ml-3 text-muted" size="xs" />
                </h4>
                <div class="col-12 col-sm-6 col-md-8 col-xl-6">
                  <span class="small-block font-titulo">Cliente, Sucursal</span>
                  <p>
                    <span :class="pago && pago.length && pago[0] && !pago[0].nom_emp ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                      {{pago && pago.length && pago[0] && pago[0].nom_emp ? pago[0].nom_emp : 'Cliente no especificado' }},
                    </span>
                    <span :class="pago && pago.length && pago[0] && !pago[0].nom_suc ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                      {{ pago && pago.length && pago[0] && pago[0].nom_suc ? pago[0].nom_suc : 'Sucursal no especificada' }}
                    </span>
                  </p>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Banco</span>
                  <p :class="['text-capitalize', {
                      'text-muted font-italic user-select-none': pago && pago.length && pago[0] && !pago[0].nom_cba,
                  }]">{{ pago && pago.length && pago[0] && pago[0].nom_cba ? pago[0].nom_cba : 'Sin banco especificado' }}</p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo mr-n1">Estatus del pago</span>
                  <p
                    v-if="pago && pago[0].est_aco"
                    :class="[asignarColorEstado(pago[0].est_aco), 'font-weight-bold'] "
                  >
                    <font-awesome-icon :icon="EstatusPagoIconos[pago[0].est_aco]" />
                    {{pago && pago.length && pago[0].est_aco.slice(0,1).toUpperCase() + pago[0].est_aco.slice(1) }}
                  </p>
                  <p
                    v-else
                    class="text-muted font-italic user-select-none"
                  >
                    Sin especificar
                  </p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Número de pago</span>
                  <p class="text-capitalize">{{ pago && pago[0] && pago[0].id_aco }}</p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Moneda</span>
                    <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].nom_mon }} / {{ pago && pago.length && pago[0] && pago[0].sim_mon }} </p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Método de pago</span>
                  <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].nom_mep }}</p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo mr-n2">Referencia de pago</span>
                    <p :class="['text-capitalize',
                  {
                      'text-muted font-italic user-select-none': pago && pago.length && pago[0] && !pago[0].ref_aco,
                  }
                  ]">{{ pago && pago.length && pago[0] && pago[0].ref_aco ?  pago[0].ref_aco : 'Sin referencia especificada' }}</p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Tasa de cambio</span>
                  <p class="text-capitalize">{{ pago && pago.length && pago[0] && convertirNumeroAFixed(pago[0].tas_aco) }}</p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Monto de pago</span>
                  <p class="text-capitalize">{{ pago && pago.length && pago[0] && convertirNumeroAFixed(pago[0].mon_aco) }} {{pago && pago.length && pago[0] && pago[0].sim_mon}}</p>
                </div>
                <div class="col-6 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Fecha del pago</span>
                  <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].fec_rec_aco ? pago[0].fec_rec_aco : 'Sin fecha especificada' }}</p>
                </div>
                <div class="col-12 col-md-4 col-xl-3">
                  <span class="small-block font-titulo">Indexación del pago</span>
                  <p :class="['text-capitalize',{'text-muted font-italic user-select-none':pago && pago.length && pago[0] && !pago[0].ind_aco} ]">{{ pago && pago.length && pago[0] && pago[0].ind_aco ? pago[0].ind_aco : 'Sin especificar'}}</p>
                </div>
                <div class="col-12">
                  <span class="small-block font-titulo">Observación</span>
                  <p
                    :class="{
                      'text-muted font-italic user-select-none': pago && pago.length && pago[0] && !pago[0].obs_aco,
                    }"
                  >
                    {{
                      (pago && pago.length && pago[0] && pago[0].obs_aco && pago[0].obs_aco ) ||
                      'Sin especificar'
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-3 mt-4 mb-3" v-if="pedido && pedido.length">
            <div class="row justify-content-between">
              <h4 class="col-12 col-sm mb-0">Detalles del pedido</h4>
              <div class="col-auto">
                <mdb-btn
                  flat
                  dark-waves
                  icon="ban"
                  type="button"
                  title="Anular pedido"
                  class="m-0 px-4"
                  v-if="pedido && pedido.length && pedido[0].est_ped === 'en cola'"
                  @click="modalConfirmacionEliminar= !modalConfirmacionEliminar; mensajeAnular = 'Se anulará el pedido'"
                >
                  Anular pedido
                </mdb-btn>
              </div>
            </div>
            <div class="row">
              <p class="col-12 col-sm-4">
                <span class="small-block font-titulo">
                  Estado del pedido
                </span>
                <span
                  :class="['font-weight-bold', asignarColorEstado(pedido[0].est_ped), 'text-capitalize']"
                >
                  {{ pedido && pedido[0].est_ped }}
                </span>
              </p>
              <p class="col-12 col-sm-4">
                <span class="small-block font-titulo">
                  Condición de pago
                </span>
                <span :class="{'text-muted font-italic user-select-none': !(pedido[0] && pedido[0].nom_cp)}">
                  {{ pedido[0] && pedido[0].nom_cp ? pedido[0].nom_cp : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-12 col-sm-4">
                <span class="small-block font-titulo">
                  Descuento del pedido
                </span>
                <span :class="{'text-muted font-italic':pedido[0] && !pedido[0].dsc_ped}">{{ pedido[0] && pedido[0].dsc_ped ? pedido[0].dsc_ped  : 'Sin descuento' }}</span>
              </p>
              <p class="col-12 col-sm-6">
                <span class="small-block font-titulo">
                  Dirección, Estado
                </span>
                <span v-if="actividad && actividad.length">{{ actividad[0].dir_suc + ', '}}{{ actividad[0].est_zon  }}.</span>
              </p>
              <p class="col-12 col-sm-6">
                <span class="small-block font-titulo">
                  Observación del Pedido
                </span>
                <span :class="{'text-muted font-italic': pedido[0] && !pedido[0].obs_ped}">
                  {{
                    pedido[0] && pedido[0].obs_ped
                      ? pedido[0].obs_ped
                      : 'Sin observaciones'
                  }}
                </span>
              </p>
            </div>
          </div>
          <!-- Detalles de la devolución (en caso exista) -->
          <div class="px-3 mt-4 mb-3" v-if="devolucionDetalles && devolucionDetalles.length">
            <h4 class="mb-3">Detalles del la devolución</h4>
            <div class="row">
              <p class="col-12 col-sm-4">
                <span class="small-block font-titulo">
                  Estado de la devolución
                </span>
                <span
                :class="!(devolucionDetalles && devolucionDetalles[0].est_dev)
                    ?'text-muted font-italic user-select-none'
                    : asignarColorEstado(devolucionDetalles[0].est_dev) + ' font-weight-bold'"
                >
                  {{
                    devolucionDetalles && devolucionDetalles[0].est_dev
                      ? devolucionDetalles[0].est_dev
                      : 'Sin especificar'
                  }}
                </span>
              </p>
              <p class="col-12 col-sm-4">
                <span class="small-block font-titulo">
                  Número de Devolución
                </span>
                <span :class="{'text-muted font-italic user-select-none': !(devolucionDetalles[0] && devolucionDetalles[0].id_dev)}">
                  {{
                    devolucionDetalles && devolucionDetalles[0].id_dev
                      ? devolucionDetalles[0].id_dev
                      : 'Sin especificar'
                  }}
                </span>
              </p>
              <p class="col-12 col-sm-4">
                <span class="small-block font-titulo">
                  Fecha
                </span>
                <span :class="{'text-muted font-italic user-select-none': !(devolucionDetalles[0] && devolucionDetalles[0].fec_dev)}">
                  {{
                    devolucionDetalles && devolucionDetalles[0].fec_dev
                      ? devolucionDetalles[0].fec_dev
                      : 'Sin especificar'
                  }}
                </span>
              </p>
              <p class="col-12 col-sm-4">
                <span class="small-block font-titulo">
                  Observación
                </span>
                <span :class="{'text-muted font-italic user-select-none': !(devolucionDetalles[0] && devolucionDetalles[0].obs_dev)}">
                  {{
                    devolucionDetalles && devolucionDetalles[0].obs_dev
                      ? devolucionDetalles[0].obs_dev
                      : 'Sin especificar'
                  }}
                </span>
              </p>
            </div>
          </div>
          <div>
            <!-- Productos del pedido de la actividad -->
            <ul class="table p-productos mt-3" v-if="pedidoActividad && pedidoActividad.length">
              <li class="encabezado sticky">
                <header class="h4-responsive">Productos del pedido</header>
                <p class="descripcion">
                  Productos listados: {{ pedidoActividad && pedidoActividad.length }}
                </p>
                <div class="thead claro">
                  <div class="col-12 col-sm">
                    Nombre
                    <span class="small-block">Categoría</span>
                  </div>
                  <div class="col-12 col-sm">
                    Descripción - Código
                    <span class="small-block d-xl-none">Unidades / Empaque</span>
                  </div>
                  <div class="col-12 col-sm d-none d-xl-block">
                    Unidades - Empaque
                  </div>
                  <div class="col-12 col-sm-2 text-right">
                    Cantidad
                  </div>
                </div>
              </li>
              <li
                class="contenido"
                v-for="(producto, i) in pedidoActividad"
                :key="producto.id_pre+'-'+i"
              >
                <div
                  class="col-12 col-sm"
                  data-columna="Nombre / Categoría"
                >
                  <p>
                    {{ producto.nom_pro }}
                    <span class="small-block">
                      {{ producto.nom_cat }}
                    </span>
                  </p>
                </div>
                <div class="col-12 col-sm" data-columna="Descripción - Código / Unidades / Empaque">
                  <p>
                    {{ producto.des_pre }} - {{producto.cod_pre}}
                    <span class="small-block d-xl-none">
                      {{ producto.uni_paq_pre }} / {{producto.paq_pre}}
                    </span>
                  </p>
                </div>
                <div class="col-12 col-sm d-none d-xl-block" data-columna="Unidades - Empaque">
                  <p>
                    {{ producto.uni_paq_pre }} - {{producto.paq_pre}}
                  </p>
                </div>
                <div class="cl-12 col-sm-2" data-columna="Cantidad">
                  <p class="text-right">
                    {{ producto.can_ped_np}}
                  </p>
                </div>
              </li>
            </ul>
            <!-- Productos del inventario de la actividad -->
            <ul class="table p-productos mt-3" v-if="inventarioActividad && inventarioActividad.length">
              <li class="encabezado sticky">
                <header class="h4-responsive">Productos del inventario</header>
                <p class="descripcion">
                  Productos listados: {{ inventarioActividad && inventarioActividad.length }}
                </p>
                <div class="thead claro">
                  <div class="col-12 col-sm">
                    Nombre
                    <span class="small-block">Categoría</span>
                  </div>
                  <div class="col-12 col-sm">
                    Descripción - Código
                    <span class="small-block d-xl-none">Unidades / Empaque</span>
                  </div>
                  <div class="col-12 col-sm d-none d-xl-block">
                    Unidades - Empaque
                  </div>
                  <div class="col-12 col-sm-2 text-right">
                    Cantidad
                  </div>
                </div>
              </li>
              <li
                class="contenido"
                v-for="(producto, i) in inventarioActividad"
                :key="producto.id_pre+'-'+i">
                <div
                  class="col-12 col-sm"
                  data-columna="Nombre / Categoría"
                >
                  <p>
                    {{ producto.nom_pro }}
                    <span class="small-block">
                      {{ producto.nom_cat }}
                    </span>
                  </p>
                </div>
                <div class="col-12 col-sm" data-columna="Descripción - Código / Unidades / Empaque">
                  <p>
                    {{ producto.des_pre }} - {{producto.cod_pre}}
                    <span class="small-block d-xl-none">
                      {{ producto.uni_paq_pre }} / {{producto.paq_pre}}
                    </span>
                  </p>
                </div>
                <div class="col-12 col-sm d-none d-xl-block" data-columna="Unidades - Empaque">
                  <p>
                    {{ producto.uni_paq_pre }} - {{producto.paq_pre}}
                  </p>
                </div>
                <div class="cl-12 col-sm-2" data-columna="Cantidad">
                  <p class="text-right">
                    {{ producto.can_ni}}
                  </p>
                </div>
              </li>
            </ul>
            <!-- Productos en devolucion de la actividad -->
            <ul class="table p-productos mt-3" v-if="devolucionActividad && devolucionActividad.length">
              <li class="encabezado sticky">
                <header class="h4-responsive">Productos en devolución</header>
                <p class="descripcion">
                  Productos listados: {{ devolucionActividad && devolucionActividad.length }}
                </p>
                <div class="thead claro">
                  <div class="col-12 col-sm">
                    Nombre
                    <span class="small-block">Categoría</span>
                  </div>
                  <div class="col-12 col-sm">
                    Descripción - Código
                    <span class="small-block d-xl-none">Unidades / Empaque</span>
                  </div>
                  <div class="col-12 col-sm d-none d-xl-block">
                    Unidades - Empaque
                  </div>
                  <div class="col-12 col-sm-2 text-right">
                    Cantidad
                  </div>
                </div>
              </li>
              <li
                class="contenido"
                v-for="(producto, i) in devolucionActividad"
                :key="producto.id_pre+'-'+i"
              >
                <div
                  class="col-12 col-sm"
                  data-columna="Nombre / Categoría"
                >
                  <p>
                    {{ producto.nom_pro }}
                    <span class="small-block">
                      {{ producto.nom_cat && producto.nom_cat }}
                    </span>
                  </p>
                </div>
                <div class="col-12 col-sm" data-columna="Descripción - Código / Unidades / Empaque">
                  <p>
                    {{ producto.des_pre }} - {{producto.cod_pre}}
                    <span class="small-block d-xl-none">
                      {{ producto.uni_paq_pre }} / {{producto.paq_pre}}
                    </span>
                  </p>
                </div>
                <div class="col-12 col-sm d-none d-xl-block" data-columna="Unidades - Empaque">
                  <p>
                    {{ producto.uni_paq_pre }} - {{producto.paq_pre}}
                  </p>
                </div>
                <div class="cl-12 col-sm-2" data-columna="Cantidad">
                  <p class="text-right">
                  {{ producto.can_nd }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="mensaje-no-items py-3" v-if="productosActividadFaltantes">
            <font-awesome-icon icon="clipboard" size="5x" class="icono" />
            <p class="texto text-center">{{productosActividadFaltantes}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <EditarActividad
      :esEditar="true"
      :id_ped="parseInt(pedidoId)"
      :mostrarModal="mostrarModalTomarCantidad"
      :notasDeEntrega="pedidoActividad"
      :inventarioAEditar="inventarioActividad"
      :devolucionAEditar="devolucionActividad"
      :adicionalesDelPedido="{
        pag: pedido[0] && pedido[0].nom_cp,
        doc: pedido[0] && pedido[0].nom_tdoc,
        tpre: pedido[0] && pedido[0].nom_tpre,
        dsc: pedido[0] && pedido[0].dsc_ped,
        obs: pedido[0] && pedido[0].obs_ped,
        estado: pedido[0] && pedido[0].est_ped,
      }"
      :id_isu="inventarioActividad && inventarioActividad.length && inventarioActividad[0].id_isu && parseInt(inventarioActividad[0].id_isu)"
      :id_dev="devolucionDetalles && devolucionDetalles.length && devolucionDetalles[0].id_dev && parseInt(devolucionDetalles[0].id_dev)"
      @alertaMensaje="alertaMensaje = $event"
      @reiniciarDatos="obtenerDetallesActividad()"
      @cerrar="mostrarModalTomarCantidad=false;"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      titulo="¡Esta acción es irreversible!"
      :mensaje= mensajeAnular
      textoBotonConfirmar="Anular"
      @cerrar="modalConfirmacionEliminar = false"
      @confirmar="confirmarModalEliminar"
    />
  </section>
</template>

<script>
import EditarActividad from '@/components/EditarActividad.vue';
import {apiPost} from '@/funciones/api.js';
import {asignarColorEstado, autoComaYAutoY, convertirNumeroAFixed} from '@/funciones/funciones.js';
import {mdbBtn} from 'mdbvue';
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import LoaderKel from '@/components/LoaderKel.vue';
import {iconosActividades} from '@/constantes/actividades';
import {EstatusPagoIconos} from '@/constantes/pagos';
export default {
  name: 'Actividad',
  components: {
    mdbBtn,
    ConfirmacionEliminar,
    AlertaMensaje,
    EditarActividad,
    LoaderKel,
  },
  props: {
    fecha: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      convertirNumeroAFixed,
      asignarColorEstado,
      EstatusPagoIconos,
      autoComaYAutoY,
      // Editar actividad
      mostrarModalTomarCantidad: false,
      // Detalles de la devolucion
      devolucionDetalles: [],
      // Otros
      actividad: [],
      inventarioActividad: [],
      pedidoActividad: [],
      devolucionActividad: [],
      pedido: [],
      pago: [],
      acciones: [],
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      pedidoId: '',
      cargando: false,
      alertaMensaje: {
        contenido: '',
      },
      mensajeAnular: '',
      iconosActividades,
    };
  },
  mounted() {
    this.obtenerDetallesActividad();
    const contenedorHijoSection = document.getElementById('contenedorHijoSection');
    const contenedorHijoDiv = document.getElementById('contenedorHijoDiv');
    contenedorHijoSection.classList.add('contenedor-hijo');
    contenedorHijoDiv.classList.remove('contenedor-hijo');

    setTimeout(() => {
      contenedorHijoSection.classList.remove('contenedor-hijo');
      contenedorHijoDiv.classList.add('contenedor-hijo');
    }, 1100);
  },
  beforeDestroy() {
    const contenedorHijoSection = document.getElementById('contenedorHijoSection');
    if (contenedorHijoSection) {
      contenedorHijoSection.classList.add('contenedor-hijo');
    }
  },
  methods: {
    asignarIcono(accion) {
      let clase = '';
      this.iconosActividades.forEach((icono) => {
        if (accion === icono.nombre) {
          clase = icono.clase;
        }
      });
      return clase;
    },
    calculaTotal(cantidad, precio) {
      return cantidad * precio;
    },
    calcularIva(cantidad, precio=0, iva=0) {
      return (parseFloat(precio || 0) *
          parseFloat(cantidad) *
          parseFloat(iva)) /
        100;
    },
    async obtenerDetallesActividad() {
      this.cargando = true;
      if (!this.paramsId) {
        this.$router.push('/actividades');
        return;
      }
      try {
        const {data} = await apiPost(
            {s: 'desActCliM'},
            'int=' + this.paramsId,
        );
        if (data) {
          this.acciones = data.acc;
          this.actividad = data.dts;
          this.pago = data.pago;
          this.inventarioActividad = data.inv;
          this.devolucionActividad = data.notdev;
          this.devolucionDetalles = data.dev;
          if (data.ped && data.ped[0] && data.ped[0].id_ped) {
            // Se obtiene el id del pedido del primer index
            this.pedidoId = data.ped[0].id_ped;
            this.pedido = data.ped;
            this.pedidoActividad = data.notped;
          }
        }
        this.cargando = false;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'No se pudo obtener la actividad',
          tipo: 'error',
        };
        this.cargando = false;
        this.$router.push('/actividades');
      }
    },
    confirmarModalEliminar() {
      this.cancelarPedido();
    },
    cancelarPedido() {
      this.botonDeshabilitado = true;
      apiPost({s: 'cancP'}, 'ped=' + this.pedidoId)
          .then((response) => {
            if (response.data.r) {
              this.alertaMensaje = {
                contenido: 'Pedido anulado correctamente',
                tipo: 'correcto',
              };
              this.modalConfirmacionEliminar = false;
              if (this.pedido[0].est_ped === 'en cola') {
                this.pedido[0].est_ped = 'Cancelado';
              }
            } else {
              this.alertaMensaje = {
                contenido: 'No se pudo anular el pedido',
                tipo: 'advertencia',
              };
            }
            this.botonDeshabilitado = false;
          }).catch(()=>{
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: `Ocurrió un error anulando el pedido.`,
              tipo: 'error',
            };
          });
    },
    formatearFechaYHora(fecha) {
      const mesSinFormato = new Date(fecha.substring(5, 7));
      const opt = {month: 'short'};
      const mesLetras = mesSinFormato.toLocaleDateString('es-VE', opt);
      const dia = fecha.substring(8, 10);
      const hora = new Date(fecha).toLocaleString().substr(-14);
      return dia + ' ' + mesLetras + ' ' + hora;
    },
  },
  computed: {
    paramsId() {
      return this.$route.fullPath.includes('clientes') ? this.$route.params.idActividad : this.$route.params.id;
    },
    productosActividadFaltantes() {
      let mensaje = '';

      const productos = [];
      this.pedidoActividad && this.pedidoActividad.length ? '' : productos.push('el pedido');
      this.inventarioActividad && this.inventarioActividad.length ? '' : productos.push('el inventario');
      this.devolucionActividad && this.devolucionActividad.length ? '' : productos.push('la devolución');

      if (productos.length === 0 && !(this.pago && this.pago.length)) {
        mensaje = 'No hay información disponible sobre el pago';
      } else {
        this.pago && this.pago.length ? '' : productos.push('tampoco hay información disponible sobre el pago.');
        mensaje = productos.length ? `No existen productos en ${autoComaYAutoY(productos)}` : '';
      }

      return mensaje;
    },
    calcularSubTotal() {
      let subtotal = 0;
      this.pedidoActividad.forEach((producto) => {
        subtotal += producto.pvmp_pre * producto.can_ped_np;
      });
      return subtotal;
    },
    calcularDescuento() {
      if (!this.pedido.length) return 0;
      return ((parseFloat(this.pedido[0].dsc_ped) / 100) * this.calcularSubTotal).toFixed(2);
    },
    calcularTotalIva() {
      let iva = 0;
      this.pedidoActividad.forEach((producto) => {
        iva += (parseFloat(producto.pvmp_pre || 0) *
          parseFloat(producto.can_ped_np) *
          parseFloat(producto.iva_pre)) /
        100;
      });
      return iva;
    },
    calcularMontoTotal() {
      return (parseFloat(this.calcularSubTotal) - parseFloat(this.calcularDescuento) + parseFloat(this.calcularTotalIva)).toFixed(2) || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.table.p-productos {
  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
</style>
