<template>
  <form
    :class="['row filtros mx-0', {'justify-content-sm-center justify-content-lg-start': estadoSeleccionado && !municipioSeleccionado.length}]"
    @submit.prevent
  >
    <!-- Estados -->
    <div class="col-12 col-sm-6 col-lg-4" v-if="Object.keys(estados).length">
      <v-select
        v-model="estadosAFiltrar"
        :searchable="false"
        :options="Object.values(estados)"
        :getOptionLabel="estados => estados.est_zon"
        multiple
        class="outline-datalist my-2"
        small-text="Haz clic en el botón > del estado para ver sus municipios"
        @input="municipios={};municipioSeleccionado = [];estadoSeleccionado = [];municipiosAFiltrar=[];parroquiasAFiltrar=[];$emit('zonasAFiltrar', {estados: estadosAFiltrar, municipios: [], parroquias: []});"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': estadosAFiltrar && Object.keys(estadosAFiltrar).length}]">
            Estado(s)
          </label>
        </template>
        <template #option="estados">
          <div class="row-flex">
            <p>{{ estados.est_zon }}</p>
            <mdb-btn
              flat
              dark-waves
              icon="angle-right"
              type="button"
              class="m-0 py-1 px-3"
              :title="`Seleccionar municipio/s del estado ${estados.est_zon}`"
              @click="seleccionarMunicipios(estados)"
            />
          </div>
        </template>
      </v-select>
    </div>
    <!-- Municipios -->
    <div
      v-if="estadoSeleccionado.length"
      class="col-12 col-sm-6 col-lg-4"
    >
      <v-select
        v-model="municipiosAFiltrar"
        :searchable="false"
        :options="Object.values(municipios)"
        :getOptionLabel="municipios => municipios.ciu_zon"
        multiple
        class="outline-datalist my-2"
        small-text="Haz clic en el botón > del municipio para ver sus parroquias"
        @input="$emit('zonasAFiltrar', {estados: estadoSeleccionado, municipios: municipiosAFiltrar, parroquias: []}); municipioSeleccionado = []"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': municipiosAFiltrar && Object.values(municipiosAFiltrar).length}]">
            Municipio(s)
          </label>
        </template>
        <template #option="municipios">
          <div class="row-flex">
            <p>{{ municipios.ciu_zon }}</p>
            <mdb-btn
              flat
              dark-waves
              icon="angle-right"
              type="button"
              class="m-0 py-1 px-3"
              :title="`Seleccionar parroquia/s del municipio ${municipios.ciu_zon}`"
              @click="seleccionarParroquias(municipios)"
            />
          </div>
        </template>
      </v-select>
    </div>
    <!-- Parroquias -->
    <div
      v-if="municipioSeleccionado.length"
      class="col-12 col-sm-6 col-lg-4"
    >
      <v-select
        v-model="parroquiasAFiltrar"
        :searchable="false"
        :options="Object.values(parroquias)"
        :getOptionLabel="parroquias => parroquias.sec_zon"
        multiple
        class="outline-datalist my-2"
        @input="$emit('zonasAFiltrar', {estados: estadoSeleccionado, municipios: municipioSeleccionado, parroquias: parroquiasAFiltrar});"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': parroquiasAFiltrar && Object.values(parroquiasAFiltrar).length}]">
            Parroquia(s)
          </label>
        </template>
        <template #option="parroquias">
          {{ parroquias.sec_zon }}
        </template>
      </v-select>
    </div>
    <!-- Tipos de clientes -->
    <div class="col-12 col-sm-6 col-lg-4" v-if="Object.keys(tipos).length">
      <v-select
        v-model="tiposCliente"
        :searchable="false"
        :options="Object.values(tipos)"
        :getOptionLabel="tipos => tipos.nom_tcl"
        multiple
        class="outline-datalist my-2"
        @input="tiposCliEnvio"
      >
        <template #header>
          <label :class="['datalist-label', {'activo':tiposCliente.includes(tipos.id_tcl)}]">
            Tipos de cliente
          </label>
        </template>
        <template #option="tipos">
          {{tipos.nom_tcl}}
        </template>
      </v-select>
    </div>
    <!-- Morosidad -->
    <div class="col-12 col-sm-6 col-lg-4" v-if="EstatusFactura.length">
      <v-select
        v-model="factura"
        :searchable="false"
        :options="EstatusFactura"
        :getOptionLabel="EstatusFactura => EstatusFactura.nombre"
        multiple
        class="outline-datalist my-2"
        @input="facturaEnvio"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': factura.includes(EstatusFactura.nombre)}]">
            Estatus de factura
          </label>
        </template>
        <template #option="EstatusFactura">
          {{EstatusFactura.nombre}}
        </template>
      </v-select>
    </div>
    <div
      v-if="nombre"
      class="col-12 col-sm-6 col-lg-4"
    >
      <mdb-input
        v-model="nombreAFiltrar"
        label="Nombre del cliente"
        class="my-2"
        outline
        @input="$emit('nombreAFiltrar', nombreAFiltrar);"
      />
    </div>
    <div
      v-if="fecha"
      class="col-12 col-sm-6 col-lg-4"
    >
      <mdb-input
        v-model="fechaAFiltrar"
        type="date"
        label="Fecha"
        class="my-2"
        outline
        @input="$emit('fechaAFiltrar', fechaAFiltrar);"
      />
    </div>
    <div
      v-if="cliPotenciales"
      class="col-6 col-lg-auto px-0 text-center align-self-center"
    >
      <div class="btn-checkgroup-contenedor mt-2">
        <input
          v-model="clientesPotenciales"
          id="clientesPo"
          type="checkbox"
          name="checkbox-categoria"
          @click="clientesPotenciales = !clientesPotenciales; clientesPotencialesEnvio()"
          >
        <label for="clientesPo" class="btn-checkgroup my-1 icon-target-account">
          Potenciales
        </label>
      </div>
    </div>
    <div
      v-if="cliPlanificaciones"
      class="col px-0 text-center align-self-center"
    >
      <div class="btn-checkgroup-contenedor mt-2">
        <input
          v-model="clientesPlanificaciones"
          id="clientesPla"
          type="checkbox"
          name="checkbox-categoria"
          @click="clientesPlanificaciones = !clientesPlanificaciones; clientesPlanificacionesEnvio()"
          >
        <label for="clientesPla" class="btn-checkgroup my-1 py-2 icon-calendar-xmark">
          Sin planificación
        </label>
      </div>
    </div>
    <div class="col-12 col-sm text-center">
      <mdb-btn
        flat
        dark-waves
        icon="broom"
        class="my-1 px-3"
        @click="$emit('eliminarFiltros'); eliminarFiltrosSeleccionados()"
      >
        Restablecer filtros
      </mdb-btn>
    </div>
  </form>
</template>

<script>
import {mdbBtn, mdbInput} from 'mdbvue';
import {EstatusFactura} from '@/constantes/facturas.js';
export default {
  name: 'FiltrosTipo1',
  components: {
    mdbBtn,
    mdbInput,
  },
  props: {
    zonas: {
      type: Array,
      default: () => [],
    },
    tiposCli: {
      type: Array,
      default: () => [],
    },
    nombre: {
      type: Boolean,
      default: true,
    },
    fecha: {
      type: Boolean,
      default: true,
    },
    cliPotenciales: {
      type: Boolean,
      default: false,
    },
    cliPlanificaciones: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tiposAFiltrar: [],
      nombreAFiltrar: '',
      fechaAFiltrar: '',
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: [],
      municipioSeleccionado: [],
      municipios: {},
      parroquias: {},
      tiposCliente: [],
      EstatusFactura,
      factura: [],
      facturaFiltrar: [],
      clientesPotenciales: false,
      clientesPlanificaciones: false,
    };
  },
  methods: {
    seleccionarMunicipios(estado) {
      this.estadosAFiltrar = [];
      this.estadosAFiltrar.push(estado);
      this.municipioSeleccionado = [];
      this.estadoSeleccionado.push(estado);
      this.municipios = estado.municipios;
      this.municipiosAFiltrar = [];
      this.parroquiasAFiltrar = [];
      this.$emit('zonasAFiltrar', {estados: this.estadosAFiltrar, municipios: [], parroquias: []});
    },
    seleccionarParroquias(municipio) {
      this.municipiosAFiltrar = [];
      this.municipiosAFiltrar.push(municipio);
      this.municipioSeleccionado.push(municipio);
      this.parroquias = municipio.parroquias;
      this.parroquiasAFiltrar = [];
      this.$emit('zonasAFiltrar', {estados: this.estadosAFiltrar, municipios: this.municipiosAFiltrar, parroquias: []});
    },
    tiposCliEnvio() {
      this.tiposAFiltrar = [];
      this.tiposCliente.forEach((t) => {
        this.tiposAFiltrar.push(t.id_tcl);
      });
      this.$emit('tiposAFiltrar', this.tiposAFiltrar);
    },
    facturaEnvio() {
      this.facturaFiltrar = [];
      this.factura.forEach((factura) => {
        this.facturaFiltrar.push(factura.nombre);
      });
      this.$emit('estatusFacturaAFiltrar', this.facturaFiltrar);
    },
    clientesPotencialesEnvio() {
      this.$emit('clientesPo', this.clientesPotenciales);
    },
    clientesPlanificacionesEnvio() {
      this.$emit('clientesPla', this.clientesPlanificaciones);
    },
    eliminarFiltrosSeleccionados() {
      this.tiposAFiltrar = [];
      this.nombreAFiltrar = '';
      this.fechaAFiltrar = '';
      this.estadosAFiltrar = [];
      this.municipiosAFiltrar = [];
      this.parroquiasAFiltrar = [];
      this.estadoSeleccionado = [];
      this.municipioSeleccionado = [];
      this.municipios = {};
      this.parroquias = {};
      this.tiposCliente = [];
    },
  },
  computed: {
    estados() {
      const estados = {};
      this.zonas.forEach((e) => {
        if (!estados[e.est_zon]) {
          estados[e.est_zon] = {
            est_zon: e.est_zon,
            municipios: [],
          };
        }
        if (!estados[e.est_zon].municipios[e.ciu_zon]) {
          estados[e.est_zon].municipios[e.ciu_zon] = {
            ciu_zon: e.ciu_zon,
            parroquias: [],
          };
        }
        if (!estados[e.est_zon].municipios[e.ciu_zon].parroquias[e.sec_zon]) {
          estados[e.est_zon].municipios[e.ciu_zon].parroquias[e.sec_zon] = {
            sec_zon: e.sec_zon,
          };
        }
      });
      return estados;
    },
    tipos() {
      const tipos = {};
      this.tiposCli.forEach((e) => {
        if (!tipos[e.id_tcl]) {
          tipos[e.id_tcl] = {
            id_tcl: e.id_tcl,
            nom_tcl: e.nom_tcl,
          };
        }
      });
      return tipos;
    },
    nombresDeTiposAFiltrar() {
      const nombres = [];
      this.tiposAFiltrar.forEach((tipo) => {
        nombres.push(this.tipos[tipo].nom_tcl);
      });
      return nombres;
    },
  },
};
</script>

<style lang="scss" scoped>
.row-flex {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;

  p {
    flex-grow: 2;
    margin-bottom: 0;
  }
}
</style>
