<template>
  <!-- Modal para agregar empresa | sucursal -->
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false)"
  >
    <mdb-modal-header class="pb-0">
      <mdb-modal-title>
        Datos de la {{esSucursal ? 'sucursal' : 'empresa'}}
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <form id="form-cliente" @submit.prevent>
        <header class="font-weight-bold">
          Información de la {{ esSucursal ? 'sucursal' : 'empresa' }}
        </header>
        <div class="row">
          <!-- Seleccionar empresa -->
          <div class="col-12 col-lg-6">
            <mdb-input
              v-model.trim="cliente.nom"
              :label="`Nombre de la ${esSucursal ? 'sucursal' : 'empresa'}`"
              :class="['my-2', validarValores.nombre]"
              :validation="validarValores.nombre === 'invalido'"
              :invalidFeedback="`Escribe el nombre de la ${esSucursal ? 'sucursal' : 'empresa'}`"
              outline
            />
          </div>
          <template v-if="esSucursal">
            <div class="col-12 col-sm">
              <mdb-input
                v-model.number="cliente.sad"
                type="number"
                label="SADA"
                :class="['my-2 ', validarValores.sad]"
                outline
                :validation="validarValores.sad === 'invalido'"
                invalidFeedback="El SADA es requerido"
              />
            </div>
            <div class="col-12 col-sm">
              <mdb-input
                v-model.trim="cliente.cod"
                label="Código"
                class="my-2"
                outline
              />
            </div>
          </template>
          <template v-else>
            <!-- RIF de la empresa -->
            <div class="col-12 col-sm">
              <mdb-input
                v-model="cliente.rif"
                label="RIF de la empresa"
                :class="['my-2 ', validarValores.rif]"
                :validation="validarValores.rif === 'invalido'"
                invalidFeedback="Ej: J-15678910-1"
                outline
                @click="!cliente.rif ? cliente.rif = 'J-' : ''"
              />
            </div>
            <!-- Tipo de relación -->
            <!-- <div class="col-12 col-sm">
              <div class="my-2 p-0 md-form md-outline outline-select">
                <select
                  v-model="cliente.rel"
                  id="rel-select"
                  class="custom-select"
                >
                <option :value="1">Cliente</option>
                </select>
                <label
                  for="rel-select"
                  :class="cliente.rel ? 'label-active' : 'label-inactive'"
                >
                  Tipo de relación
                </label>
              </div>
            </div> -->
          </template>
          <div class="col-12">
            <mdb-input
              v-model.trim="cliente.dir"
              type="textarea"
              label="Dirección"
              :class="['my-2', validarValores.dir]"
              :validation="validarValores.dir === 'invalido'"
              :invalidFeedback="`Escribe la dirección de la ${esSucursal ? 'sucursal' : 'empresa'}`"
              small="Ej: Avenida principal, detrás del edificio Paredes"
              outline
            />
          </div>
          <!-- Zonas -->
          <div class="col-12 col-lg-4">
            <div
              v-if="esSucursal"
              :class="['my-2 p-0 md-form md-outline outline-select', validarValores.zon]"
            >
              <select
                v-model="cliente.zon"
                id="zon-select"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(value, key) in zonas"
                  :key="key"
                  :value="value.id_zon"
                >
                {{ value.est_zon }} - {{value.ciu_zon}} - {{value.sec_zon}}
                </option>
              </select>
              <label
                for="zon-select"
                :class="cliente.zon ? 'label-active' : 'label-inactive'"
              >
                Zonas
              </label>
            </div>
          </div>
          <!-- Tipo de Sucursal -->
          <div class="col-12 col-sm col-lg-4">
            <div
              v-if="esSucursal"
              :class="['my-2 p-0 md-form md-outline outline-select', validarValores.tcli]"
            >
              <select
                v-model="cliente.tcli"
                id="tcli-select"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(value, key) in tipos"
                  :key="key"
                  :value="value.id_tcl"
                >
                  {{ value.nom_tcl }}
                </option>
              </select>
              <label
                for="tcli-select"
                :class="cliente.tcli ? 'label-active' : 'label-inactive'"
              >
                Tipo de sucursal
              </label>
            </div>
          </div>
          <!-- Retenciones -->
          <div class="col-12 col-sm-6 col-lg-4">
            <div
              v-if="esSucursal"
              :class="['my-2 p-0 md-form md-outline outline-select', validarValores.ret]"
            >
              <select
                v-model="cliente.ret"
                id="ret-select"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(value, key) in retenciones"
                  :key="key"
                  :value="value.id_res"
                >
                  {{ value.nom_res }}
                </option>
              </select>
              <label
                for="ret-select"
                :class="cliente.ret ? 'label-active' : 'label-inactive'"
              >
                Tipo de retención
              </label>
            </div>
          </div>
          <!-- Obtener coordenada actual -->
          <div
            v-if="cliente.lat && cliente.lon"
            class="col-12 col-lg-6"
          >
            <div class="row">
              <header class="col-12 h6-responsive font-weight-bold my-1 pr-lg-0">Coordenadas de la sucursal</header>
              <p class="col-12 col-sm mb-1">Latitud: {{cliente.lat}}</p>
              <p class="col-12 col-sm mb-1">Longitud: {{cliente.lon}}</p>
            </div>
          </div>
          <div :class="['col-12', {'col-sm': cliente.lat && cliente.lon }, 'my-2 m-sm-0 d-flex justify-content-center']">
            <mdb-btn
              v-if="esSucursal"
              color="secundario"
              icon="map-pin"
              class="px-3"
              @click="obtenerCoordenadas()"
            >
              {{ cliente.lat && cliente.lon ? 'Actualizar' : 'Obtener'}} coordenadas
            </mdb-btn>
          </div>
        </div>
        <header class="font-weight-bold">
          Números de contacto
        </header>
        <div class="row">
          <!-- Telefono de la empresa -->
          <div
            v-if="!esSucursal"
            class="col-12 col-sm-6 "
          >
            <mdb-input
              v-model.number="cliente.tele"
              type="tel"
              label="Teléfono"
              placeholder="Ej: 426573828"
              minlength="9"
              maxlength="10"
              :class="['my-2 plus-58', validarValores.tele]"
              :validation="validarValores.tele === 'invalido'"
              invalidFeedback="Indica el teléfono de la empresa"
              outline
            >
              <span class="input-group-text md-addon" slot="prepend">+58</span>
            </mdb-input>
          </div>
          <!-- Telefono de la sucursal -->
          <div
            v-else
            class="col-12 col-sm-6 "
          >
            <mdb-input
              v-model.number="cliente.tels"
              type="tel"
              label="Teléfono"
              placeholder="Ej: 426573828"
              minlength="9"
              maxlength="10"
              :class="['my-2 plus-58', validarValores.tels]"
              :validation="validarValores.tels === 'invalido'"
              invalidFeedback="Indica el teléfono de la sucursal"
              outline
            >
              <span class="input-group-text md-addon" slot="prepend">+58</span>
            </mdb-input>
          </div>
          <!-- Email de la empresa -->
          <div
            v-if="!esSucursal"
            class="col-12 col-sm"
          >
            <mdb-input
              v-model.trim="cliente.ema_emp"
              id="input-email"
              type="email"
              label="Email"
              placeholder="Ej: empresa@mail.com"
              :class="['my-2', validarValores.ema_emp]"
              :validation="validarValores.ema_emp === 'invalido'"
              invalidFeedback="Indica el email de la empresa"
              outline
            />
          </div>
          <!-- Email de la sucursal -->
          <div
            v-else
            class="col-12 col-sm"
          >
            <mdb-input
              v-model.trim="cliente.ema_suc"
              id="input-email"
              type="email"
              label="Email"
              placeholder="Ej: sucursal@mail.com"
              :class="['my-2', validarValores.ema_suc]"
              :validation="validarValores.ema_suc === 'invalido'"
              invalidFeedback="Indica el email de la sucursal"
              outline
            />
          </div>
        </div>
        <!-- Datos de persona (solo cuando es sucursal) -->
        <div v-if="esSucursal">
          <header class="font-weight-bold">
            Persona de contacto
          </header>
          <div class="row">
            <!-- Nombres de la persona -->
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model.trim="cliente.fname"
                    label="Nombre de la persona"
                    :class="['my-2 ', validarValores.fname]"
                    :validation="validarValores.fname === 'invalido'"
                    invalidFeedback="Escribe el nombre de la persona"
                    outline
                  />
                </div>
                <div class="col-12 col-sm">
                  <mdb-input
                    v-model.trim="cliente.lname"
                    label="Apellido de la persona"
                    :class="['my-2', validarValores.lname]"
                    :validation="validarValores.lname === 'invalido'"
                    invalidFeedback="Escribe el apellido de la persona"
                    outline
                  />
                </div>
              </div>
            </div>
            <!-- Teléfono de la persona -->
            <div class="col-12 col-sm-6 col-lg-4 ">
              <mdb-input
                v-model.number="cliente.tel"
                type="tel"
                label="Teléfono"
                placeholder="Ej: 426573828"
                minlength="9"
                maxlength="10"
                :class="['my-2 plus-58', validarValores.tel]"
                :validation="validarValores.tel === 'invalido'"
                invalidFeedback="Indica el teléfono de la persona"
                outline
              >
                <span class="input-group-text md-addon" slot="prepend">+58</span>
              </mdb-input>
            </div>
            <!-- Cédula de la persona -->
            <div class="col-12 col-sm ">
              <mdb-input
                v-model.number="cliente.ced"
                type="number"
                label="Cédula"
                placeholder="Ej: 23022891"
                :class="['my-2', validarValores.ced]"
                invalidFeedback="Indica la cédula de la persona"
                minlength="5"
                maxlength="9"
                outline
              >
              </mdb-input>
            </div>
            <!-- Email de la persona -->
            <div class="col-12 col-lg ">
              <mdb-input
                v-model.trim="cliente.ema_per"
                id="input-email"
                type="email"
                label="Email"
                placeholder="Ej: persona@mail.com"
                :class="['my-2', validarValores.ema_per]"
                :validation="validarValores.ema_per === 'invalido'"
                invalidFeedback="Indica el email de la persona"
                outline
              />
            </div>
            <!-- Direccion de la persona -->
            <div class="col-12">
              <mdb-input
                v-model.trim="cliente.dirp"
                type="textarea"
                class="my-2"
                label="Dirección de la persona"
                outline
              />
            </div>
          </div>
        </div>
      </form>
      <div class="row justify-content-around">
        <mdb-btn flat dark-waves icon="times" @click="$emit('cerrar', false)">
          Cancelar
        </mdb-btn>
        <mdb-btn
          type="submit"
          form="form-cliente"
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : esEditar ? 'sync': 'check'"
          :icon-class="[{'fa-spin': botonDeshabilitado}]"
          :disabled="botonDeshabilitado"
          @click="validarDatos"
        >
          <span>{{ esEditar ? 'Actualizar' : 'Agregar' }}</span>
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import {apiPost} from '@/funciones/api.js';
import {
  capitalizar,
  eliminarVacios,
  validarTel,
  validarMail,
  validarTexto,
  validarTextoEspecial,
} from '@/funciones/funciones.js';
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbInput,
} from 'mdbvue';
export default {
  name: 'FormularioCliente',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbInput,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    esSucursal: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      },
    },
    esCliente: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      },
    },
    esEditar: {
      type: Boolean,
      required: false,
    },
    empresaId: {
      type: String,
      required: false,
    },
    empresa: {
      type: Object,
      required: false,
      default: function() {
        return {};
      },
    },
    sucursal: {
      type: Object,
      required: false,
      default: function() {
        return {};
      },
    },
    zonas: {
      type: Array,
      required: true,
      default: function() {
        return [];
      },
    },
    tipos: {
      type: Array,
      required: true,
      default: function() {
        return [];
      },
    },
    retenciones: {
      type: Array,
      required: false,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      validarValores: {},
      cliente: {
        nom: '',
        rif: 'J-',
        tele: null,
        dir: '',
        rel: 1,
        fname: '',
        ced: null,
        tcli: '',
        emp: '',
        ema_emp: '',
        ema_per: '',
        tel: '',
        zon: '',
        ret: '',
        ema_suc: '',
        sad: '',
        lat: '',
        lon: '',
        cod: '',
        lname: '',
        dirp: '',
      },
    };
  },
  methods: {
    mostrarAlerta(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    limpiarCampos() {
      this.botonDeshabilitado = false;
      this.validarValores = {};
      this.cliente = {
        nom: '',
        rif: 'J-',
        tele: null,
        dir: '',
        rel: 1,
        fname: '',
        ced: null,
        emp: '',
        ema_emp: '',
        ema_per: '',
        tel: '',
        zon: '',
        ret: '',
        tcli: '',
        ema_suc: '',
        sad: '',
        lat: '',
        lon: '',
        cod: '',
        lname: '',
        dirp: '',
      };
    },
    obtenerCoordenadas() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      const success = (pos) => {
        const crd = pos.coords;
        this.cliente.lat = crd.latitude;
        this.cliente.lon = crd.longitude;
      };

      const error = (err) => {
        this.mostrarAlerta(`Ha ocurrido un error obteniendo la ubicación actual: ${err.message}`, 'advertencia');
      };
      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    validarDatos() {
      this.cliente.dirs ? this.cliente.dir = this.cliente.dirs : '';
      this.botonDeshabilitado = true;
      let error = false;
      this.validarValores = {
        nombre: this.cliente.nom.length > 2 && validarTextoEspecial(this.cliente.nom) ? 'valido' : 'invalido',
        dir: this.cliente.dir.length > 5 ? 'valido' : 'invalido',
        ema_emp: this.cliente.ema_emp && this.cliente.ema_emp.length ? this.validarEmail(this.cliente.ema_emp) ? 'valido' : 'invalido' : '',
      };
      // Validar valores especificos de caso: sucursal
      if (this.esSucursal) {
        Object.assign(this.validarValores, {
          zon: this.cliente.zon ? 'valido-select' : 'invalido-select',
          ret: this.cliente.ret ? 'valido-select' : 'invalido-select',
          tcli: this.cliente.tcli ? 'valido-select' : 'invalido-select',
          tels: this.cliente.tels ? this.validarTelefono(this.cliente.tels) ? 'valido' : 'invalido': '',
          ema_suc: this.cliente.ema_suc && this.cliente.ema_suc.length ? this.validarEmail(this.cliente.ema_suc) ? 'valido' : 'invalido' : '',
        });
        Object.assign(this.validarValores, {
          fname: this.cliente.fname && this.cliente.fname.length ? validarTexto(this.cliente.fname) ? 'valido' : 'invalido': '',
          ced: this.cliente.ced ? this.validarIdentificacion(`V${this.cliente.ced}`, /^[V|E|J|P][0-9]{5,9}$/)? 'valido' : 'invalido': '',
          tel: this.cliente.tel ? this.validarTelefono(this.cliente.tel) ? 'valido' : 'invalido': '',
          ema_per: this.cliente.ema_per && this.cliente.ema_per.length ? this.validarEmail(this.cliente.ema_per) ? 'valido' : 'invalido' : '',
          lname: this.cliente.lname && this.cliente.lname.length ? validarTexto(this.cliente.lname) ? 'valido' : 'invalido' : '',
          sad: this.cliente.sad ? this.cliente.sad.toString().length >= 3 ? 'valido' : 'invalido': '',
        });
        if (Object.values(this.validarValores).includes('invalido')) {
          this.botonDeshabilitado = false;
          error = true;
          if (this.validarValores.nombre.includes('invalido') && this.cliente.nom.length) {
            return this.mostrarAlerta('Asegúrate que el nombre de la empresa no tenga caracteres especiales', 'advertencia');
          } else {
            return this.mostrarAlerta('Todos los campos son requeridos', 'advertencia');
          }
        }
        // Asignar id de la empresa
        if (!this.empresaId) {
          this.botonDeshabilitado = false;
          return this.mostrarAlerta('Ha ocurrido un error obteniendo información de la empresa. Por favor intenta de nuevo', 'advertencia');
        } else {
          this.cliente.emp = this.empresaId;
        }
      } else {
        // Validar valores especificos de caso: empresa
        Object.assign(this.validarValores, {
          rif: this.cliente.rif && this.validarIdentificacion(this.cliente.rif, '^[J|V|E|G]{1,1}-[0-9]{7,10}-[0-9]{1,1}$') ? 'valido' : 'invalido',
          tele: this.cliente.tele ? this.validarTelefono(this.cliente.tele) ? 'valido' : 'invalido': '',
        });
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.botonDeshabilitado = false;
        error = true;
        if (this.validarValores.nombre.includes('invalido') && this.cliente.nom.length) {
          return this.mostrarAlerta('Asegúrate que el nombre de la empresa no tenga caracteres especiales', 'advertencia');
        } else {
          return this.mostrarAlerta('Todos los campos son requeridos', 'advertencia');
        }
      }
      if (error) return this.botonDeshabilitado = false;
      // Aplica formato Capitalize al nombre de la empresa
      this.cliente.nom = capitalizar(this.cliente.nom);
      this.cliente.fname = capitalizar(this.cliente.fname);
      this.cliente.lname = capitalizar(this.cliente.lname);
      // Da formato a los teléfonos del cliente
      this.cliente.tel ? this.cliente.tel = '58' + this.cliente.tel : '';
      this.cliente.tele ? this.cliente.tele = '58' + this.cliente.tele : '';
      this.cliente.tels ? this.cliente.tels = '58' + this.cliente.tels : '';

      // Eliminar campos vacios
      const empresaSinCamposVacios = eliminarVacios(this.cliente);
      if (this.esEditar) {
        return this.editarCliente(empresaSinCamposVacios, this.esSucursal);
      }
      return this.crearCliente(empresaSinCamposVacios, this.esSucursal);
    },
    validarTelefono(telefono) {
      if (!telefono || !validarTel(telefono, '58')) {
        this.mostrarAlerta('Teléfono no válido',
            'advertencia');
        return false;
      }
      return true;
    },
    formatearTelefono(telefono) {
      return telefono.slice(2);
    },
    validarEmail(email) {
      if (!email || !validarMail(email)) {
        this.mostrarAlerta('Email no válido',
            'advertencia');
        return false;
      }
      return true;
    },
    validarIdentificacion(identificacion, reg) {
      const regExp = new RegExp(reg);
      return regExp.test(identificacion);
    },
    crearCliente(entidad, esSucursal) {
      const variablesPost = JSON.parse(JSON.stringify(entidad));
      if (esSucursal) {
        if (variablesPost.dir) {
          variablesPost.dirs = variablesPost.dir;
          delete variablesPost.dir;
        }
        // Validaciones adicionales y conversion de datos
        variablesPost.rif ? delete variablesPost.rif : '';
        variablesPost.rel ? delete variablesPost.rel : '';
        variablesPost.zon = Number(variablesPost.zon);
        variablesPost.emp = Number(variablesPost.emp);
        variablesPost.ret = Number(variablesPost.ret);
        variablesPost.tcli = Number(variablesPost.tcli);
      } else {
        // this.cliente.id_emp = this.empresaId;
        // this.cliente.tel = this.cliente.tele;
        // delete this.cliente.tele;
      };

      apiPost({s: esSucursal ? 'sucursalCrearMv' : 'empresaCrearMv'}, JSON.stringify(variablesPost))
          .then((response) => {
            this.desFormatearTelefonos();
            if (response.data.e.startsWith('Hubo un error guardando al encargado: Duplicate entry')) {
              this.mostrarAlerta('La cédula de la persona de contacto ya se encuentra registrada', 'advertencia');
              this.$emit('clienteAgregado');
              this.limpiarCampos();
              this.$emit('cerrar', false);
              return;
            }
            if (response.data.r && !response.data.e.startsWith('El RIF ya')) {
              if (response.data.e.startsWith('El SADA ya')) {
                if (response.data.e.includes('diferente')) {
                  this.mostrarAlerta('El SADA ya se encuentra registrada con otro vendedor y ha sido reactivada', 'advertencia');
                } else {
                  this.mostrarAlerta('El SADA ya se encuentra registrada y ha sido reactivada, verifica la lista de clientes de nuevo', 'advertencia');
                }
              } else {
                this.mostrarAlerta(`La ${esSucursal ? 'sucursal' : 'empresa'} ha sido agregada correctamente`, 'correcto');
              }
              this.limpiarCampos();
              this.$emit('cerrar', false);
              // En caso de se agregar una empresa, emite clienteAgregado con al response del id empresa
              if (!esSucursal) {
                this.$emit('clienteAgregado', response.data.emp);
                return;
              }
              this.$emit('clienteAgregado');
            } else {
              // Ya existe un cliente con el RIF, se envia el id del cliente
              const valorDeRespuesta = response.data.emp;
              if (valorDeRespuesta) {
                this.botonDeshabilitado = false;
                this.$emit('clienteDuplicado', valorDeRespuesta);
                return;
              } else {
                this.botonDeshabilitado = false;
                this.mostrarAlerta(`Ocurrió un error obtieniendo información sobre la empresa existente`, 'error');
                return;
              }
            }
          })
          .catch(() => {
            this.botonDeshabilitado = false;
            this.desFormatearTelefonos();
            this.mostrarAlerta(`Ha ocurrido un error agregando ${esSucursal ? 'la sucursal' : 'el cliente'}, por favor intenta de nuevo`, 'error');
          });
    },
    desFormatearTelefonos() {
      // Da formato a los teléfonos del cliente
      this.cliente.tel ? this.formatearTelefono(this.cliente.tel) : '';
      this.cliente.tele ? this.formatearTelefono(this.cliente.tele) : '';
      this.cliente.tels ? this.formatearTelefono(this.cliente.tels) : '';
    },
    editarCliente(entidad, esSucursal) {
      const variablesPost = JSON.parse(JSON.stringify(entidad));
      // Ajustar datos a esquema de la API al editar cliente
      if (!esSucursal) {
        variablesPost.id_emp = this.empresaId;
        variablesPost.tel = variablesPost.tele;
        delete variablesPost.tele;
      } else {
        // Validaciones adicionales y conversion de datos al editar sucursal
        variablesPost.rif ? delete variablesPost.rif : '';
        variablesPost.rel ? delete variablesPost.rel : '';
        // Ajustar datos a esquema de la API al editar cliente
        variablesPost.zon = Number(variablesPost.zon);
        variablesPost.emp = Number(variablesPost.emp);
        variablesPost.ret = Number(variablesPost.ret);
        variablesPost.tcli = Number(variablesPost.tcli);
        variablesPost.emp = this.empresaId;
      }
      apiPost({s: esSucursal ? 'sucursalEditarMv' : 'empresaEditarMv'}, JSON.stringify(variablesPost))
          .then((response) => {
            this.desFormatearTelefonos();
            if (response.data.r && !response.data.e.startsWith('Faltan datos')) {
              this.mostrarAlerta(`La ${esSucursal ? 'sucursal' : 'empresa'} ha sido actualizada correctamente`, 'correcto');
              this.$emit('clienteAgregado');
              this.limpiarCampos();
              this.$emit('cerrar', false);
              return;
            }
            this.botonDeshabilitado = false;
            if (response.data.e.startsWith('Hubo un error guardando al encargado: Duplicate entry')) {
              this.mostrarAlerta('La cédula de la persona de contacto ya se encuentra registrada', 'advertencia');
              this.$emit('clienteAgregado');
              this.limpiarCampos();
              this.$emit('cerrar', false);
              return;
            }
            return this.mostrarAlerta(`Ocurrió un error: ${response.data.e}`, 'error');
          })
          .catch(() => {
            this.botonDeshabilitado = false;
            this.desFormatearTelefonos();
            this.mostrarAlerta(`Ha ocurrido un error actualizando ${esSucursal ? 'la sucursal' : 'el cliente'}. Por favor intenta de nuevo`, 'error');
          });
    },
  },
  watch: {
    mostrarModal: function() {
      if (!this.mostrarModal) {
        this.limpiarCampos();
      }
    },
    esEditar: function(valor) {
      if (valor && this.esSucursal) {
        const sucursal = JSON.parse(JSON.stringify(this.sucursal));
        this.cliente.sad = sucursal.sada_suc;
        this.cliente.suc = sucursal.id_suc;
        this.cliente.nom = sucursal.nom_suc;
        this.cliente.cod = sucursal.cod_suc;
        this.cliente.lat = sucursal.lat_suc;
        this.cliente.lon = sucursal.lon_suc;
        this.cliente.dir = sucursal.dir_suc;
        this.cliente.ema_suc = sucursal.ema_suc;
        this.cliente.tels = this.formatearTelefono(sucursal.tel_suc);
        this.cliente.tcli = sucursal.id_tcl_suc;
        this.cliente.zon = sucursal.id_zon_suc;
        this.cliente.ret = sucursal.id_res_suc;
        this.cliente.fname = sucursal.nom_per_enc;
        this.cliente.lname = sucursal.ape_per_enc;
        this.cliente.ced = sucursal.ced_per_enc;
        this.cliente.tel = sucursal.tel_per_enc ? this.formatearTelefono(sucursal.tel_per_enc) : sucursal.tel_per_enc;
        this.cliente.ema_per = sucursal.ema_per_enc;
        this.cliente.enc = sucursal.id_enc;
        this.cliente.dirp = sucursal.dir_enc;
      }
      if (valor && this.esCliente) {
        const empresa = JSON.parse(JSON.stringify(this.empresa));
        this.cliente.nom = empresa.nom_emp;
        this.cliente.dir = empresa.dir_emp;
        this.cliente.rif = empresa.rif_emp;
        this.cliente.tele = this.formatearTelefono(empresa.tel_emp);
        this.cliente.ema_emp = empresa.ema_emp;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
