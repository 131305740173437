
/* eslint-disable */
const EstatusPago = [
    {
        nombre: 'Notificado'
    },
    {
        nombre: 'Rechazado'
    },
    {
        nombre: 'Confirmado'
    },
    {
        nombre: 'Auditado'
    },
]

const EstatusPagoIconos = {
    Notificado: 'exclamation-circle',
    Rechazado: 'times-circle',
    Confirmado: 'check-circle',
    Auditado: 'search-dollar'
}
export {
    EstatusPago,
    EstatusPagoIconos
};
