import axios from 'axios';
import router from '@/router';
const baseURL = process.env.NODE_ENV === 'production' ?
  'https://industriaskel.com/vendedor/ven.php' :
  'http://localhost/SisKEL/api-kel/vendedor/ven.php';

/**
 * Función para obtener/enviar datos de api-kel usando el método POST
 * @param {object} params
 * @param {object} data
 * @return {object}
 */
function apiPost(params, data) {
  return new Promise((resolve, reject) => {
    axios.request({
      baseURL,
      method: 'post',
      params,
      data,
      withCredentials: true,
    })
        .then((respuesta) => {
          if (typeof respuesta.data === 'string' && respuesta.data.indexOf('<!--log-->') === 0) {
            router.push('/login');
          }
          return resolve(respuesta);
        })
        .catch((error) => reject(error));
  });
}
/**
 * Función para obtener datos de api-kel usando el método GET
 * @param {object} params
 * @return {string}
 */
function apiGet(params) {
  return new Promise((resolve, reject) => {
    axios.request({
      baseURL,
      method: 'get',
      params,
      withCredentials: true,
    })
        .then((respuesta) => resolve(respuesta))
        .catch((error) => reject(error));
  });
}

export {
  apiPost,
  apiGet,
};
