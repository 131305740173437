<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarModal"
      @close="$emit('cerrar', false)"
    >
      <mdb-modal-header>
        <mdb-modal-title>Actualizar actividad</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="contenedor-opciones">
        <div
          v-if="(!notasDeEntrega.length || adicionalesDelPedido.estado.trim().toLowerCase() === 'cancelado')  && !inventarioAEditar.length && !devolucionAEditar.length"
          class="mensaje-no-items pb-4"
        >
          <font-awesome-icon icon="exclamation-circle" size="5x" class="icono" />
          <p class="texto">No hay productos disponibles para actualizar</p>
        </div>
        <div v-else class="row justify-content-around pb-4">
          <div
            v-if="notasDeEntrega && notasDeEntrega.length && adicionalesDelPedido.estado.trim().toLowerCase() === 'en cola'"
            class="col-12 col-sm-6"
            @click="botonDeshabilitado ? '' : (mostrarModalTomarCantidad = true, esPedido = true)"
          >
            <input
              id="pedido"
              type="radio"
              name="pedido"
              :disabled="botonDeshabilitado"
            />
            <label for="pedido" class="opciones-actividad">
              <font-awesome-icon icon="clipboard-list" />
              Pedido
            </label>
          </div>
          <div
            v-if="inventarioAEditar && inventarioAEditar.length"
            class="col-12 col-sm-6"
            @click="botonDeshabilitado ? '' : (mostrarModalTomarCantidad = true, esInventario = true)"
          >
            <input
              id="inventario"
              type="radio"
              name="inventario"
              :disabled="botonDeshabilitado"
            />
            <label for="inventario" class="opciones-actividad">
              <font-awesome-icon icon="boxes" />
              Inventario
            </label>
          </div>
          <div
            v-if="devolucionAEditar && devolucionAEditar.length"
            class="col-12 col-sm-6"
            @click="botonDeshabilitado ? '' : (mostrarModalTomarCantidad = true, esDevolucion = true)"
          >
            <input
              id="devolucion"
              type="radio"
              name="devolucion"
              :disabled="botonDeshabilitado"
            />
            <label for="Devolucion" class="opciones-actividad">
              <font-awesome-icon icon="file-invoice" />
              Devolución
            </label>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <TomarCantidad
      :esPedido="esPedido"
      :esDevolucion="esDevolucion"
      :esInventario="esInventario"
      :esEditar="true"
      :mostrarModal="mostrarModalTomarCantidad"
      :notasDeEntrega="esPedido ? notasDeEntrega : []"
      :inventarioAEditar="esInventario ? inventarioAEditar : []"
      :devolucionAEditar="esDevolucion ? devolucionAEditar : []"
      :adicionalesDelPedido="adicionalesDelPedido"
      @alertaMensaje="emitirAlertaMensaje($event)"
      @tomarPedido="tomarPedido($event)"
      @tomarInventario="tomarInventario($event)"
      @tomarDevolucion="tomarDevolucion($event)"
      @cerrar="mostrarModalTomarCantidad=false; esPedido=false; esInventario=false; esDevolucion=false;"
    />
  </section>
</template>

<script>
import {mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody} from 'mdbvue';
import TomarCantidad from '@/components/TomarCantidad.vue';
import {apiPost} from '@/funciones/api.js';
export default {
  name: 'EditarActividad',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    TomarCantidad,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    id_ped: {
      type: Number,
      required: false,
    },
    id_isu: {
      type: Number,
      required: false,
    },
    id_dev: {
      type: Number,
      required: false,
    },
    notasDeEntrega: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    inventarioAEditar: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    devolucionAEditar: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    adicionalesDelPedido: {
      type: Object,
      required: false,
      default: () => {
        return {
          pag: '',
          doc: '',
          tpre: '',
          dsc: '',
          obs: '',
          estado: '',
        };
      },
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      mostrarModalTomarCantidad: false,
      esPedido: false,
      esInventario: false,
      esDevolucion: false,
    };
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    emitirAlertaMensaje(evento) {
      if (!evento.contenido || !evento.tipo) return;
      this.$emit('alertaMensaje', {
        contenido: evento.contenido,
        tipo: evento.contenido,
      });
    },
    alertaMensaje(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    // método para editar pedido
    tomarPedido(event) {
      const pedidoConFormato = {
        presentaciones: {},
        id_ped: this.id_ped,
      };
      const {pago, pedido} = JSON.parse(JSON.stringify(event));
      Object.assign(pedidoConFormato.presentaciones, {
        ...pedido,
      });
      Object.assign(pedidoConFormato, {
        ...pago,
      });
      // Enviar datos API
      if (this.adicionalesDelPedido.estado.trim().toLowerCase() !== 'en cola') {
        this.alertaMensaje('Solo se pueden editar pedidos En cola', 'advertencia');
        return;
      }
      this.botonDeshabilitado = true;
      apiPost({s: 'pedEdiMv'}, JSON.stringify(pedidoConFormato))
          .then((response) => {
            if (response.data.r) {
              this.alertaMensaje('Pedido actualizado correctamente', 'correcto');
              this.botonDeshabilitado = false;
              this.mostrarModalTomarCantidad = false;
              this.$emit('reiniciarDatos', true);
              return;
            }
            this.alertaMensaje(`${response.data.e}`, 'error');
            this.botonDeshabilitado = false;
          })
          .catch(() => {
            this.botonDeshabilitado = false;
            this.alertaMensaje('Ocurrió un error actualizando el pedido. Por favor intenta de nuevo', 'error');
          });
    },
    // método para editar inventario
    tomarInventario(event) {
      const datosInventarios = JSON.parse(JSON.stringify(event));
      const inventarioConformato = {
        presentaciones: {},
        id_isu: this.id_isu || Object.values(datosInventarios).filter((datos) => datos.id_isu).at(0).id_isu,
      };
      Object.assign(inventarioConformato.presentaciones, {
        ...datosInventarios,
      });
      this.botonDeshabilitado = true;
      apiPost({s: 'ediInvCliMv'}, JSON.stringify(inventarioConformato))
          .then((response) => {
            if (response.data.r) {
              this.alertaMensaje('Inventario actualizado correctamente', 'correcto');
              this.botonDeshabilitado = false;
              this.mostrarModalTomarCantidad = false;
              this.$emit('reiniciarDatos', true);
              return;
            }
            this.alertaMensaje(`${response.data.e}`, 'error');
            this.botonDeshabilitado = false;
          })
          .catch(() => {
            this.botonDeshabilitado = false;
            this.alertaMensaje('Ocurrió un error actualizando el inventario. Por favor intenta de nuevo', 'error');
          });
    },
    // método para editar devolución
    tomarDevolucion(event) {
      const datosDevolucion = JSON.parse(JSON.stringify(event));
      const devolucionConFormato = {
        presentaciones: {},
        id_dev: this.id_dev || Object.values(datosDevolucion).filter((datos) => datos.id_dev).at(0).id_dev,
      };
      Object.assign(devolucionConFormato.presentaciones, {
        ...datosDevolucion,
      });
      this.botonDeshabilitado = true;
      apiPost({s: 'ediDevCliMv'}, JSON.stringify(devolucionConFormato))
          .then((response) => {
            if (response.data.r) {
              this.alertaMensaje('Toma de devolución actualizada correctamente', 'correcto');
              this.botonDeshabilitado = false;
              this.mostrarModalTomarCantidad = false;
              this.$emit('reiniciarDatos', true);
              return;
            }
            this.alertaMensaje(`${response.data.e}`, 'error');
            this.botonDeshabilitado = false;
          })
          .catch(() => {
            this.botonDeshabilitado = false;
            this.alertaMensaje('Ocurrió un error actualizando la Toma de devolución. Por favor intenta de nuevo', 'error');
          });
    },
  },
};
</script>

<style lang="scss" scoped>
header.font-weight-bold {
  margin: .5rem 0 0;
  text-align: center;
  @include agregar-prefijos(user-select, none);

  @media screen and (min-width: 992px) {
    margin: .5rem 0 0;
  }
}
.contenedor-opciones {
  input[type="radio"],
  input[type="checkbox"] {
    display: none;

    &:checked + .opciones-actividad {
      border-color: $primario;
      color: $primario;
      font-weight: 500;
    }
  }
}
.opciones-actividad {
  align-items: center;
  border: 1px solid $gris-tr-borde;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  font-size: .85rem;
  margin: .5rem 0;
  padding: .5rem;
  text-align: center;
  width: 100%;
  @include agregar-prefijos(transition, all .25s ease);
  @include agregar-prefijos(user-select, none);

  @media screen and (min-width: 576px) {
    font-size: .9rem;
  }

  svg {
    height: 18px;
    margin: 0 .25rem 0 0;
    width: 18px;
  }
}
</style>
