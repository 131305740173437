<template>
  <section class="login">
    <div class="card">
      <img
        src="/img/icons/android-chrome-512x512.png"
        alt="Vue logo"
        class="col-6 col-lg-4 mx-auto mx-lg-0 px-0"
      >
      <form class="col-lg-7 px-0 text-center" @submit.prevent="validarLogin">
        <header class="h3-responsive">Iniciar sesión</header>
        <mdb-input
          v-model.trim="usuario"
          label="Usuario"
          :class="['text-left', validarValores.usuario]"
          :validation="validarValores.usuario === 'invalido'"
          :invalidFeedback="mensajesInvalidos.usuario"
          outline
        />
        <mdb-input
          v-model.trim="clave"
          :type="mostrarClave ? 'text' : 'password'"
          label="Clave"
          :class="['text-left', validarValores.clave]"
          :validation="validarValores.clave === 'invalido'"
          :invalidFeedback="mensajesInvalidos.clave"
          outline
        >
          <mdb-btn
            dark-waves
            flat
            group
            :icon="mostrarClave ? 'eye' : 'eye-slash'"
            size="md"
            slot="append"
            @click="mostrarClave = !mostrarClave"
          />
        </mdb-input>
        <mdb-btn
          type="submit"
          color="secundario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'sign-in-alt'"
          :icon-class="[{'fa-spin': botonDeshabilitado}]"
          class="my-2"
        >
          Entrar
        </mdb-btn>
        <mdb-btn
          flat
          dark-waves
          :icon="botonDeshabilitado ? 'circle-notch' : 'lock'"
          :icon-class="[{'fa-spin': botonDeshabilitado}]"
          class="px-3"
        >
          Recuperar clave
        </mdb-btn>
      </form>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>
<script>
import {mdbBtn, mdbInput} from 'mdbvue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import {apiPost} from '@/funciones/api.js';
export default {
  name: 'Login',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje,
  },
  data() {
    return {
      alertaMensaje: {contenido: ''},
      botonDeshabilitado: false,
      clave: '',
      mostrarClave: false,
      usuario: '',
      validarValores: {},
      mensajesInvalidos: {
        usuario: '',
        clave: '',
      },
    };
  },
  methods: {
    validarLogin() {
      this.validarValores = {
        usuario: this.usuario.length ? 'valido' : 'invalido',
        clave: this.clave.length ? 'valido' : 'invalido',
      };

      this.mensajesInvalidos = {
        usuario: this.usuario && this.usuario.length ? 'Verifica el usuario' : 'Escribe el usuario',
        clave: this.clave && this.clave.length ? 'Verifica la clave' : 'Escribe la clave',
      };

      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos. Falta más funcionalidad',
          tipo: 'error',
        };
      }
      apiPost({s: 'auth'}, `usuario=${this.usuario}&frase=${btoa(this.clave)}&k=1`)
          .then((res) => {
            if (res.data.r || res.data.Seleccion === 'No existe') {
              this.$router.push('/');
            } else {
              this.alertaMensaje = {
                contenido: res.data.e,
                tipo: 'error',
              };
              this.validarValores = {
                usuario: 'invalido',
                clave: 'invalido',
              };
            }
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  align-items: center;
  background-image: linear-gradient(20deg, $primario, $secundario);
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .card {
    border-radius: 40px;
    padding: 1rem 1.25rem 2.5rem;
    width: 80%;

    @media screen and (min-width: 576px) {
      max-width: 350px;
    }
    @media screen and (min-width: 992px) {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      max-width: 750px;
      padding: 3rem 2rem;
    }

    header.h3-responsive {
      background-image: linear-gradient(350deg, $primario, $secundario);
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
}
</style>
