<template>
  <article class="contenedor-hijo">
    <div class="container">
      <div class="row justify-content-center justify-content-md-between mb-3 ml-2 ml-sm-0">
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="arrow-left"
            type="button"
            title="Regresar"
            @click="$router.go(-1)"
          >
            Regresar
          </mdb-btn>
        </div>
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="pen"
            type="button"
            title="Editar pedido"
            v-if="pedido && pedido.length && pedido[0].est_ped === 'en cola'"
            @click="mostrarModalTomarCantidad= !mostrarModalTomarCantidad"
          >
            Editar pedido
          </mdb-btn>
        </div>
        <div class="col-auto px-0">
          <mdb-btn
            v-if="pedido && pedido.length && pedido[0].est_ped === 'en cola'"
            flat
            dark-waves
            icon="ban"
            type="button"
            title="Anular pedido"
            @click="modalConfirmacionEliminar= !modalConfirmacionEliminar"
          >
            Anular pedido
          </mdb-btn>
        </div>
      </div>
      <li
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </li>
      <div v-else>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles del pedido</header>
          <div
            class="row mx-0 mt-2"
            v-if="pedido && pedido.length"
          >
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Estado del pedido</span>
              <p
                v-if="pedido && pedido[0].est_ped"
                :class="[asignarColorEstado(pedido[0].est_ped), 'font-weight-bold'] "
              >
                {{ pedido[0].est_ped.slice(0,1).toUpperCase() + pedido[0].est_ped.slice(1) }}
              </p>
              <p
                v-else
                class="text-muted font-italic user-select-none"
              >
                Sin especificar
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Condición de pago</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].nom_cp }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Descuento del pedido</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].dsc_ped ? pedido[0].dsc_ped  : 'Sin descuento especificado'}}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Cliente, Sucursal</span>
              <p>
                <span :class="pedido[0] && !pedido[0].nom_emp.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ pedido[0] && pedido[0].nom_emp ? pedido[0].nom_emp : 'Cliente no especificado' }},
                </span>
                <span :class="pedido[0] && !pedido[0].nom_suc.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ pedido[0] && pedido[0].nom_suc ? pedido[0].nom_suc : 'Sucursal no especificada' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Número de pedido</span>
              <p class="text-capitalize">{{ this.$route.params.id }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Fecha del pedido</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].fec_ped ? pedido[0].fec_ped : 'Sin fecha especificada' }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <span class="small-block font-titulo">Dirección</span>
              <p class="text-capitalize">
                {{ pedido[0] && pedido[0].dir_suc }},
                {{ pedido[0] && pedido[0].ciu_zon }},
                {{ pedido[0] && pedido[0].est_zon }}.
              </p>
            </div>
            <div class="col-12 col-sm-6">
              <span class="small-block font-titulo">Observación</span>
              <p
                :class="{
                  'text-muted font-italic user-select-none': pedido[0] && !pedido[0].obs_ped.length,
                }"
              >
                {{
                  (pedido[0] && pedido[0].obs_ped && pedido[0].obs_ped) ||
                  'Sin especificar'
                }}
              </p>
            </div>
          </div>
          <div
            v-else
            class="mensaje-no-items pb-3"
          >
            <font-awesome-icon icon="clipboard" size="5x" class="icono" />
            <p class="texto">Sin detalles del pedido</p>
          </div>
        </div>
          <!-- Productos del pedido de la actividad -->
          <ul class="table p-productos mt-3">
            <li class="encabezado sticky">
              <header class="h4-responsive">Productos del pedido</header>
              <p class="descripcion">
                Productos listados: {{ notasPedido && notasPedido.length }}
              </p>
              <div class="thead claro">
                <div class="col-12 col-sm">
                  Nombre
                  <span class="small-block">Categoría</span>
                </div>
                <div class="col-12 col-sm">
                  Descripción - Código
                  <span class="small-block d-xl-none">Unidades / Empaque</span>
                </div>
                <div class="col-12 col-xl d-none d-xl-block">
                  Unidades - Empaque
                </div>
                <div class="col-12 col-sm text-right">
                  Cantidad
                  <span class="small-block">Despachado</span>
                </div>
              </div>
            </li>
            <li
              v-if="notasPedido && !notasPedido.length"
              class="mensaje-no-items py-3"
            >
              <font-awesome-icon icon="clipboard" size="5x" class="icono" />
              <p class="texto text-center">No existen productos en el pedido de la actividad</p>
            </li>
            <li
              class="contenido"
              v-for="(producto, i) in notasPedido"
              :key="producto.id_pre+'-'+i"
            >
              <div
                class="col-12 col-sm"
                data-columna="Nombre / Categoría"
              >
                <p>
                  {{ producto.nom_pro }}
                  <span class="small-block">
                    {{ producto.nom_cat }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-sm" data-columna="Descripción - Código / Unidades / Empaque">
                <p>
                  {{ producto.des_pre }} - {{producto.cod_pre}}
                  <span class="small-block d-xl-none">
                    {{ producto.uni_paq_pre }} / {{producto.paq_pre}}
                  </span>
                </p>
              </div>
              <div class="d-none d-xl-block col-12 col-sm" data-columna="Unidades - Empaque">
                <p>
                  {{ producto.uni_paq_pre }} - {{producto.paq_pre}}
                </p>
              </div>
              <div class="cl-12 col-sm" data-columna="Cantidad / Despachado">
                <p class="text-right">
                    {{ producto.can_ped_np}}
                    <span class="small-block">
                      {{ cantidadDes(producto) }}
                    </span>
                </p>
              </div>
            </li>
          </ul>
      </div>
    </div>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <EditarActividad
      :id_ped="parseInt($route.params.id)"
      :mostrarModal="mostrarModalTomarCantidad"
      :notasDeEntrega="notasPedido"
      :adicionalesDelPedido="{
        pag: pedido[0] && pedido[0].nom_cp,
        doc: pedido[0] && pedido[0].nom_tdoc,
        tpre: pedido[0] && pedido[0].nom_tpre,
        dsc: pedido[0] && pedido[0].dsc_ped,
        obs: pedido[0] && pedido[0].obs_ped,
        estado: pedido[0] && pedido[0].est_ped,
      }"
      @alertaMensaje="alertaMensaje = $event"
      @reiniciarDatos="obtenerDetallesPedido()"
      @cerrar="mostrarModalTomarCantidad=false;"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      textoBotonConfirmar="Anular"
      titulo="¡Esta acción es irreversible!"
      mensaje="Se anulará el pedido"
      @cerrar="modalConfirmacionEliminar = false;"
      @confirmar="cancelarPedido"
    />
  </article>
</template>

<script>
import EditarActividad from '@/components/EditarActividad.vue';
import {apiPost} from '@/funciones/api.js';
import {asignarColorEstado} from '@/funciones/funciones.js';
import {mdbBtn} from 'mdbvue';
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import LoaderKel from '@/components/LoaderKel.vue';
export default {
  name: 'Pedido',
  components: {
    EditarActividad,
    mdbBtn,
    ConfirmacionEliminar,
    AlertaMensaje,
    LoaderKel,
  },
  data() {
    return {
      asignarColorEstado,
      pedido: [],
      notasPedido: [],
      productosPedido: [],
      botonDeshabilitado: false,
      mostrarModalTomarCantidad: false,
      modalConfirmacionEliminar: false,
      cargando: false,
      alertaMensaje: {
        contenido: '',
      },
      ivaTotal: 0,
    };
  },
  mounted() {
    this.obtenerDetallesPedido();
  },
  computed: {
    calcularSubTotal() {
      let subtotal = 0;
      this.notasPedido.forEach((nota) => {
        subtotal += nota.pvmp_pre * nota.can_ped_np;
      });
      return subtotal;
    },
    calcularDescuento() {
      if (!this.pedido.length) return 0;
      return ((parseFloat(this.pedido[0].dsc_ped) / 100) * this.calcularSubTotal).toFixed(2);
    },
    calcularTotalIva() {
      let iva = 0;
      this.notasPedido.forEach((nota) => {
        iva += (parseFloat(nota.pvmp_pre || 0) *
          parseFloat(nota.can_ped_np) *
          parseFloat(nota.iva_pre)) /
        100;
      });
      return iva;
    },
    calcularMontoTotal() {
      return (parseFloat(this.calcularSubTotal) - parseFloat(this.calcularDescuento) + parseFloat(this.calcularTotalIva)).toFixed(2) || 0;
    },
  },
  methods: {
    async obtenerDetallesPedido() {
      this.cargando = true;
      if (!this.$route.params.id) {
        this.$router.push('/pedidos');
        return;
      }
      try {
        const {data} = await apiPost(
            {s: 'ped_detM'},
            'ped=' + this.$route.params.id,
        );
        if (data) {
          this.pedido = data.ped;
          this.productosPedido = data.pre;
          this.notasPedido = data.not;
        }
        this.cargando = false;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'No se pudo obtener el pedido',
          tipo: 'error',
        };
        this.cargando = false;
        this.$router.push('/pedidos');
      }
    },
    calculaTotal(cantidad, precio) {
      return cantidad * precio;
    },
    calcularIva(cantidad, precio=0, iva=0) {
      return (parseFloat(precio || 0) *
          parseFloat(cantidad) *
          parseFloat(iva)) /
        100;
    },
    cantidadDes(notas) {
      return Number(notas.can_ped_np) === Number(notas.can_np) ? 0 : Number(notas.can_ped_np) - Number(notas.can_np);
    },
    async cancelarPedido() {
      this.botonDeshabilitado = true;
      apiPost({s: 'cancP'}, 'ped=' + this.$route.params.id)
          .then((response) => {
            if (response.data.r) {
              this.alertaMensaje = {
                contenido: 'Pedido anulado correctamente',
                tipo: 'correcto',
              };
              this.modalConfirmacionEliminar = false;
              if (this.pedido[0].est_ped === 'en cola') {
                this.pedido[0].est_ped = 'Cancelado';
              }
            } else {
              this.alertaMensaje = {
                contenido: 'No se pudo anular el pedido',
                tipo: 'advertencia',
              };
            }
            this.botonDeshabilitado = false;
          }).catch(()=>{
            this.botonDeshabilitado = false;
            this.alertaMensaje = {
              contenido: `Ocurrió un error anulando el pedido.`,
              tipo: 'error',
            };
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.table.p-productos {
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
</style>
