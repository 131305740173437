<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h5-responsive text-lg-left pl-4">
          Filtros de búsqueda
        </header>
        <!-- <div class="col-auto boton-primario pr-4">
          <mdbBtn
            color="primario"
            icon="plus"
            class="px-3"
            @click="FormularioPagos = true"
          >
            Nuevo Pago
          </mdbBtn>
        </div> -->
      </div>
      <form class="row filtros" @submit.prevent>
        <div class="col-12 col-xl-6">
          <mdb-input
            v-model.trim="pagosAFiltar"
            type="text"
            label="Nombre o RIF"
            class="my-2"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 my-2">
          <v-select
            v-model.trim="bancoFiltrar"
            :options="BancosPago"
            :reduce="cuenta => cuenta.id_cba"
            :getOptionLabel="option => option.nom_cba"
            placeholder="Selecciona banco"
            multiple
            class="outline-datalist"
          >
            <template #header>
              <label class="datalist-label">
                Bancos
              </label>
            </template>
            <template #option="{ nom_cba }">
              {{ nom_cba }}
            </template>
            <template #no-options v-if="bancoFiltrar && bancoFiltrar.length < 3">
              Selecciona un banco
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 my-2">
          <v-select
            v-model="estatusFiltrar"
            :options="EstatusPago"
            :reduce="estatus => estatus.nombre"
            :getOptionLabel="option => option.nombre"
            placeholder="Selecciona estatus"
            multiple
            class="outline-datalist"
          >
            <template #header>
              <label class="datalist-label">
                Estatus de pago
              </label>
            </template>
            <template #option="{ nombre }">
              {{ nombre }}
            </template>
            <template #no-options v-if="estatusFiltrar && estatusFiltrar.length < 3">
              Selecciona un estatus
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 my-2">
          <v-select
            v-model="metodoFiltrar"
            :options="MetodosPago"
            :reduce="metodo => metodo.id_mep"
            :getOptionLabel="option => option.nom_mep"
            placeholder="Selecciona método"
            multiple
            class="outline-datalist"
          >
            <template #header>
              <label class="datalist-label">
                Método de pago
              </label>
            </template>
            <template #option="{ nom_mep }">
              {{ nom_mep }}
            </template>
            <template #no-options v-if="metodoFiltrar && metodoFiltrar.length < 3">
              Selecciona un método de pago
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-xl-4">
          <mdb-input
            class="my-2"
            v-model="fechaPago"
            type="month"
            label="Mes"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md">
          <mdb-input
            class="my-2"
            v-model="fechaDel"
            type="date"
            label="Desde"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md">
          <mdb-input
            class="my-2"
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            outline
          />
        </div>
        <div
          v-if="$route.params.sucursal || pagosAFiltar.length || bancoFiltrar.length || fechaDel.length || fechaHasta.length"
          class="col col-xl-12 d-flex justify-content-center"
        >
          <mdb-btn
            flat
            dark-waves
            icon="broom"
            class="mx-0 px-1"
            @click="RestablecerFiltros()"
          >
            Restablecer Filtros
          </mdb-btn>
        </div>
      </form>
      <ul class="table pedidos">
        <li class="encabezado sticky">
          <header class="h4-responsive">Reporte de pagos</header>
          <p class="descripcion">
            Pagos listados: {{pagos && Object.keys(pagos).length}}
          </p>
          <div class="thead claro">
            <div class="col-12 col-sm-auto pr-sm-1 fecha">
              Día
              <span class="small-block">
                Mes
              </span>
            </div>
            <div class="col-12 col-sm col-md-4">
              Cliente - Sucursal
              <span class="small-block">
                N°pago - Zona
              </span>
            </div>
            <div class="col-12 col-sm">
              Banco - Método - Referencia
              <span class="small-block">
                Monto - Tasa cambio
              </span>
            </div>
            <div class="col-12 col-sm-3 text-sm-right">
              Estatus
            </div>
          </div>
        </li>
        <li
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </li>
        <!-- Mensaje para fecha sin pagos -->
        <div
          v-if="pagos && !Object.keys(pagos).length && !cargando"
          class="mensaje-no-items pb-3"
        >
          <font-awesome-icon
            icon="calendar-times"
            size="5x"
            class="icono"
          />
          <p class="texto">
            No hay pagos con el filtro seleccionado
          </p>
        </div>
        <li
          class="contenido cursor-pointer"
          v-for="p in pagos"
          :key="p.id_aco"
          @click="$router.push(`/cobranzas/${p.id_aco}`)"
        >
          <div
            class="col-12 col-sm-auto pr-sm-1 fecha"
            data-columna="Día / Mes"
          >
            <p>
              {{formatearFecha(p.fec_rec_aco).ddmm.slice(0, 2)}}
              <span class="small-block">
                {{mesEnLetra(p.fec_rec_aco)}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm col-md-4"
            data-columna="Cliente - Sucursal / Núm Pago - Zona"
          >
            <p>
              {{p.nom_emp}} - {{p.nom_suc}}
              <span class="small-block">
                {{p.id_aco}} - {{p.ciu_zon}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm"
            data-columna="Banco - Método - #Referencia /  Monto - Tasa cambio"
          >
            <p>
              <span v-if="p.nom_cba">{{p.nom_cba}} - </span> {{p.nom_mep}} <span v-if="p.ref_aco">#{{p.ref_aco}}</span>
              <span class="small-block">
                {{convertirNumeroAFixed(p.mon_aco)}} {{p.sim_mon}} - {{convertirNumeroAFixed(p.tas_aco)}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm-3 text-sm-right"
            data-columna="Estatus"
          >
            <div class="row justify-content-sm-end align-items-center">
              <p class="col-auto pl-md-2 order-lg-2">
                <span :class="[asignarColorEstado(p.est_aco), 'font-weight-bold']">
                  {{ p.est_aco.slice(0,1).toUpperCase() + p.est_aco.slice(1) }}
                </span>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </article>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </section>
</template>

<script>
import {
  EstatusPago, EstatusPagoIconos,
} from '@/constantes/pagos.js';
import {mdbInput, mdbBtn} from 'mdbvue';
import {apiPost} from '@/funciones/api.js';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import {asignarColorEstado, formatearFecha, convertirNumeroAFixed, comprobarRutaHija} from '@/funciones/funciones.js';
import LoaderKel from '@/components/LoaderKel.vue';
export default {
  name: 'PagosView',
  components: {
    mdbInput,
    mdbBtn,
    AlertaMensaje,
    LoaderKel,
  },
  data() {
    return {
      convertirNumeroAFixed,
      EstatusPago,
      EstatusPagoIconos,
      asignarColorEstado,
      formatearFecha,
      pagos: [],
      fechaPago: '',
      fechaDel: '',
      fechaHasta: '',
      pagosAFiltar: '',
      bancoFiltrar: '',
      metodoFiltrar: '',
      estatusFiltrar: '',
      mes: {},
      clientes: [],
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      pagoId: '',
      alertaMensaje: {
        contenido: '',
      },
      cargando: false,
      // datos para el formulario de pagos
      BancosPago: [],
      MetodosPago: [],
      MonedasPago: [],
      FormularioPagos: false,
    };
  },
  mounted() {
    this.fechaPago = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
    this.obtenerDatosPagos();
    this.obtenerBancos();
    this.obtenerMetodos();
    this.obtenerMonedas();
  },
  watch: {
    fechaPago() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.actualizarPagos();
    },
    fechaHasta() {
      if (!this.fechaHasta) return;
      this.actualizarDeHasta();
    },
    bancoFiltrar() {
      this.actualizarPagos();
    },
    metodoFiltrar() {
      this.actualizarPagos();
    },
    pagosAFiltar() {
      this.actualizarPagos();
    },
    estatusFiltrar() {
      this.actualizarPagos();
    },
  },
  computed: {
    siMuestraRutaHija() {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'));
    },
  },
  methods: {
    async obtenerDatosPagos() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'cobrosBaseMv'}, '');
        this.pagos = data.cobros;
      } catch (error) {
        console.log(error);
        this.alertaMensaje = {
          contenido: 'Ocurrió un error al obteniendo los bancos',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    async obtenerBancos() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'cuentasMv'}, '');
        this.BancosPago = data.cuentas;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error al obteniendo los bancos',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    async obtenerMetodos() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'metodosPagoMv'}, '');
        this.MetodosPago = data.metodos;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error al obteniendo los métodos de pago',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    async obtenerMonedas() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'monedasMv'}, '');
        this.MonedasPago = data.monedas;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error al obteniendo las monedas de pago',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    actualizarPagos() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.pedidos = {};
        this.cargando = true;
        let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal + '&' : '';
        data += this.fechaPago ? 'y=' + this.fechaPago.split('-')[0] + '&m=' + this.fechaPago.split('-')[1] : '';
        data += this.fechaHasta && this.fechaDel && this.fechaHasta.length && this.fechaDel.length ? '&del=' + this.fechaDel + '&al=' + this.fechaHasta : '';
        data += Object.keys(this.bancoFiltrar).length ? '&bancos=' + this.bancoFiltrar : '';
        data += Object.keys(this.metodoFiltrar).length ? '&metodo=' + this.metodoFiltrar : '';
        data += Object.keys(this.estatusFiltrar).length ? '&estatus=' + this.estatusFiltrar : '';
        data += this.pagosAFiltar.length ? '&nombre=' + this.pagosAFiltar : '';
        apiPost({s: 'cobrosBaseMv'}, data)
            .then((r) => {
              this.pagos = r.data.cobros;
              this.cargando = false;
            });
      }, 500); // delay
    },
    actualizarDeHasta() {
      let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal + '&' : '';
      data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta : '';
      data += Object.keys(this.bancoFiltrar).length ? '&bancos=' + this.bancoFiltrar : '';
      data += Object.keys(this.metodoFiltrar).length ? '&metodo=' + this.metodoFiltrar : '';
      data += Object.keys(this.estatusFiltrar).length ? '&estatus=' + this.estatusFiltrar : '';
      data += this.pagosAFiltar.length ? '&nombre=' + this.pagosAFiltar : '';
      apiPost({s: 'cobrosBaseMv'}, data)
          .then((r) => {
            this.pagos = r.data.cobros;
          });
    },
    mesEnLetra(fechaPlana) {
      const mess = new Date(fechaPlana.substring(5, 7));
      const opt = {month: 'short'};
      const mesLetras = mess.toLocaleDateString('es-VE', opt);
      return mesLetras;
    },
    RestablecerFiltros() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.pagosAFiltar = '';
      this.bancoFiltrar = '';
      this.metodoFiltrar = '';
      this.fechaPago = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
      this.actualizarPagos();
    },
    filtroNombre(search) {
      if (!search) {
        this.clientes = [];
      } else {
        apiPost({s: 'cobrosBaseMv'}, 'nom=' + search)
            .then((r) => {
              if (r.data.nfil) {
                this.clientes = r.data.nfil;
              } else {
                this.clientes = [];
              }
            });
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.filtros {
  margin-bottom: 1rem;
  @media screen and (min-width: 768px) {
    margin: auto .5rem 1.5rem;
  }
}
.table.pedidos {
  margin: 0 auto 6rem;
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");

  .fecha {width: 44px;}
}
</style>
