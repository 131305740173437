const EstatusFactura = [
  {
    nombre: 'Por cobrar',
  },
  {
    nombre: 'Solvente',
  },
  {
    nombre: 'Excepción',
  },
];

export {
  EstatusFactura,
};
