<template>
  <section class="pt-3">
    <header class="h5-responsive text-center text-lg-left mb-3">
      Filtros de búsqueda
    </header>
    <FiltrosTipo1
      :zonas="zonasFiltro"
      :tipos-cli="tiposFiltro"
      cli-potenciales
      cli-planificaciones
      @zonasAFiltrar="aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
      @tiposAFiltrar="tiposAFiltrar=$event; aplicarFiltros()"
      @nombreAFiltrar="nombreAFiltrar=$event; aplicarFiltros()"
      @estatusFacturaAFiltrar="estatusFacturaAFiltrar=$event; aplicarFiltros()"
      @fechaAFiltrar="fechaAFiltrar=$event; aplicarFiltros()"
      @clientesPo="clientesPo=$event; aplicarFiltros()"
      @clientesPla="clientesPla=$event; aplicarFiltros()"
      @eliminarFiltros="eliminarFiltrosSeleccionados"
    />
    <ul class="table t-clientes">
      <li class="encabezado sticky">
        <header class="h4-responsive text-center text-sm-left">Clientes</header>
        <p class="descripcion text-capitalize text-center text-sm-left">En lista: {{Object.keys(totalEmpresas).length}} empresas, {{totalSucursales}} sucursales</p>
        <p class="descripcion text-center text-sm-left">Haz clic sobre el nombre de la empresa para seleccionarla</p>
        <div class="thead claro">
          <div class="col-12 col-sm-5 col-lg-6">
            Empresa
            <span class="small-block">
              Tipos
            </span>
          </div>
          <div class="col-4">Zonas</div>
          <div class="col">planificación</div>
        </div>
      </li>
      <li
        v-for="empresa in clientes"
        :key="`suc-${empresa.id_emp}-${empresa.id_suc}`"
        class="contenido cursor-pointer"
      >
        <div class="col-12 col-sm-5 col-lg-6 pr-1" data-columna="Empresa / Tipos">
          <label class="checkbox-opcion text-capitalize d-block text-wrap text-break">
            <input
              v-model="clientesSeleccionados"
              type="checkbox"
              name="check-empresas-seleccionadas"
              :value="empresa"
            />
            <span class="icono-checkbox" />
            <span>
              {{empresa.nom_emp}} - {{empresa.nom_suc}}
              <span class="small-block mb-0 text-wrap text-break">
                <template v-if="tipos && tipos[empresa.id_tcl_suc] && tipos[empresa.id_tcl_suc].nom_tcl">
                  {{tipos[empresa.id_tcl_suc].nom_tcl}}
                </template>
                <template v-else>
                  <span class="text-muted">Tipo no especificado</span>
                </template>
              </span>
            </span>
          </label>
        </div>
        <div class="col-12 col-sm-4 pr-1" data-columna="Zonas">
          <p class="mb-0">
            <span class="auto-coma" v-for="z in empresa.zonas" :key="'z'+z">
              <template v-if="zonas[z]">
                {{zonas[z].est_zon}} - {{zonas[z].ciu_zon}}
              </template>
              <template v-else>
                <span class="text-muted">Sin zona especificada</span>
              </template>
            </span>
          </p>
        </div>
        <div class="col-12 col-sm text-sm-center" data-columna="Planificación">
          <mdb-badge
            pill
            :color="empresa.pla ? 'bg-success' : 'bg-secundario'"
          >
            {{ empresa.pla ? 'Planificada' : 'Sin planificación' }}
          </mdb-badge>
        </div>
      </li>
    </ul>
    <!-- Listado de clientes seleccionados -->
    <ul v-if="clientesSeleccionados && clientesSeleccionados.length" class="table t-clientes">
      <li class="encabezado sticky">
          <header class="h4-responsive text-center text-sm-left">Clientes seleccionados</header>
          <p class="descripcion text-capitalize text-center text-sm-left">En lista: {{clientesSeleccionados.length}}</p>
        <div class="thead claro">
          <div class="col">
            Empresa
            <small class="d-block text-muted">Tipos</small>
          </div>
          <div class="col">Zonas</div>
          <div class="col-12 col-sm-3 text-center">Acción</div>
        </div>
      </li>
      <div
        tag="li"
        v-for="empresa in clientesSeleccionados"
        :key="`${empresa.id_emp}-${empresa.id_suc}`"
        class="contenido cursor-pointer"
      >
        <div class="col" data-columna="Empresa / Tipos">
          <p class="text-capitalize">
            {{empresa.nom_emp}} - {{empresa.nom_suc}}
            <small class="d-block text-muted text-uppercase">
              <template v-if="tipos && tipos[empresa.id_tcl_suc] && tipos[empresa.id_tcl_suc].nom_tcl">
                {{tipos[empresa.id_tcl_suc].nom_tcl}}
              </template>
              <template v-else>
                <span class="text-muted">Tipo no especificado</span>
              </template>
            </small>
          </p>
        </div>
        <div class="col" data-columna="Zonas">
          <p class="mb-0">
            <span v-for="z in empresa.zonas" :key="'z'+z">
              <template v-if="zonas && zonas[z] && zonas[z].est_zon && zonas[z].ciu_zon">
                <span class="auto-coma"> {{zonas[z].est_zon}} - {{zonas[z].ciu_zon}} </span>
              </template>
              <template v-else>
                <span class="text-muted">Sin zona especificada</span>
              </template>
            </span>
          </p>
        </div>
        <div class="col-12 col-sm-3 pr-1 px-sm-0" data-columna="Acción">
          <mdb-btn
            block
            flat
            :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
            class="m-0 py-2 px-0"
            :disabled="botonDeshabilitado"
            @click="eliminarCliente(empresa.id_emp, empresa.id_tcl_suc, empresa.id_zon_suc)"
          >
            Eliminar
          </mdb-btn>
        </div>
      </div>
    </ul>
    <form
      v-if="clientesSeleccionados && clientesSeleccionados.length"
      class="row justify-content-center mt-n2"
      @submit.prevent
    >
      <div class="col-12 col-sm-6">
        <mdb-input
          v-model="fechaPlanificada.dia"
          type="date"
          label="Fecha prevista"
          :class="['my-2',
            {'invalido-date-time': validarValores.fecha === 'invalido'},
            {'valido-date-time': validarValores.fecha === 'valido'}]"
          :validation="validarValores.fecha === 'invalido'"
          invalidFeedback="Escribe una fecha a planificar"
          outline
        />
      </div>
      <div class="col-12 col-sm-6">
        <mdb-input
          v-model="fechaPlanificada.hora"
          type="time"
          label="Hora prevista"
          :class="['my-2',
            {'invalido-date-time': validarValores.hora === 'invalido'},
            {'valido-date-time': validarValores.hora === 'valido'}]"
          :validation="validarValores.hora === 'invalido'"
          invalidFeedback="Escribe una hora a planificar"
          outline
        />
      </div>
      <div class="btn-checkgroup-contenedor mt-2 col-12">
        <span v-for="mot in motivos" :key="mot.id_ma">
          <input
            v-model="motivosAFiltrar"
            :id="`mot-${mot.id_ma}`"
            type="checkbox"
            name="checkbox-categoria"
            :value="mot.id_ma"
          >
          <label :for="`mot-${mot.id_ma}`" class="btn-checkgroup my-1">
            {{mot.motivo_ma}}
          </label>
        </span>
      </div>
      <div class="col-12">
        <mdbInput
          v-model.trim="observacion"
          type="textarea"
          label="Observación"
          class="my-2"
          outline
        />
      </div>
      <mdb-btn
        color="primario"
        :icon-class="[{'fa-spin': botonDeshabilitado}]"
        :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
        :disabled="botonDeshabilitado"
        class="mt-4"
        @click="empresasAgregar"
      >
        Agregar
      </mdb-btn>
    </form>
  </section>
</template>

<script>
// @ is an alias to /src
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue';
import {apiPost} from '@/funciones/api.js';
import {mdbBtn, mdbInput, mdbBadge} from 'mdbvue';

export default {
  name: 'ClientesFiltros',
  components: {
    FiltrosTipo1,
    mdbBtn,
    mdbInput,
    mdbBadge,
  },
  props: {
    diaSeleccionado: {
      type: String,
      required: false,
      default: () => '',
    },
    motivos: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      botonDeshabilitado: false,
      totalSucursales: 0,
      totalEmpresas: {},
      zonas: {},
      zonasFiltro: [],
      tipos: {},
      tiposFiltro: [],
      clientes: {},
      tiposAFiltrar: [],
      nombreAFiltrar: '',
      fechaAFiltrar: '',
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: '',
      municipioSeleccionado: '',
      clientesSeleccionados: [],
      estatusFacturaAFiltrar: [],
      motivosAFiltrar: ['1', '4', '9'],
      validarValores: {},
      fechaPlanificada: {
        dia: '',
        hora: '',
      },
      observacion: '',
      clientesPo: false,
      clientesPla: false,
    };
  },
  methods: {
    mostrarAlerta(contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo,
      });
    },
    eliminarCliente(idEmpresa, idTipo, idZona) {
      this.clientesSeleccionados = this.clientesSeleccionados.filter(
          (c) =>
            c.id_emp !== idEmpresa ||
          c.id_tcl_suc !== idTipo ||
          c.id_zon_suc !== idZona,
      );
      this.fechaPlanificada = {
        dia: '',
        hora: '',
      };
    },
    empresasAgregar() {
      this.validarValores = {
        fecha: this.fechaPlanificada.dia.length ? 'valido' : 'invalido',
        hora: this.fechaPlanificada.hora.length ? 'valido' : 'invalido',
        mot: this.motivosAFiltrar.length ? 'valido' : 'invalido',
      };
      if (Object.values(this.validarValores).includes('invalido')) {
        return this.mostrarAlerta('Todos los campos son requeridos', 'advertencia');
      }
      const clientes = JSON.parse(JSON.stringify(this.clientesSeleccionados));
      const idSucs = clientes.map((c) => (Number(c.id_suc)));
      const variablesPost = {
        suc: idSucs,
        fecha: this.fechaPlanificada.dia,
        hora: this.fechaPlanificada.hora,
        obs: this.observacion,
        motivos: this.motivosAFiltrar,
      };
      // Consultar en localStorage si alguna empresa se encuentra ya planificada ese día
      const existeDatos = window.localStorage.getItem(`planificador-${this.fechaPlanificada.dia}`);
      if (existeDatos) {
        let clienteAnteriormenteAgregado = false;
        let todasSucursales = [];
        const datos = Object.values(JSON.parse(existeDatos));
        datos.forEach(((f) => {
          for (const e in f.zonas) {
            if (f.zonas[e].sucursales) {
              todasSucursales = todasSucursales.concat(f.zonas[e].sucursales);
            }
          };
        }));
        todasSucursales.forEach((s) => {
          if (idSucs.includes(Number(s.id_suc))) {
            clienteAnteriormenteAgregado = true;
          }
        });
        if (clienteAnteriormenteAgregado) {
          return this.mostrarAlerta(`Algunas de las empresas seleccionadas ya se encuentra agregada, día ${this.fechaPlanificada.dia}, Por favor verifica tus datos`, 'advertencia');
        }
      }

      apiPost({s: 'planificadorCrearMv'}, JSON.stringify(variablesPost))
          .then((response) => {
            if (response.data.r) {
              this.mostrarAlerta(`Clientes agregados al planificador: ${response.data.e}`, 'correcto');
              if (this.diaSeleccionado == this.fechaPlanificada.dia) {
              // Se actualizan los clientes de la planificación solo si se agregó en dia actual del planificador
                this.$emit('clientesAgregados', true);
                // Deshabilitar el botón para evitar dobles envío de formulario
                this.botonDeshabilitado = true;
              }
              this.$emit('actualizar', true);
              this.limpiarDatos();
              this.$emit('cerrar', false);
              return;
            }
            this.mostrarAlerta(`Ocurrió un error: ${response.data.e}`, 'error');
          })
          .catch(() => {
            this.mostrarAlerta('Ha ocurrido un error agregando los clientes al planificador, por favor intenta de nuevo', 'error');
          });
    },
    limpiarDatos() {
      this.botonDeshabilitado = false;
      this.totalSucursales = 0;
      this.totalEmpresas = {};
      this.observacion = '';
      this.zonas = {};
      this.zonasFiltro = [];
      this.tipos = {};
      this.tiposFiltro = [];
      this.clientes = {};
      this.tiposAFiltrar = [];
      this.nombreAFiltrar = '';
      this.fechaAFiltrar = '';
      this.estadosAFiltrar = [];
      this.municipiosAFiltrar = [];
      this.parroquiasAFiltrar = [];
      this.estadoSeleccionado = '';
      this.municipioSeleccionado = '';
      this.clientesSeleccionados = [];
      this.motivosAFiltrar = [];
      this.validarValores = {};
      this.fechaPlanificada = {
        dia: '',
        hora: '',
      };
    },
    // Debe traer las zonas, los vendedores y los tipos de clientes.
    // También los primeros 50 clientes por orden alfabético, los demás los trae al hacer scroll (si
    // no se ha seleccionado otro filtro)
    cargaInicial() {
      this.resultados = {};
      apiPost({s: 'clientes_generalMv'}, '')
          .then((res) => {
            this.enlistarZonas(res.data.zon);
            this.zonasFiltro = res.data.zon;
            this.enlistarTipos(res.data.tcl);
            this.tiposFiltro = res.data.tcl;
          });
    },
    aplicarFiltros() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        apiPost({s: 'sucursales_filtradasMv'}, 'tipos=' + this.tiposAFiltrar + '&nombre=' + this.nombreAFiltrar +
        '&fecha_desde=' + this.fechaAFiltrar + '&estados=' + this.estadosAFiltrar + '&municipios=' + this.municipiosAFiltrar +
        '&parroquias=' + this.parroquiasAFiltrar + '&factura=' + this.estatusFacturaAFiltrar + '&clip=' + this.clientesPo + '&pla=' + this.clientesPla)
            .then((res) => {
              this.enlistarEmpresas(res.data.emp);
              this.totalSucursales = res.data.emp.length;
            });
      }, 500); // delay
    },
    aplicarFiltrosZonas(estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = [];
      this.municipiosAFiltrar = [];
      this.parroquiasAFiltrar = [];

      estadosFil.forEach((e) => {
        this.estadosAFiltrar.push(e.est_zon);
      });
      municipiosFil.forEach((m) => {
        this.municipiosAFiltrar.push(m.ciu_zon);
      });
      parroquiasFil.forEach((p) => {
        this.parroquiasAFiltrar.push(p.sec_zon);
      });

      this.aplicarFiltros();
    },
    eliminarFiltrosSeleccionados() {
      this.tiposAFiltrar = [];
      this.nombreAFiltrar = '';
      this.fechaAFiltrar = '';
      this.estadosAFiltrar = [];
      this.municipiosAFiltrar = [];
      this.parroquiasAFiltrar = [];
      this.clientes = {};
      this.totalSucursales = 0;
      this.totalEmpresas = {};
    },
    enlistarEmpresas(data) {
      const empresas = {};
      this.totalEmpresas = {};
      data.forEach((e) => {
        if (!this.totalEmpresas[e.id_emp]) {
          this.totalEmpresas[e.id_emp] = e;
        }
        empresas[`${e.nom_emp}-${e.id_suc}`] = {
          id_emp: e.id_emp,
          id_suc: e.id_suc,
          nom_suc: e.nom_suc,
          nom_emp: e.nom_emp,
          rif_emp: e.rif_emp,
          id_tcl_suc: e.id_tcl_suc,
          id_zon_suc: e.id_zon_suc,
          pla: e.id_pla ? true : false,
          tipos: {},
          zonas: {},
        };
        empresas[`${e.nom_emp}-${e.id_suc}`].tipos[e.id_tcl_suc] = e.id_tcl_suc;
        empresas[`${e.nom_emp}-${e.id_suc}`].zonas[e.id_zon_suc] = e.id_zon_suc;
      });
      this.clientes = empresas;
    },
    enlistarZonas(data) {
      const zonas = {};
      data.forEach((e) => {
        if (!zonas[e.id_zon]) {
          zonas[e.id_zon] = {
            id_zon: e.id_zon,
            est_zon: e.est_zon,
            ciu_zon: e.ciu_zon,
            sec_zon: e.sec_zon,
          };
        }
      });
      this.zonas = zonas;
    },
    enlistarTipos(data) {
      const tipos = {};
      data.forEach((e) => {
        if (!tipos[e.id_tcl]) {
          tipos[e.id_tcl] = {
            id_tcl: e.id_tcl,
            nom_tcl: e.nom_tcl,
          };
        }
      });
      this.tipos = tipos;
    },
  },
  mounted() {
    this.cargaInicial();
  },
};
</script>
<style lang="scss" scoped>
.table.t-clientes {
  margin: 2.5rem auto 3rem;

  .contenido > [class*=col] {line-height: 1.3}

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);

  // Anchos específicos de columnas
  @media screen and (min-width: $breakpointToChange) {
    .acciones {width: 140px;}
  }
}
</style>
