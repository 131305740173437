<template>
<!-- Modal para agregar horarios a la sucursal -->
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModal"
      @close="$emit('cerrar', false)"
    >
    <mdb-modal-header class="pb-0">
        <mdb-modal-title>
          Agregar nuevo horario a la sucursal
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="row align-items-center">
          <div class="col-12 col-sm">
            <div :class="['my-2 p-0 md-form md-outline outline-select', validarCopyHorario.deDia]">
              <select
                v-model="copyHorario.deDia"
                id="rel-select"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(dia, key) in dias"
                  :key="key"
                  :value="dia.value"
                >
                {{ dia.name }}
                </option>
              </select>
              <label
                for="rel-select"
                :class="copyHorario.dia ? 'label-active' : 'label-inactive'"
              >
                De
              </label>
            </div>
          </div>
          <div class="col-12 col-sm">
            <div :class="['my-2 p-0 md-form md-outline outline-select', validarCopyHorario.aDia]">
              <select
                v-model="copyHorario.aDia"
                id="rel-select"
                class="custom-select"
              >
                <option class="d-none" disabled value="">
                  Seleccione
                </option>
                <option
                  v-for="(dia, key) in dias"
                  :key="key"
                  :value="dia.value"
                >
                {{ dia.name }}
                </option>
              </select>
              <label
                for="rel-select"
                :class="copyHorario.dia ? 'label-active' : 'label-inactive'"
              >
                a
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-2">
            <mdb-input
              v-model.trim="copyHorario.horaInicio"
              type="time"
              label="Recibe desde"
              class="my-2"
              :class="['my-2 type-time', validarCopyHorario.horaInicio]"
              :validation="validarCopyHorario.horaInicio == 'invalido'"
              outline
            />
          </div>
          <div class="col-6 col-sm-2">
            <mdb-input
              v-model.trim="copyHorario.horaFinal"
              type="time"
              label="Hasta"
              class="my-2"
              :class="['my-2 type-time', validarCopyHorario.horaFinal]"
              :validation="validarCopyHorario.horaFinal == 'invalido'"
              outline
            />
          </div>
          <div class="col-12 col-sm-auto">
            <mdb-btn
              flat
              dark-waves
              icon="plus"
              class="p-2"
              @click="agregarHorarioDesdeHasta()"
            >
              Agregar
            </mdb-btn>
          </div>
        </div>
        <form id="form-horario" @submit.prevent>
          <div
            v-for="(horario, h) in horarios"
            :key="h"
            class="row align-items-center"
          >
            <div class="col-12 col-sm">
              <div :class="['my-2 p-0 md-form md-outline outline-select', validarValores[h].dia]">
                <select
                  v-model="horario.dia"
                  id="rel-select"
                  class="custom-select"
                >
                  <option class="d-none" disabled value="">
                    Seleccione
                  </option>
                  <option
                    v-for="(dia, key) in dias"
                    :key="key"
                    :value="dia.value"
                  >
                  {{ dia.name }}
                  </option>
                </select>
                <label
                  for="rel-select"
                  :class="horario.dia ? 'label-active' : 'label-inactive'"
                >
                  Dia
                </label>
              </div>
            </div>
            <div class="col-6 col-sm-3">
              <mdb-input
                v-model.trim="horario.horaInicio"
                type="time"
                label="Recibe desde"
                :class="['my-2 type-time', validarValores[h].horaInicio]"
                :validation="validarValores[h].horaInicio === 'invalido'"
                outline
              />
            </div>
            <div class="col-6 col-sm-3">
              <mdb-input
                v-model.trim="horario.horaFinal"
                type="time"
                label="Hasta"
                :class="['my-2 type-time', validarValores[h].horaFinal]"
                :validation="validarValores[h].horaFinal === 'invalido'"
                outline
              />
            </div>
            <div class="col-12 col-sm-auto">
              <mdb-btn
                flat
                dark-waves
                icon="trash"
                class="p-2"
                @click="eliminarHorario(h)"
              >
                <span class="d-sm-none">
                  Eliminar
                </span>
              </mdb-btn>
            </div>
          </div>
          <div class="text-center">
            <mdb-btn
              flat
              dark-waves
              @click="agregarOtroHorario()"
            >
              Agregar otro
            </mdb-btn>
          </div>
        </form>
        <mdb-modal-footer class="pt-2 pb-0 justify-content-center">
          <mdb-btn
            color="primario"
            class="p m-0"
            @click="agregarOtroHorario()"
          >
            {{ accion == 'crear' ? 'Guardar horarios' : accion == 'editar' ? 'Actualizar horarios' : 'Eliminar'}}
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>
<script>
// import {apiPost} from '@/funciones/api.js';
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
} from 'mdbvue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';

export default {
  name: 'AgregarHorario',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    AlertaMensaje,
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true,
    },
    accion: {
      type: String,
      required: false,
    },
    sucId: {
      type: () => String | Number,
      required: false,
    },
  },
  data() {
    return {
      alertaMensaje: {contenido: ''},
      copyHorario: {
        deDia: '',
        aDia: '',
        horaInicio: '',
        horaFinal: '',
      },
      validarCopyHorario: {},
      horarios: [],
      dias: [
        {name: 'Lunes', value: '1'},
        {name: 'Martes', value: '2'},
        {name: 'Miércoles', value: '3'},
        {name: 'Jueves', value: '4'},
        {name: 'Viernes', value: '5'},
        {name: 'Sábado', value: '6'},
        {name: 'Domingo', value: '7'},
      ],
    };
  },
  computed: {
    validarValores() {
      return this.horarios.map((horario) => ({
        dia: !!horario.dia.length ? 'valido' : 'invalido',
        horaInicio: !!horario.horaInicio ? 'valido' : 'invalido',
        horaFinal: !!horario.horaFinal || horario.horaInicio < horario.horaFinal ? 'valido' : 'invalido',
      }));
    },
  },
  methods: {
    agregarOtroHorario() {
      this.horarios.push(
          {
            dia: '',
            horaInicio: '',
            horaFinal: '',
          },
      );
    },
    agregarHorarioDesdeHasta() {
      this.validarCopyHorario = {
        deDia: !!this.copyHorario.deDia.length ? 'valido' : 'invalido',
        aDia: !!this.copyHorario.aDia.length ? 'valido' : 'invalido',
        horaInicio: !!this.copyHorario.horaInicio ? 'valido' : 'invalido',
        horaFinal: !!this.copyHorario.horaFinal || this.copyHorario.horaInicio < this.copyHorario.horaFinal ? 'valido' : 'invalido',
      };
      if (!Object.values(this.copyHorario).filter((h) => !h.length).length) {
        this.dias.forEach((dia, d) => {
          if (Number(dia.value) <= this.copyHorario.aDia && Number(dia.value) >= this.copyHorario.deDia) {
            this.horarios.push(
                {
                  dia: dia.value,
                  horaInicio: this.copyHorario.horaInicio,
                  horaFinal: this.copyHorario.horaFinal,
                },
            );
          }
        });
      } else {
        this.alertaMensaje.contenido = 'Debes llenar todos los datos para agregar el horario.';
      }
    },
    eliminarHorario(horario) {
      this.horarios.splice(horario, 1);
    },
    guardarHorario() {
      console.log('Guardar horario');
    },
  },
};
</script>
