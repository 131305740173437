// Expresión regular de un email
const emailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

// Expresión regular para validar que un strign sea solo un texo numeros, además & y .
const textoNumerosYCaracteresRegExp = /^[a-z0-9A-ZÀ-ÿ\u00f1\u00d1&.,]+(\s*[a-z0-9A-ZÀ-ÿ\u00f1\u00d1&.,]*)*[a-z0-9A-ZÀ-ÿ\u00f1\u00d1&.,]+$/;
const soloTextoRegExp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;


/**
 * Función que convierte un valor de formato string a numerico de dos decimales
 * @param {number} numero Es el valor en formato string
 * @return {number}
 */
function convertirNumeroAFixed(numero) {
  return Number.parseFloat(numero).toFixed(2);
}

/**
 * @param {String} string a comprobar
 * @return {String}  string capitalizado
 * @description Capitaliza las palabras de un string
 */
const capitalizarPalabras = (string) => {
  if (string) {
    const textoSinEspacios = string.trim().replace(/\s+/g, ' ');
    const arrayDePalabras = textoSinEspacios.split(' ');
    const arrayDePalabrasConFormato = arrayDePalabras.map(
        (p) => p.charAt(0).toUpperCase() + p.slice(1),
    );
    const stringConFormato = arrayDePalabrasConFormato.join(' ');
    return stringConFormato;
  }
};

/**
 * @param {String} texto a comprobar
 * @return {Boolean} Valor de la comprobación
 * @description Valida que el parametro enviado sea un string valido
 */
const validarTexto = (texto) => soloTextoRegExp.test(texto);

/**
 * Retorna un string con comas e y
 * @param {Array} array a comprobar
 * @return {String} Valor de la comprobación
 * @description Valida que el parametro enviado sea un string valido
 */
function autoComaYAutoY(array) {
  if (array.length) {
    return array.length == 1 ? `${array[0]}` : `${array.slice(0, -1).join(', ')} y ${array.slice(-1)}`;
  }
}

/**
 * @param {String} texto a comprobar
 * @return {Boolean} Valor de la comprobación
 * @description Valida que el parametro enviado sea un string valido
 */
const validarTextoEspecial = (texto) => textoNumerosYCaracteresRegExp.test(texto);

/**
 * Capitaliza la primera letra de una cadena
 * Función que convierte la primera letra de una cadena a mayúscula
 * @param {string} string
 * @return {string}
 */
function capitalizar(string) {
  if (string) {
    const textotoLowerCase = string.toLowerCase();
    const textoCapitalize = textotoLowerCase.replace(/(^\w|\s\w)/g, (m) =>
      m.toUpperCase(),
    );
    const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, ' ');
    string = textoSinEspacios;
    return string;
  }
}
/**
 * Elimina propiedades vacias de objetos
 * Función que regresa objeto sin propiedades vacías
 * @param {object} objeto
 * @return {object}
 */
const eliminarVacios = (objeto) =>
  Object.fromEntries(
      Object.entries(objeto)
          .filter(
              ([_, valor]) => valor !== null && valor !== undefined && valor !== '',
          )
          .map(([clave, valor]) => [
            clave,
       valor === Object(valor) ? eliminarVacios(valor) : valor,
          ]),
  );


/**
 * Devuelve el día actual
 * Función que regresa la fecha actual
 * @return {string}
 */
function diaActual() {
  return (new Date()).toISOString().split('T')[0];
}

/**
 * Devuelve la fecha actual en formato YYYY-MM-DD HH:MM:SS
 * Función que regresa la fecha actual
 * @return {string}
 */
function fechaHoraActual() {
  const hoy = new Date();
  const fecha = ('0' + hoy.getDate()).slice(-2);
  const mes = ('0' + (hoy.getMonth() + 1)).slice(-2);
  const anio = hoy.getFullYear();
  const horas = hoy.getHours();
  const minutos = hoy.getMinutes();
  const segundos = hoy.getSeconds();
  return anio + '-' + mes + '-' + fecha + ' ' + horas + ':' + minutos + ':' + segundos;
}


/**
 * Formatea la fecha en un Object con formato {dd-mm, yyyy, dd-mm-yyyy}
 * @param {string} fecha
 * @return {string}
 */
function formatearFecha(fecha) {
  const soloFecha = fecha.split(' ');
  if (!soloFecha.length) return '';
  const fechaArray = soloFecha[0].split('-');
  return {
    ddmm: `${fechaArray[2]}-${fechaArray[1]}`,
    yyyy: fechaArray[0],
    ddmmyyyy: `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`,
    yyyymmdd: `${fechaArray[0]}-${fechaArray[1]}-${fechaArray[2]}`,
  };
}
/**
 * Formatea la fecha y hora a dd-mm-aaaa hh:mm AM/PM
 * @param {string} fecha // En formato aaaa-mm-dd hh:mm:ss
 * @return {string}
 */
function formatearFechaYHora(fecha) {
  if (fecha) {
    const [FECHA, HORA] = fecha.slice(0, 16).split(' ');
    const [ANHO, MES, DIA] = FECHA.split('-');
    const [HORAS, MINUTOS] = HORA.split(':');
    let horaFormateada = '';

    if (Number(HORAS) < 12) {
      horaFormateada = `${HORAS}:${MINUTOS} AM`;
    } else {
      if (Number(HORAS) === 12) {
        horaFormateada = `${HORAS}`;
      } else {
        const formato12hrs = Number(HORAS) - 12;
        horaFormateada = formato12hrs < 10 ? `0${formato12hrs}` : `${formato12hrs}`;
      }
      horaFormateada += `:${MINUTOS} PM`;
    }

    return `${DIA}-${MES}-${ANHO} ${horaFormateada}`;
  }
}

/**
 * Formatea la fecha a dd-mm-aaaa
 * @param {string} fechaConHora // En formato aaaa-mm-dd
 * @return {string}
 */
function formatearFechaSinHora(fechaConHora) {
  if (fechaConHora) {
    const fecha = fechaConHora.split(' ');
    const [ANHO, MES, DIA] = fecha[0].split('-');
    return `${DIA}-${MES}-${ANHO}`;
  }
}

/**
 * Valida expresión regular de un teléfono
 * retorna verdadero si es válido y falso si es invalido
 * @param {string} telefono
 * @param {string} codigo
 * @return {boolean}
 */
function validarTel(telefono, codigo) {
  const telefonoFormateado = String(codigo) + String(telefono);
  const valido = parseFloat(telefonoFormateado) && /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(telefonoFormateado);
  return (valido);
}

/**
 * Valida expresión regular de un email
 * retorna verdadero si es válido y falso si es invalido
 * @param {string} email
 * @return {boolean}
 */
function validarMail(email) {
  return emailReg.test(email);
}

/**
 * Valida días de retraso o adelanto en el pago de factura
 * retorna dias de atraso/adelanto y color correspondiente
 * @param {string} ven
 * @param {string} pag
 * @return {object}
 */
function diasEstatusFac(ven, pag) {
  let dif = 0;
  let color = '';
  let dias = '';
  let fFecha2 = 0;
  const venFac = formatearFecha(ven).ddmmyyyy;
  const aFecha1 = venFac.split('-');
  const fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0]);
  if (pag) {
    const pago = formatearFecha(pag).ddmmyyyy;
    const aFecha2 = pago.split('-');
    fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0]);
  } else {
    const actual = formatearFecha(diaActual()).ddmmyyyy;
    const aFecha2 = actual.split('-');
    fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0]);
  }
  dif = fFecha2 - fFecha1;
  const total = Math.floor(dif / (1000 * 60 * 60 * 24));
  if (pag) {
    if (total <= 0) {
      dias = 'Adelantado ' + Math.abs(total);
      color = 'texto-exitoso';
    } else {
      dias = 'Atrasado ' + Math.abs(total);
      color = 'texto-error';
    }
  } else if (total <= 0) {
    dias = 'Faltan ' + Math.abs(total);
    color = 'texto-exitoso';
  } else {
    dias = 'Pasaron ' + Math.abs(total);
    color = 'texto-error';
  }
  return {dias, color};
}

/**
 * Valida estado de un pedido
 * retorna la clase correspondiente a aplicar
 * @param {string} estado
 * @return {string}
 */
function asignarColorEstado(estado) {
  const estadoCapitilizado = capitalizar(estado);
  if (estado && estado.length) {
    switch (estadoCapitilizado) {
      case 'Auditado':
      case 'Completo':
      case 'Confirmado':
      case 'Procesada':
      case 'Solvente':
      case 'Despachado':
        return 'texto-exitoso';
      case 'en cola':
      case 'En Cola':
      case 'Por confirmar':
      case 'En Espera':
      case 'En espera':
      case 'Por Cobrar':
      case 'Notificado':
      case 'Cargando':
        return 'texto-secundario';
      default:
        return 'texto-error';
    }
  }
  return '';
}

/**
 * Comprueba si se está mostrando una ruta hija
 * @param {string} ruta
 * @return {boolean}
 */
function comprobarRutaHija(ruta) {
  if (ruta) {
    return !!(ruta[1] && ruta[1].length); // Si existe ruta hija return true
  } else return false;
}

export {
  capitalizar,
  comprobarRutaHija,
  convertirNumeroAFixed,
  diaActual,
  diasEstatusFac,
  validarTel,
  validarMail,
  eliminarVacios,
  validarTexto,
  validarTextoEspecial,
  capitalizarPalabras,
  asignarColorEstado,
  fechaHoraActual,
  formatearFecha,
  formatearFechaYHora,
  formatearFechaSinHora,
  autoComaYAutoY,
};
