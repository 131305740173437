const iconosActividades = [
  {
    nombre: 'Devolución',
    clase: 'icon-return-box',
  },
  {
    nombre: 'No tiene inventario',
    clase: 'icon-no-box',
  },
  {
    nombre: 'Toma de inventario',
    clase: 'icon-box',
  },
  {
    nombre: 'Merchandising',
    clase: 'icon-merchandising',
  },
  {
    nombre: 'Visita',
    clase: 'icon-walking',
  },
  {
    nombre: 'No hizo pedido',
    clase: 'icon-clipboard-remove',
  },
  {
    nombre: 'Hizo pedido',
    clase: 'icon-clipboard-checked2',
  },
  {
    nombre: 'Entrega de pedido',
    clase: 'icon-truck',
  },
  {
    nombre: 'Gestión de apertura cliente nuevo',
    clase: 'icon-user-plus',
  },
  {
    nombre: 'Cobranza efectiva',
    clase: 'icon-banknote',
  },
  {
    nombre: 'Cobranza no efectiva',
    clase: 'icon-no-banknote',
  },
  {
    nombre: 'Llamada',
    clase: 'icon-phone',
  },
];

export {iconosActividades};
